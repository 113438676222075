import { type MessageDescriptor } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import { setChildState, useContext } from '@strise/react-utils'
import { ButtonGroup, IconRadio, IconRadioSelected } from '@strise/ui-components'
import React, { useState } from 'react'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type UserGrowSettingsFragment } from '~/graphqlTypes'
import { enumOptions } from '~/utils/enum'
import { GrowSettingsSections } from '~/views/Grow/components/GrowSettingsPanel'
import { GrowLocationAreaSelection } from '~/views/Grow/components/Location/GrowLocationAreaSelection'
import { GrowLocationRestrictionEnum } from '~/views/Grow/components/Location/GrowLocationRestrictionEnum'
import { GrowLocationSearch } from '~/views/Grow/components/Location/GrowLocationSearch'

const tabTranslations: {
  [key in GrowLocationRestrictionEnum]?: MessageDescriptor
} = {
  [GrowLocationRestrictionEnum.NONE]: defineMessage({ message: 'None' }),
  [GrowLocationRestrictionEnum.LOCATION]: defineMessage({ message: 'By location' }),
  [GrowLocationRestrictionEnum.AREA]: defineMessage({ message: 'By area' })
}

const extractRestriction = (growSettings: UserGrowSettingsFragment): GrowLocationRestrictionEnum => {
  const locations = growSettings.locations
  const polygon = growSettings.coordinates

  if (locations.length) return GrowLocationRestrictionEnum.LOCATION
  if (polygon.length) return GrowLocationRestrictionEnum.AREA
  return GrowLocationRestrictionEnum.NONE
}

export const GrowLocationRestriction = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const growSettings = settings.grow
  const saveGrowSettings = setChildState(saveSettings, 'grow')

  const defaultRestriction = extractRestriction(settings.grow)
  const [restrictState, setRestrict] = useState<GrowLocationRestrictionEnum>(defaultRestriction)

  const handleRestrictionChange = (value: GrowLocationRestrictionEnum): void => {
    setRestrict(value)

    if (value === GrowLocationRestrictionEnum.LOCATION) {
      saveGrowSettings({
        ...growSettings,
        coordinates: []
      })
    } else if (value === GrowLocationRestrictionEnum.AREA) {
      saveGrowSettings({
        ...growSettings,
        locations: []
      })
    } else {
      saveGrowSettings({
        ...growSettings,
        locations: [],
        coordinates: []
      })
    }
  }

  const restrictOptions = enumOptions(Object.values(GrowLocationRestrictionEnum), tabTranslations, (option) => ({
    buttonProps: {
      startIcon:
        restrictState === option ? (
          <IconRadioSelected size='md' className='mr-2 shrink-0' />
        ) : (
          <IconRadio size='md' className='mr-2 shrink-0' />
        )
    }
  }))

  return (
    <GrowSettingsSections
      title={t`Location restriction`}
      description={t`Search location or click on the map to limit the area manually.`}
    >
      <ButtonGroup<GrowLocationRestrictionEnum>
        className='mb-4 w-full'
        palette='secondary'
        onClick={handleRestrictionChange}
        value={restrictState}
        options={restrictOptions.map((option) => ({
          ...option,
          text: option.label
        }))}
      />

      {restrictState === GrowLocationRestrictionEnum.LOCATION && (
        <GrowLocationSearch
          locations={growSettings.locations}
          setLocations={setChildState(saveGrowSettings, 'locations')}
        />
      )}

      {restrictState === GrowLocationRestrictionEnum.AREA && (
        <div className='max-w-[600px]'>
          <GrowLocationAreaSelection
            polygon={growSettings.coordinates}
            setPolygon={setChildState(saveGrowSettings, 'coordinates')}
          />
        </div>
      )}
    </GrowSettingsSections>
  )
}
