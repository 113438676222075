import { NationFlag, type NationFlagBaseProps } from './NationFlag'
import { type DivProps } from '@strise/react-utils'
import { IconLocation, cn } from '@strise/ui-components'
import * as React from 'react'

export const NationFlags = ({
  className,
  countryIsoCodes,
  iconProps,
  ...props
}: { countryIsoCodes: string[]; iconProps?: NationFlagBaseProps } & DivProps): React.ReactNode => {
  if (!countryIsoCodes.length) return <IconLocation {...iconProps} />

  return (
    <div className={cn('flex shrink-0 items-center', className)} {...props}>
      {countryIsoCodes.map((countryIsoCode, index) => {
        const isFirst = index === 0

        return (
          <NationFlag
            key={index}
            countryIsoCode={countryIsoCode}
            noFallback={!isFirst}
            className={cn(isFirst ? '' : '-ml-2', iconProps?.className)}
            {...iconProps}
          />
        )
      })}
    </div>
  )
}
