import * as React from 'react'
import { GrowContent } from '~/components/Grow/GrowContent'
import { GrowSettingsPanel } from '~/views/Grow/components/GrowSettingsPanel'

export const GrowView = (): React.ReactNode => {
  return (
    <div
      className='relative py-10 legacy-xs:min-w-0 legacy-md:min-w-0 legacy-lg:min-w-[1100px]'
      data-id='Sales Opportunities View / Container'
    >
      <div className='grid grid-cols-[minmax(450px,_min-content)_1fr] gap-4'>
        <GrowSettingsPanel />
        <GrowContent className='self-start' trackContext='grow' />
      </div>
    </div>
  )
}
