import * as React from 'react'
import { useResizeObserver } from 'usehooks-ts'
import { PrivatePersonReviewCard } from '~/components/Review/PrivatePersonReviewCard'
import { ReviewCardSkeleton } from '~/components/Review/ReviewCardSkeleton'
import { ReviewPrivatePersonHeader } from '~/components/Review/ReviewPrivatePersonHeader'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { usePrivatePersonSearchQuery, useTeamReviewSettingsQuery } from '~/graphqlOperations'

export const ReviewPrivatePersonsContent = (): React.ReactNode => {
  const { id: teamId } = useTeam()

  // TODO: Infinite scroll
  const { data, loading: privatePersonSearchLoading } = usePrivatePersonSearchQuery({
    variables: {
      searchInput: {
        query: ''
        // TODO: Support status, and probably a separate query for this
        // status: PrivatePersonReviewStatus.InReview
      },
      pageInfo: {
        limit: 10,
        offset: 0
      }
    },
    fetchPolicy: 'network-only'
  })
  const privatePersons = data?.privatePersonSearch.edges.map((edge) => edge.node)

  const { data: teamSettingsData, loading: teamSettingsLoading } = useTeamReviewSettingsQuery({ variables: {} })
  const teamReviewSettings = teamSettingsData?.team.teamSettings.reviewSettingsV2

  const loading = privatePersonSearchLoading || teamSettingsLoading

  const filterRef = React.useRef<HTMLDivElement>(null)
  const { height: filterHeight } = useResizeObserver({ ref: filterRef })

  return (
    <div>
      <ReviewPrivatePersonHeader ref={filterRef} />
      <div className='mx-auto max-w-[960px] pb-[30vh] pt-4 flex flex-col gap-4'>
        {loading && !privatePersons && (
          <div>
            <ReviewCardSkeleton />
            <ReviewCardSkeleton />
            <ReviewCardSkeleton />
          </div>
        )}
        {/* TODO: Add empty state */}
        {teamReviewSettings &&
          privatePersons?.map((privatePerson) => {
            return (
              <PrivatePersonReviewCard
                key={privatePerson.id}
                filterHeight={filterHeight}
                privatePerson={privatePerson}
                teamId={teamId}
                teamReviewSettings={teamReviewSettings}
              />
            )
          })}
      </div>
    </div>
  )
}
