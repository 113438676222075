import { type MessageDescriptor, i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { NationFlag } from '@strise/app-shared'
import { Chip, type ChipProps } from '@strise/ui-components'
import * as React from 'react'
import { UserChip } from '~/components/Assignee/UserChip'
import { type LegalFormFragment, type SimpleUserFragment } from '~/graphqlTypes'
import { getTitle } from '~/utils/enum'

export type ChipComponentType<T> = React.ComponentType<
  { titleMap?: Record<string, MessageDescriptor>; value: T } & Omit<ChipProps, 'value'>
>

export const EnumChip = <T extends string>({
  className,
  titleMap,
  value,
  ...props
}: { titleMap?: Record<T, MessageDescriptor>; value: T } & Omit<ChipProps, 'value'>): React.ReactNode => {
  return <Chip className={className} key={value} label={getTitle(titleMap ? titleMap[value] : undefined)} {...props} />
}

export const AssigneeChip: ChipComponentType<SimpleUserFragment | null> = ({ className, value, ...props }) => (
  <React.Fragment key={value?.id ?? 'no-assignee'}>
    {!value && <Chip className={className} label={i18n._(t`No assignee`)} {...props} />}
    {value && <UserChip className={className} user={value} {...props} />}
  </React.Fragment>
)

export const LegalFormChip: ChipComponentType<LegalFormFragment> = ({ value, ...props }) => {
  return (
    <Chip startIcon={<NationFlag countryIsoCode={value.countryV2} className='w-4' />} label={value.name} {...props} />
  )
}
