import { createContext, useContext } from 'react'
import { type TeamSettingsQuery } from '~/graphqlTypes'

interface TeamSettingsContextValue {
  data?: TeamSettingsQuery
  loading: boolean,
  refetch: () => void
}

export const TeamSettingsContext = createContext<TeamSettingsContextValue | undefined>(undefined)

export const useTeamSettings = (): TeamSettingsContextValue => {
  const context = useContext(TeamSettingsContext)
  if (!context) {
    throw new Error('useTeamSettings must be used within a TeamSettingsContextProvider')
  }
  return context
}
