import { useApolloClient } from '@apollo/client/index.js'
import {
  type PageInput,
  type SimpleUserConnection,
  SortOrdering,
  type TeamSimpleUsersArgs,
  UserField,
  type UserSortInput
} from '@strise/types'
import * as React from 'react'
import { useTeamUsersQuery } from '~/graphqlOperations'
import { type TeamSimpleUsersFragment, type TeamUsersQuery } from '~/graphqlTypes'
import { team } from '~/state'

interface UseTeamUsersProps {
  notifyOnNetworkStatusChange?: boolean
  onCompleted?: (data: TeamUsersQuery) => void
  setPage?: React.Dispatch<React.SetStateAction<PageInput<UserSortInput>>>
  variables?: TeamSimpleUsersArgs
}

export const defaultTeamUsersPageLimit = 100

export const defaultTeamUsersPageVariables: PageInput<UserSortInput> = {
  offset: 0,
  limit: defaultTeamUsersPageLimit,
  sort: [
    {
      field: UserField.Name,
      ordering: SortOrdering.Ascending
    }
  ]
}

export const useTeamUsers = ({
  notifyOnNetworkStatusChange,
  onCompleted,
  setPage,
  variables
}: UseTeamUsersProps = {}): {
  fetchMore: typeof fetchMore
  hasNextPage: boolean
  loading: boolean
  teamUsers: TeamUsersQuery['team']['users']['edges']
  totalCount: number | null
} => {
  const { page, q } = variables ?? {}
  // Store totalCount in state for better UX when loading
  const [totalCount, setTotalCount] = React.useState<number | null>(null)

  // Reset offset when search query changes
  React.useEffect(() => {
    setPage?.((prevPage) => ({
      ...prevPage,
      offset: 0
    }))
  }, [q])

  const { data, fetchMore, loading } = useTeamUsersQuery({
    variables: {
      q,
      page: {
        ...defaultTeamUsersPageVariables,
        ...page
      }
    },
    onCompleted: (res) => {
      setTotalCount(res.team.users.totalCount)
      onCompleted?.(res)
    },
    notifyOnNetworkStatusChange
  })

  const teamUsers = data?.team.users.edges ?? []
  const hasNextPage = !!totalCount && (page?.offset || 0) + (page?.limit || defaultTeamUsersPageLimit) < totalCount

  return { teamUsers, totalCount, hasNextPage, loading, fetchMore }
}

export const useSetTeamUsers = (): ((edges: TeamSimpleUsersFragment['users']['edges']) => void) => {
  const client = useApolloClient()

  return (edges: TeamSimpleUsersFragment['users']['edges']) => {
    const currentTeamId = team()
    if (!currentTeamId) return

    client.cache.modify({
      id: `Team:${currentTeamId}`,
      fields: {
        // @ts-expect-error
        simpleUsers(curr: SimpleUserConnection | undefined) {
          return {
            ...curr,
            edges
          }
        }
      }
    })
  }
}
