import { type SetStateFn } from '../types/types'
import { getBrowserGlobals } from './browserGlobals'
import type { Primitive } from '@strise/types'
import { differenceInDays } from 'date-fns'
import { isFunction, omit, orderBy } from 'lodash-es'
import * as React from 'react'
import { useState } from 'react'

interface TimestampedItem<T> {
  timestamp: number
  value: T
}

/**
 * Hook to manage an array state in local storage with a max size (FIFO)
 */
export const usePersistentArrayState = <T>(
  key: string,
  maxSize: number
): { addItem: (item: T) => void; resetState: () => void; state: T[] } => {
  // Use the provided usePersistentState hook with an empty array as the default value
  const [state, setState, resetState] = usePersistentState<Array<TimestampedItem<T>>>(key, [])

  // Convert the timestamped items to a set of values (without timestamps)
  const currentValue = React.useMemo(() => {
    return state.map((item) => item.value)
  }, [state])

  // Function to add a new item to the set
  const addItem = (item: T): void => {
    setState((prevState) => {
      // Remove the item if it already exists to avoid duplicates
      const filteredState = prevState.filter((i) => i.value !== item)

      // If the new size would exceed maxSize, remove the oldest item
      if (filteredState.length >= maxSize) {
        // eslint-disable-next-line functional/immutable-data
        filteredState.shift()
      }

      // Add the new item with the current timestamp
      return [...filteredState, { value: item, timestamp: Date.now() }]
    })
  }

  // Return the set and the functions to add an item or reset the set
  return {
    state: currentValue,
    addItem,
    resetState
  }
}

export const useDisableTemporarilyState = (
  localStorageKey: string,
  hoursDuration: number,
  now: Date = new Date()
): [boolean, () => void] => {
  const disableString = getBrowserGlobals()?.window.localStorage.getItem(localStorageKey)
  const disableDate = disableString && new Date(Number.parseInt(disableString))
  const [isEnabled, setIsEnabled] = useState(disableDate ? disableDate.valueOf() < now.valueOf() : true)

  const toggleIsEnabled = (): void => {
    setIsEnabled((prevIsEnabled) => {
      const nextIsEnabled = !prevIsEnabled

      if (nextIsEnabled) {
        getBrowserGlobals()?.window.localStorage.removeItem(localStorageKey)
      } else {
        const disableTimestamp = new Date().setHours(now.getHours() + hoursDuration)
        getBrowserGlobals()?.window.localStorage.setItem(localStorageKey, disableTimestamp.toString())
      }

      return nextIsEnabled
    })
  }

  return [isEnabled, toggleIsEnabled]
}

/**
 * Hook to manage a state in local storage
 */
export const usePersistentState = <T extends Primitive | object>(
  key: string,
  defaultValue: T
): [T, SetStateFn<T>, () => void] => {
  const [state, _setState] = React.useState<T>(() => {
    try {
      const currentValue = getBrowserGlobals()?.window.localStorage.getItem(key)
      if (!currentValue) return defaultValue
      if (currentValue === 'undefined') return defaultValue

      return JSON.parse(currentValue) as T
    } catch (error) {
      console.error('Error parsing persistent state', error)
      return defaultValue
    }
  })

  const setState: SetStateFn<T> = (newState: T | ((preState: T) => T)) => {
    const stringifiedState = isFunction(newState)
      ? JSON.stringify((newState as (prevState: T) => T)(state))
      : JSON.stringify(newState)

    getBrowserGlobals()?.window.localStorage.setItem(key, stringifiedState)
    _setState(newState)
  }

  const reset = (): void => {
    getBrowserGlobals()?.window.localStorage.removeItem(key)
    _setState(defaultValue)
  }

  return [state, setState, reset]
}

export const useSizeLimitedPersistentState = <T extends object>(
  key: string,
  defaultValue: T,
  options: {
    daysToLive?: number
    maxSize: number
  }
): [T, SetStateFn<T>, () => void] => {
  const [internalState, setInternalState, resetState] = usePersistentState<T & { timestamp?: number }>(
    key,
    defaultValue
  )

  React.useEffect(() => {
    const keys = Object.keys(localStorage)
    const relevantKeys = keys.filter((k) => {
      const firstKey = key.split('-')[0]
      return firstKey && k.startsWith(firstKey)
    })

    if (relevantKeys.length > options.maxSize) {
      const keyTimestamps = relevantKeys.map((k) => {
        try {
          const value = localStorage.getItem(k)
          const parsed = value ? (JSON.parse(value) as TimestampedItem<T>) : null

          return {
            key: k,
            timestamp: parsed ? parsed.timestamp || Date.now() : Date.now()
          }
        } catch {
          return { key: k, timestamp: Date.now() }
        }
      })

      const sorted = orderBy(keyTimestamps, (k) => k.timestamp)
      const keysToRemove = sorted.slice(0, sorted.length - options.maxSize)
      keysToRemove.forEach(({ key: lsKey }) => localStorage.removeItem(lsKey))
    }

    if (options.daysToLive) {
      const value = localStorage.getItem(key)
      if (value) {
        try {
          const parsed = JSON.parse(value) as TimestampedItem<T>
          const timestamp = parsed.timestamp || Date.now()
          if (differenceInDays(Date.now(), timestamp) >= options.daysToLive) {
            resetState()
          }
        } catch {
          // If parsing fails, keep the state
        }
      }
    }
  }, [key, options.maxSize, options.daysToLive, resetState])

  const wrappedSetState: SetStateFn<T> = (value) => {
    setInternalState((prev) => {
      const newValue = isFunction(value) ? value(prev) : value
      return {
        ...newValue,
        timestamp: Date.now()
      }
    })
  }

  // Strip out the timestamp before returning the state
  const stateWithoutTimestamp = omit(internalState, 'timestamp')

  return [stateWithoutTimestamp as T, wrappedSetState, resetState]
}
