import { t } from '@lingui/macro'
import { Chip, Tooltip, Typography } from '@strise/ui-components'
import * as React from 'react'
import { PepDispositionMeta } from '~/components/PepAndSanctions/PepDispositionMeta'
import type { PepInfoFragment, PersonBaseFragment } from '~/graphqlTypes'

interface PepDispositionBadgeProps {
  pepInfo: PepInfoFragment | null
  person: PersonBaseFragment | null
}

type PepDispositionBadgeData = {
  chipText: string
  palette: 'primary' | 'secondary'
  text: string
  variant: 'outlined' | 'contained'
}

const extractPepDispositionBadgeData = ({
  pepInfo,
  person
}: PepDispositionBadgeProps): PepDispositionBadgeData | null => {
  if (!person) {
    // This means that we don't have the necessary data to connect the disposition
    // Applicable in pepInfo.relatedPersons where we fetch pepInfo without entity
    return null
  }

  if (!pepInfo || !pepInfo.disposition) {
    // This means that there are no results
    return {
      variant: 'outlined' as const,
      palette: 'secondary' as const,
      chipText: t`Strise`,
      text: t`System suggested`
    }
  }

  return {
    variant: 'contained' as const,
    palette: 'primary' as const,
    chipText: t`User`,
    text: t`Confirmed`
  }
}

const PepDispositionBadgeTooltipContent = ({ pepInfo }: { pepInfo: PepInfoFragment | null }): React.ReactNode => {
  if (!pepInfo) return t`No results`

  if (!pepInfo.disposition) return t`Not user confirmed yet`

  return <PepDispositionMeta disposition={pepInfo.disposition} />
}

export const PepDispositionBadge = ({ pepInfo, person }: PepDispositionBadgeProps): React.ReactNode => {
  const badgeData = extractPepDispositionBadgeData({ pepInfo, person })

  if (!badgeData) return null

  return (
    <Tooltip arrow content={<PepDispositionBadgeTooltipContent pepInfo={pepInfo} />}>
      <div className='inline-flex items-center gap-1'>
        <Chip
          size='sm'
          className='h-auto rounded-md p-0 pb-px'
          variant={badgeData.variant}
          palette={badgeData.palette}
          label={badgeData.chipText}
        />
        <Typography variant='body2'>{badgeData.text}</Typography>
      </div>
    </Tooltip>
  )
}
