import { useReactiveVar } from '@apollo/client/index.js'
import { t } from '@lingui/macro'
import { useContext } from '@strise/react-utils'
import { type CompanyFilterInput } from '@strise/types'
import { uniqBy } from 'lodash-es'
import * as React from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useDebounceValue, useResizeObserver, useToggle } from 'usehooks-ts'
import { PaginationFooter } from '~/components/PaginationFooter'
import { ReviewCardSkeleton } from '~/components/Review/ReviewCardSkeleton'
import { ReviewCompanyHeader } from '~/components/Review/ReviewCompanyHeader'
import { ReviewCompanyList } from '~/components/Review/ReviewCompanyList'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { useReviewCompaniesQuery, useTeamReviewSettingsQuery } from '~/graphqlOperations'
import { refreshReviewCompanies } from '~/state'
import { userReviewSettingsToInput } from '~/utils/settingsUtils'
import { TestIDs } from '~/utils/testIDs'

const baseSize = 5

export const ReviewCompanyContent = (): React.ReactNode => {
  const [offset, setOffset] = React.useState(0)
  const [companyNameFilter, setCompanyNameFilter] = React.useState('')
  const [companyNameFilterDebounced] = useDebounceValue(companyNameFilter, 300)
  const filterRef = React.useRef<HTMLDivElement>(null)
  const { height: filterHeight } = useResizeObserver({ ref: filterRef })

  const { settings } = useContext(CurrentUserSettingsContext)
  const [filterOpen, toggleFilterOpen] = useToggle(false)

  const settingsInput = userReviewSettingsToInput(settings)

  const filterVariables: CompanyFilterInput = {
    ...settingsInput.review?.companies,
    name: companyNameFilterDebounced
  }

  const { id: teamId } = useTeam()

  const {
    data: reviewCompaniesData,
    fetchMore,
    loading: reviewCompaniesLoading,
    refetch: refetchReviewCompanies
  } = useReviewCompaniesQuery({
    variables: { filter: filterVariables, size: baseSize + 1, offset: 0 },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
  const { data: teamSettingsData, loading: teamSettingsLoading } = useTeamReviewSettingsQuery({ variables: {} })

  // This is triggered when we update a company status
  const refreshReview = useReactiveVar(refreshReviewCompanies)

  React.useEffect(() => {
    // Reset page info on refetch
    if (!refreshReview) return

    setOffset(0)
    refetchReviewCompanies()
  }, [refreshReview])

  React.useEffect(() => {
    // Reset page info on filter change
    setOffset(0)
  }, [JSON.stringify(filterVariables)])

  const handleIncrementOffset = (): void => {
    setOffset((prevOffset) => {
      const newOffset = prevOffset + baseSize
      fetchMore({
        variables: {
          filter: filterVariables,
          size: baseSize,
          offset: newOffset + 1
        }
      })
      return newOffset
    })
  }

  const companies = reviewCompaniesData?.team.portfolio?.companies.edges.map(({ node: company }) => company)
  const companiesToShow = uniqBy(companies?.slice(0, baseSize + offset), (company) => company.id)
  const hasMoreCompanies = !!companies && companies.length > baseSize + offset

  const teamReviewSettings = teamSettingsData?.team.teamSettings.reviewSettingsV2
  const loading = reviewCompaniesLoading || teamSettingsLoading

  const [infiniteScrollItemRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMoreCompanies,
    onLoadMore: handleIncrementOffset,
    disabled: loading,
    rootMargin: '0px 0px 100px 0px'
  })

  return (
    <div>
      <ReviewCompanyHeader
        ref={filterRef}
        filterOpen={filterOpen}
        toggleFilterOpen={toggleFilterOpen}
        companyNameFilter={companyNameFilter}
        setCompanyNameFilter={setCompanyNameFilter}
      />

      <div className='mx-auto max-w-[960px] pb-[30vh] pt-4'>
        {loading && !companies && (
          <div>
            <ReviewCardSkeleton />
            <ReviewCardSkeleton />
            <ReviewCardSkeleton />
          </div>
        )}
        {teamReviewSettings && (
          <>
            <ReviewCompanyList
              filterHeight={filterHeight}
              companies={companiesToShow}
              teamReviewSettings={teamReviewSettings}
              toggleFilterOpen={toggleFilterOpen}
              loading={loading}
              infiniteScrollItemRef={infiniteScrollItemRef}
              teamId={teamId}
            />

            {loading && <ReviewCardSkeleton />}

            {!!companiesToShow.length && !loading && (
              <PaginationFooter
                className='mt-[3.75rem]'
                nextPage={handleIncrementOffset}
                hasMore={hasMoreCompanies}
                name={t`companies`}
                data-id={TestIDs.Review.paginationFooter}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
