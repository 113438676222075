import { Trans } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { ExternalLink, Typography, cn } from '@strise/ui-components'
import React from 'react'

interface DataSource {
  lastUpdatedAt: string | null
  link: string | null
  name: string
}

export const DataSources = ({
  className,
  dataSources,
  hideLinks,
  learnMoreLink,
  ...props
}: {
  className?: string
  dataSources: DataSource[]
  hideLinks?: boolean
  learnMoreLink?: string
} & DivProps): React.ReactNode => {
  return (
    <div className={cn('flex items-center gap-1 p-4 pb-2 text-secondary-shade-40', className)} {...props}>
      {!!dataSources.length && (
        <>
          <Typography className='' variant='body2'>
            {dataSources.length === 1 && <Trans>Source</Trans>}
            {dataSources.length > 1 && <Trans>Sources</Trans>}:
          </Typography>
          {dataSources.map((dataSource, index) => {
            const isLast = index === dataSources.length - 1
            const sourceClassName = cn({ 'mr-1': isLast })
            return (
              <React.Fragment key={index}>
                {hideLinks || !dataSource.link ? (
                  <Typography variant='body2' className={sourceClassName}>
                    {dataSource.name}
                    {!isLast && ','}
                  </Typography>
                ) : (
                  <ExternalLink href={dataSource.link} className={sourceClassName} variant='body2'>
                    {dataSource.name}
                  </ExternalLink>
                )}
              </React.Fragment>
            )
          })}
        </>
      )}

      {learnMoreLink && (
        <ExternalLink href={learnMoreLink} variant='body2'>
          <Trans>Learn more</Trans>
        </ExternalLink>
      )}
    </div>
  )
}
