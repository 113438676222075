import { Trans } from '@lingui/macro'
import { extractEntityCountries } from '@strise/app-shared'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { TrackedActivityKind } from '@strise/types'
import { Typography, cn } from '@strise/ui-components'
import React, { useRef } from 'react'
import { SidepanelContainer } from '~/components/Sidepanel/SidepanelContainer'
import { SidepanelContextProvider } from '~/components/Sidepanel/SidepanelContext/SidepanelContextProvider'
import { SidepanelFooter } from '~/components/Sidepanel/SidepanelFooter'
import { SidepanelHeader } from '~/components/Sidepanel/SidepanelHeader'
import { SidepanelCompanyLayout } from '~/components/Sidepanel/SidepanelLayouts/SidepanelCompanyLayout'
import { SidepanelGlobalCompanyLayout } from '~/components/Sidepanel/SidepanelLayouts/SidepanelGlobalCompanyLayout'
import { SidepanelGlobalPersonLayout } from '~/components/Sidepanel/SidepanelLayouts/SidepanelGlobalPersonLayout'
import { SidepanelPersonLayout } from '~/components/Sidepanel/SidepanelLayouts/SidepanelPersonLayout'
import { SidepanelPrivatePersonLayout } from '~/components/Sidepanel/SidepanelLayouts/SidepanelPrivatePersonLayout'
import { SidepanelTabs } from '~/components/Sidepanel/SidepanelTabs/SidepanelTabs'
import { useSidepanelTabRefs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { extractIsSupportedSidepanelEntity, useSidepanel } from '~/components/Sidepanel/utils/sidepanelUtils'
import { SIDEPANEL_WIDTH } from '~/constants'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { RenderContextType } from '~/contexts/RenderContext/RenderContext'
import { RenderContextProvider } from '~/contexts/RenderContext/RenderContextProvider'
import { useSidepanelEntityQuery } from '~/graphqlOperations'
import { SidepanelContentLoader } from '~/loaders/SidepanelContentLoader'
import { TestIDs } from '~/utils/testIDs'
import { useTrackOnLoad } from '~/utils/tracking'
import { type SidepanelTab } from '~/utils/urls'

const SidepanelContent = ({
  className,
  expanded,
  id,
  onClose,
  setExpanded,
  setId,
  sidepanelOwnerNodeId,
  sidepanelTab,
  ...props
}: {
  expanded: boolean
  id: string
  onClose: () => void
  setExpanded: SetStateFn<boolean | null>
  setId: SetStateFn<string | null>
  sidepanelOwnerNodeId: string | null
  sidepanelTab: SidepanelTab | null
} & DivProps): React.ReactNode => {
  const features = useCurrentUserFeatures()
  const sidepanelContentRef = useRef<HTMLDivElement | null>(null)

  const { data, loading } = useSidepanelEntityQuery({
    variables: {
      entity: id,
      includeCreditReport: !!features.CREDIT_DATA,
      includeRealEstates: !!features.REAL_ESTATE_DATA_KARTVERKET,
      withFlags: false
    }
  })

  const entity = data?.entity

  useTrackOnLoad(
    TrackedActivityKind.StriseEntityDetailsExposed,
    {
      entityId: entity?.id,
      entityType: entity?.__typename,
      country: entity ? extractEntityCountries(entity)[0]?.isoAlpha2Code : null
    },
    !entity
  )

  const tabRefs = useSidepanelTabRefs(entity)

  const isSupportedSidepanelEntity = extractIsSupportedSidepanelEntity(entity)

  if (!isSupportedSidepanelEntity) return 'Not supported type'

  return (
    <SidepanelContextProvider
      id={id}
      setId={setId}
      expanded={expanded}
      setExpanded={setExpanded}
      sidepanelOwnerNodeId={sidepanelOwnerNodeId}
      scrollEl={sidepanelContentRef}
    >
      <div
        className={cn('h-full bg-background-default', className)}
        id='app-sidepanel'
        data-id={TestIDs.SidePanel.root}
        {...props}
      >
        <SidepanelHeader id={id} entity={entity} onClose={onClose} />
        <SidepanelTabs entity={entity} tabRefs={tabRefs} sidepanelTab={sidepanelTab} loading={loading} />
        <div
          className='h-[calc(100%-theme(height.sub-header))] overflow-y-auto scroll-smooth'
          ref={sidepanelContentRef}
        >
          {loading && <SidepanelContentLoader />}
          {!entity && !loading && (
            <div className='flex items-center justify-center bg-background-default'>
              <Typography variant='h3'>
                <Trans>Not found</Trans>
              </Typography>
            </div>
          )}
          {entity?.__typename === 'Company' && <SidepanelCompanyLayout company={entity} tabRefs={tabRefs} />}
          {entity?.__typename === 'GlobalCompany' && (
            <SidepanelGlobalCompanyLayout globalCompany={entity} tabRefs={tabRefs} />
          )}
          {entity?.__typename === 'Person' && <SidepanelPersonLayout person={entity} tabRefs={tabRefs} />}
          {entity?.__typename === 'GlobalPerson' && (
            <SidepanelGlobalPersonLayout globalPerson={entity} tabRefs={tabRefs} />
          )}
          {entity?.__typename === 'PrivatePerson' && (
            <SidepanelPrivatePersonLayout privatePerson={entity} tabRefs={tabRefs} />
          )}
          <SidepanelFooter />
        </div>
      </div>
    </SidepanelContextProvider>
  )
}

export const Sidepanel = (): React.ReactNode => {
  const { close, id, isExpanded, setId, setIsExpanded, sidepanelOwnerNodeId, sidepanelTab } = useSidepanel()

  if (!id) return null

  return (
    <RenderContextProvider type={RenderContextType.Sidepanel}>
      <SidepanelContainer
        className={cn('legacy-xs:w-full', isExpanded ? SIDEPANEL_WIDTH.twOpenExpanded.lg : SIDEPANEL_WIDTH.twOpen.lg)}
        isOpen
        expanded={isExpanded}
        onClose={close}
      >
        <SidepanelContent
          key={`sidepanel-entity-${id}`}
          id={id}
          setId={setId}
          expanded={isExpanded}
          setExpanded={setIsExpanded}
          sidepanelTab={sidepanelTab}
          sidepanelOwnerNodeId={sidepanelOwnerNodeId}
          onClose={close}
        />
      </SidepanelContainer>
    </RenderContextProvider>
  )
}
