import { cn } from '../../utils/className'
import { createContext, useContext } from '@strise/react-utils'
import * as React from 'react'

type TableVariant = 'default' | 'backplate'

interface TableContextProps {
  variant: TableVariant
}

const TableContext = createContext<TableContextProps>()
TableContext.displayName = 'TableContext'

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  className?: string
  variant?: TableVariant
  wrapperClassName?: string
}

const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ className, variant = 'default', wrapperClassName, ...props }, ref) => {
    const contextValue = React.useMemo(
      () => ({
        variant
      }),
      [variant]
    )

    return (
      <TableContext.Provider value={contextValue}>
        <div className={cn('relative w-full overflow-auto', wrapperClassName)}>
          <table
            ref={ref}
            className={cn('w-full caption-bottom text-sm', className)}
            data-variant={variant}
            {...props}
          />
        </div>
      </TableContext.Provider>
    )
  }
)
Table.displayName = 'Table'

const TableHeader = React.forwardRef<HTMLTableSectionElement, TableSectionProps>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('[&_tr]:border-b', className)} {...props} />
))
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<HTMLTableSectionElement, TableSectionProps>(({ className, ...props }, ref) => (
  <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props} />
))
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<HTMLTableSectionElement, TableSectionProps>(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={cn('border-t font-medium [&>tr]:last:border-b-0', className)} {...props} />
))
TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(({ className, ...props }, ref) => (
  <tr ref={ref} className={cn('border-b border-gray-20 transition-colors hover:bg-gray-5', className)} {...props} />
))
TableRow.displayName = 'TableRow'

export interface TableHeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  className?: string
}

const TableHead = React.forwardRef<HTMLTableCellElement, TableHeadProps>(({ className, ...props }, ref) => {
  const { variant } = useContext(TableContext)

  return (
    <th
      ref={ref}
      className={cn(
        'p-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0',
        variant === 'backplate' && 'bg-gray-90 text-md text-white',
        variant === 'default' && 'text-text-primary',
        className
      )}
      {...props}
    />
  )
})
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(({ className, ...props }, ref) => (
  <td ref={ref} className={cn('p-4 align-top [&:has([role=checkbox])]:pr-0', className)} {...props} />
))
TableCell.displayName = 'TableCell'

const TableCaption = React.forwardRef<HTMLTableCaptionElement, TableCaptionProps>(({ className, ...props }, ref) => (
  <caption ref={ref} className={cn('mt-4 text-sm text-gray-30', className)} {...props} />
))
TableCaption.displayName = 'TableCaption'

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption }

export interface TableSectionProps extends React.HTMLAttributes<HTMLTableSectionElement> {
  className?: string
}

export interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  className?: string
}

export interface TableCaptionProps extends React.HTMLAttributes<HTMLTableCaptionElement> {
  className?: string
}

export interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  className?: string
}
