import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconChevronDoubleDownSmall = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 15.293 8.707 12l-1.414 1.414L12 18.121l4.707-4.707L15.293 12 12 15.293Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 10.293 8.707 7 7.293 8.414 12 13.121l4.707-4.707L15.293 7 12 10.293Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconChevronDoubleDownSmall.displayName = 'IconChevronDoubleDownSmall'
