import { useCurrentUserLazyQuery } from '../../graphqlOperations'
import { type CurrentUserQuery } from '../../graphqlTypes'
import { AuthContext } from './AuthContext'
import { AuthError } from './authError'
import { useLogout } from './authUtils'
import { type UserRoleEnum, userRole } from './userRoleEnum'
import { useContext } from '@strise/react-utils'
import * as React from 'react'

export const Authorize = ({
  children,
  minRequiredRole
}: {
  children: React.ReactNode
  minRequiredRole: string
}): React.ReactNode => {
  const { setUser, user } = useContext(AuthContext)
  const logout = useLogout()

  const authorize = (data: CurrentUserQuery): void => {
    const { currentUser } = data
    if (!currentUser) {
      logout(AuthError.NoUser, 'Current user missing')
      return
    }
    const userId = currentUser.id
    const teams = currentUser.teams.edges
    const roles = teams.map(({ role }) => role.id as UserRoleEnum)

    if (!roles.some((role) => userRole(role).higherThanOrEquals(minRequiredRole))) {
      logout(AuthError.MissingRole, 'User missing necessary roles')
      return
    }

    setUser({
      id: userId,
      isAdmin: roles.some((role) => userRole(role).isAdmin()),
      isSupervisor: roles.some((role) => userRole(role).isSupervisor()),
      teams
    })
  }

  const [fetch] = useCurrentUserLazyQuery({ fetchPolicy: 'network-only', onCompleted: authorize })

  React.useEffect(() => {
    if (!user) {
      fetch()
    }
  }, [user, fetch])

  if (!user) return null

  return <>{children}</>
}
