import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconHelp = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        d='M10.548 14.999h2.026v-1.635c0-.314.099-.393.428-.393 1.894 0 2.998-1.335 2.998-2.907C16 8.18 14.567 7 12.59 7H9v1.807h3.294c.906 0 1.532.472 1.532 1.257 0 .943-.626 1.257-1.532 1.257h-.445c-.823 0-1.3.472-1.3 1.258v2.42ZM10.4 18h2.306v-2.121H10.4V18Z'
      />
    </svg>
  )
)
IconHelp.displayName = 'IconHelp'
