import { type MessageDescriptor, i18n } from '@lingui/core'
import { Chip, type ChipProps } from '@strise/ui-components'
import { isNil } from 'lodash-es'
import * as React from 'react'
import { type FromToLike, activeText } from '~/components/Filter/filterFromToUtils'

export const FilterFromToActive = <T extends string, I extends FromToLike<T>>({
  className,
  filter,
  suffix,
  ...props
}: { filter: I | null | undefined; suffix: MessageDescriptor } & ChipProps): React.ReactNode => {
  if (isNil(filter?.from) && isNil(filter?.to)) return null

  const labels = [...activeText(filter.from ?? null, filter.to ?? null), suffix]

  return <Chip className={className} label={labels.map((text) => i18n._(text)).join(' ')} {...props} />
}
