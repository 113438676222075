import { type DivProps } from '@strise/react-utils'
import { Alert, type AlertVariant, Typography, type TypographyProps, cn } from '@strise/ui-components'
import * as React from 'react'

interface SettingsChangesBaseProps extends DivProps {
  actions?: React.ReactNode
  description?: string
  descriptionProps?: TypographyProps
  title?: string
  titleProps?: TypographyProps
}

interface SettingsChangesSectionHeaderProps extends SettingsChangesBaseProps {
  descriptionVariant?: AlertVariant
}

export const SettingChangesSectionHeader = ({
  actions,
  className,
  description,
  descriptionProps,
  descriptionVariant,
  title,
  titleProps,
  ...props
}: SettingsChangesSectionHeaderProps): React.ReactNode => {
  const { className: descriptionClassName, ...descriptionPropsRest } = descriptionProps ?? {}

  return (
    <div className={cn('flex justify-between', className)} {...props}>
      <div>
        {title && (
          <Typography variant='subtitle1' {...titleProps}>
            {title}
          </Typography>
        )}
        {description && !descriptionVariant && (
          <Typography
            className={cn('mb-2 block text-text-secondary', descriptionClassName)}
            variant='aLabelSmall'
            {...descriptionPropsRest}
          >
            {description}
          </Typography>
        )}

        {description && descriptionVariant && (
          <Alert variant={descriptionVariant} className='mb-4'>
            {description}
          </Alert>
        )}
      </div>
      <div>{actions}</div>
    </div>
  )
}

interface SettingsChangesProps extends SettingsChangesBaseProps {
  children: React.ReactNode
}

export const SettingsChangesSection = ({
  actions,
  children,
  className,
  description,
  descriptionProps,
  title,
  titleProps,
  ...props
}: SettingsChangesProps): React.ReactNode => {
  return (
    <div className={cn('flex flex-col gap-2', className)} {...props}>
      <SettingChangesSectionHeader
        title={title}
        titleProps={titleProps}
        description={description}
        descriptionProps={descriptionProps}
        actions={actions}
      />
      {children}
    </div>
  )
}
