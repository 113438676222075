import { cn } from '../../utils/className'
import { inputVariants } from '../variants/inputVariants'
import { type DataProps } from '@strise/react-utils'
import { type VariantProps } from 'class-variance-authority'
import * as React from 'react'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    Omit<VariantProps<typeof inputVariants>, 'disabled'>,
    DataProps {
  /** Icon displayed after the input text */
  endIcon?: React.ReactNode
  /** Use if you need a custom className on the input field itself. Use className if you want a className on the container */
  inputClassName?: string
  /** Icon displayed before the input text */
  startIcon?: React.ReactNode
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, disabled, endIcon, inputClassName, palette, startIcon, variant, ...props }, ref) => {
    return (
      <div
        className={cn(inputVariants({ variant, palette, disabled }), startIcon && 'pl-2', endIcon && 'pr-2', className)}
      >
        {startIcon}
        <input
          className={cn('size-full flex-1 bg-transparent p-2 text-inherit outline-none', inputClassName)}
          ref={ref}
          {...props}
        />
        {endIcon}
      </div>
    )
  }
)
Input.displayName = 'Input'
