import { type DivProps } from '@strise/react-utils'
import { type KeyEntityMeta } from '@strise/types'
import { Skeleton, Tooltip, Typography, type TypographyProps, cn } from '@strise/ui-components'
import * as React from 'react'
import { extractIcon } from '~/utils/apiTable/apiTableValueBaseUtils'

export const EntityMetaItem = ({
  className,
  item,
  loading,
  noTooltip,
  textProps,
  ...props
}: {
  item: KeyEntityMeta
  loading?: boolean
  noTooltip?: boolean
  textProps?: TypographyProps
} & DivProps): React.ReactNode => {
  const Icon = React.useMemo(() => (item.iconId ? extractIcon(item.iconId) : undefined), [item.iconId])

  if (!loading && !item.content) return null

  const content = (
    <div className={cn('flex min-w-0 shrink-0 flex-row items-center', className)} {...props}>
      {Icon && <Icon className='size-4 shrink-0 align-text-bottom' />}
      <Typography className='ml-1' variant='body2' {...textProps}>
        {loading ? <Skeleton className='size-3' /> : item.content}
      </Typography>
    </div>
  )

  if (noTooltip) return content

  const tooltipContent = (
    <>
      {item.label && <span className='font-bold'>{item.label}</span>}
      {item.content && <div>{item.content}</div>}
    </>
  )

  return <Tooltip content={item.tooltip ?? tooltipContent}>{content}</Tooltip>
}
