import { Trans } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { CountryKind } from '@strise/types'
import { Alert } from '@strise/ui-components'
import * as React from 'react'
import { useOwners } from '~/components/Ownerships/ownershipChartUtils'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { SidepanelTransformedTableRow } from '~/components/Sidepanel/SidepanelTransformedTableRow'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type SidepanelCompanyFragment, type SidepanelGlobalCompanyFragment } from '~/graphqlTypes'
import { useTransformedTable } from '~/utils/apiTable/apiTableValueBaseUtils'
import { type SidepanelTab } from '~/utils/urls'

interface SidepanelCompanyOwnersCardProps extends DivProps {
  company: SidepanelCompanyFragment | SidepanelGlobalCompanyFragment
  showOriginal: boolean
  sidepanelTab?: SidepanelTab
}

export const SidepanelCompanyOwnersCard = React.forwardRef<HTMLDivElement, SidepanelCompanyOwnersCardProps>(
  (
    {
      company,
      showOriginal,
      sidepanelTab,
      ...props
    }: {
      company: SidepanelCompanyFragment | SidepanelGlobalCompanyFragment
      showOriginal: boolean
      sidepanelTab?: SidepanelTab
    },
    ref
  ) => {
    const features = useCurrentUserFeatures()
    const { hasData, loading, table } = useOwners(company, 5, showOriginal)
    const transformedTable = useTransformedTable(company, table)

    return (
      <SidepanelCard
        title={transformedTable?.title}
        description={transformedTable?.description}
        loading={loading && !hasData}
        ref={ref}
        tab={sidepanelTab}
        {...props}
      >
        <div className='px-4'>
          {features.ABOS_FOLKEREGISTERET && company.primaryCountry?.kind === CountryKind.No && (
            <Alert className='pb-2' size='sm' variant='info'>
              <Trans>The information is verified with the National Population Register</Trans>
            </Alert>
          )}

          {transformedTable?.rows.map((row, index) => {
            const isLast = index === transformedTable.rows.length - 1
            return (
              <SidepanelTransformedTableRow key={index} row={row} hideDivider={isLast} context='Company / Owners' />
            )
          })}
        </div>

        {transformedTable && <DataSources dataSources={transformedTable.dataSources} />}
      </SidepanelCard>
    )
  }
)
