import { type RiskColor } from '@strise/types'
import { Label, cn } from '@strise/ui-components'
import * as React from 'react'
import { RiskLevelDot } from '~/views/Settings/TeamFeatures/components/RiskLevelDot'

interface BaseRiskLabelProps {
  label: string
  riskColor: RiskColor
}

export const BaseRiskLabel = ({ label, riskColor }: BaseRiskLabelProps): React.ReactNode => (
  <Label className={cn('inline-flex cursor-default items-center p-2')} variant='aLabel'>
    <RiskLevelDot className='mr-1' color={riskColor} />
    {label}
  </Label>
)
