import { t } from '@lingui/macro'
import { IdNameChip } from '@strise/app-shared'
import { setChildState, useContext } from '@strise/react-utils'
import { ApplicationSearchReturnType } from '@strise/types'
import * as React from 'react'
import { EntitySearchInputMultiple } from '~/components/Entity/EntitySearchInput'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'
import { GrowSettingsSections } from '~/views/Grow/components/GrowSettingsPanel'

export const GrowCollateralCreditorFilter = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const collateralCreditorsState = settings.grow.collateralCreditors
  const saveCollateralCreditorsSettings = setChildState(saveSettings, 'grow.collateralCreditors')

  const handleDeleteCreditor = (creditor: BaseEntityLikeFragment): void => {
    saveCollateralCreditorsSettings(collateralCreditorsState.filter((prevCreditor) => prevCreditor.id !== creditor.id))
  }

  return (
    <GrowSettingsSections title={t`Collateral creditor`}>
      <div className='flex flex-wrap gap-1'>
        {collateralCreditorsState.map((entity) => (
          <IdNameChip key={entity.id} value={entity} onDelete={() => handleDeleteCreditor(entity)} />
        ))}
      </div>
      <EntitySearchInputMultiple
        inlineSearch={false}
        selectedEntities={collateralCreditorsState}
        onChange={saveCollateralCreditorsSettings}
        entityKindFilter={ApplicationSearchReturnType.Company}
        dataTrack='Opportunity Settings / Collateral creditor select'
        clearSearchOnSelect={false}
      />
    </GrowSettingsSections>
  )
}
