import { type DivPropsWithChildren } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import React from 'react'
import { Dim } from '~/components/Dim'
import { SIDEPANEL_WIDTH } from '~/constants'

export const SidepanelContainer = ({
  children,
  className,
  expanded,
  isOpen,
  onClose,
  ...props
}: { expanded: boolean; isOpen: boolean; onClose: () => void } & DivPropsWithChildren): React.ReactNode => {
  return (
    <>
      <div className='legacy-xs:hidden legacy-lg:block' style={{ width: SIDEPANEL_WIDTH.open }} />
      <div
        className={cn(
          'fixed bottom-0 right-0 block border-l border-solid border-divider legacy-xs:top-0 legacy-xs:z-[13] legacy-lg:top-[theme(height.header)] legacy-lg:z-[12]',
          className
        )}
        style={{
          maxWidth: SIDEPANEL_WIDTH.max
        }}
        {...props}
      >
        {children}
        {isOpen && (
          <Dim
            className={cn('z-[-1]', expanded ? 'legacy-lg:block' : 'legacy-lg:hidden')}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        )}
      </div>
    </>
  )
}
