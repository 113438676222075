import { type FeatureFragment } from '../../graphqlTypes'
import { type FeaturesMap } from '../../utils/features'
import { FeatureCategory } from './FeatureCategory'
import { type SetStateFn } from '@strise/react-utils'
import { Skeleton } from '@strise/ui-components'
import { groupBy } from 'lodash-es'
import * as React from 'react'

export const FeatureCategories = ({
  DisabledFeatureComponent,
  currentFeatures,
  featuresState,
  ignoreDisabled,
  isLoading,
  selectAll,
  setFeaturesState,
  showDescription
}: {
  DisabledFeatureComponent?: React.FC<{ feature: FeatureFragment }>
  currentFeatures: FeatureFragment[]
  featuresState: FeaturesMap
  ignoreDisabled?: boolean
  isLoading: boolean
  selectAll?: boolean
  setFeaturesState: SetStateFn<FeaturesMap>
  showDescription?: boolean
}): React.ReactNode => {
  const grouped = React.useMemo(() => {
    return groupBy(currentFeatures, (f) => f.category.name)
  }, [currentFeatures])

  const showTitle = Object.keys(grouped).length

  return (
    <>
      {isLoading && (
        <div className='grid gap-6'>
          {Array.from({ length: 10 }).map((_, index) => {
            return (
              <div className='grid gap-2' key={index}>
                <Skeleton className='h-6 w-20' />
                <Skeleton className='h-10 w-full' />
              </div>
            )
          })}
        </div>
      )}
      {!isLoading &&
        Object.entries(grouped).map(([categoryName, categoryFeatures]) => {
          return (
            <FeatureCategory
              key={categoryName}
              title={showTitle ? categoryName : null}
              categoryFeatures={categoryFeatures}
              featuresState={featuresState}
              setFeaturesState={setFeaturesState}
              ignoreDisabled={ignoreDisabled}
              DisabledFeatureComponent={DisabledFeatureComponent}
              selectAll={selectAll}
              showDescription={showDescription}
            />
          )
        })}
    </>
  )
}
