import { useUserTeams } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { IconChevronRightSmall, Typography, cn } from '@strise/ui-components'
import { Dropdown, MenuItem } from '@strise/ui-components-legacy'
import * as React from 'react'
import { useTeam } from '~/contexts/TeamContext/TeamContext'

export const SelectTeam = ({ className, ...props }: Omit<DivProps, 'onToggle'>): React.ReactNode => {
  const { name, setTeam } = useTeam()
  const teams = useUserTeams()

  const options = teams.map(({ node }) => ({
    text: node.name,
    value: node.id,
    className: 'bg-secondary-main pl-4 text-white',
    dataTrack: 'Team changed',
    onClick: () => {
      setTeam(node.id)
    }
  }))

  if (teams.length === 1) {
    return (
      <div className={cn('pl-0', className)} {...props}>
        <Typography variant='aLabel'>{name}</Typography>
      </div>
    )
  }

  return (
    <Dropdown
      ToggleComponent={
        <MenuItem className='pl-0' endIcon={<IconChevronRightSmall />}>
          {name}
        </MenuItem>
      }
      paperProps={{
        className: 'w-full absolute right-[-208px] top-[-48px] shadow-none'
      }}
      popperProps={{ portal: true }}
      data-track='Open Select Team'
      {...props}
    >
      {options.map(({ text, ...optionProps }, index) => (
        <MenuItem key={index} {...optionProps} data-track='Team changed'>
          {text}
        </MenuItem>
      ))}
    </Dropdown>
  )
}
