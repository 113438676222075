import { getSandboxCustomer } from '../components/apollo/apolloUtils'
import { getBrowserGlobals } from '@strise/react-utils'

export const API_ENV_TO_API_URL = {
  PROD: 'https://graphql.strise.ai/internal/graphql',
  STAGE: 'https://stage.graphql.strise.ai/internal/graphql',
  DEV: 'https://dev.graphql.strise.ai/internal/graphql',
  LOCAL: 'http://localhost:8080/internal/graphql',
  SANDBOX: `https://${getSandboxCustomer()}.api.sandbox.strise.ai/internal/graphql`
}

const getApiEnv = (serverSideEnv?: string): string => {
  try {
    const params = new URLSearchParams(getBrowserGlobals()?.window.location.search)
    const env = (
      params.get('api') ||
      getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.API_ENV ||
      process.env.API_ENV ||
      serverSideEnv
    )?.toUpperCase()

    if (!env) {
      console.error('API env not set')
      return 'PROD'
    }

    return env
  } catch (e) {
    console.error('Error getting API env', e)
    return 'PROD'
  }
}

export interface ApiEnvState {
  get: (serverSideEnv?: string) => string
  set: (env: string) => void
}

export const initApiEnv = (): ApiEnvState => {
  return {
    set(newEnv: string): void {
      // eslint-disable-next-line no-restricted-globals
      if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-restricted-globals
        const url = new URL(window.location.href)
        url.searchParams.set('api', newEnv)
        // Tricky to handle mutation of browser variables without explicitly using them
        // eslint-disable-next-line no-restricted-globals
        window.location.href = url.toString()
      }
    },
    get(serverSideEnv?: string): string {
      return getApiEnv(serverSideEnv)
    }
  }
}
