import { t } from '@lingui/macro'
import { setChildState, useContext } from '@strise/react-utils'
import { type SetStateFn } from '@strise/react-utils'
import { type LocationFilterInput } from '@strise/types'
import { Checkbox, Chip, type ChipProps, LoaderRound, cn } from '@strise/ui-components'
import { isBoolean, isObject, orderBy } from 'lodash-es'
import * as React from 'react'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useIndustriesQuery } from '~/graphqlOperations'
import { type IndustryFragment } from '~/graphqlTypes'
import { extractNaceName } from '~/utils/industryUtils'
import { userGrowSettingsToInput } from '~/utils/settingsUtils'
import { GrowSettingsSections } from '~/views/Grow/components/GrowSettingsPanel'
import {
  IndustriesSearchInput,
  type IndustryDownshiftItem
} from '~/views/Grow/components/Industry/GrowIndustrySearchInput'

interface TransformedIndustryNode extends IndustryFragment {
  children?: { edges: TransformedIndustryEdges }
}

type TransformedIndustryEdges = Array<{ node: TransformedIndustryNode }>

const sortIndustries = (edges: TransformedIndustryEdges): TransformedIndustryEdges => {
  return orderBy(edges, (edge) => edge.node.name)
}

export const IndustryChip = ({
  className,
  industry,
  onDelete,
  ...props
}: { industry: IndustryFragment; onDelete?: () => void } & ChipProps): React.ReactNode => {
  return (
    <Chip
      className={cn('mb-2 mr-2', className)}
      label={extractNaceName(industry)}
      onDelete={onDelete ? () => onDelete() : undefined}
      {...props}
    />
  )
}

const Industry = ({
  checked,
  industries,
  industry,
  parent,
  setIndustry
}: {
  checked: boolean
  industries?: Record<string, boolean> | boolean
  industry: TransformedIndustryNode
  parent?: TransformedIndustryNode
  setIndustry: (industry: IndustryFragment, parentId?: string) => void
}): React.ReactNode => {
  const hasChildren = isObject(industries) && Object.values(industries).some(Boolean)
  return (
    <>
      <Checkbox
        id={`filter-industry-${industry.id}`}
        checked={hasChildren ? 'indeterminate' : checked}
        onCheckedChange={() => setIndustry(industry, parent?.id)}
        data-track={`Grow Filter / Industry / ${industry.id}`}
        label={extractNaceName(industry)}
        labelProps={{ variant: 'aLabelSmall' }}
      />
      {(hasChildren || checked) && industry.children && (
        <div className='ml-6 space-y-1'>
          {sortIndustries(industry.children.edges).map(({ node }) => (
            <Industry
              key={node.id}
              industry={node}
              setIndustry={setIndustry}
              checked={isObject(industries) && Boolean(industries[node.id])}
              parent={industry}
            />
          ))}
        </div>
      )}
    </>
  )
}

const useIndustries = (
  locationFilter?: LocationFilterInput | null
): { industries: TransformedIndustryEdges; loading: boolean } => {
  const { data, loading } = useIndustriesQuery({
    variables: { locationFilter }
  })
  const industries = data?.industries.edges ?? []
  const sortedIndustries = sortIndustries(industries)
  return { industries: sortedIndustries, loading }
}

const useIndustriesObject = (
  industries: TransformedIndustryEdges,
  industriesState: IndustryFragment[]
): Record<string, boolean | Record<string, boolean>> => {
  const [industriesObject, setIndustriesObject] = React.useState<Record<string, Record<string, boolean> | boolean>>({})

  React.useEffect(() => {
    setIndustriesObject(() => {
      // eslint-disable-next-line unicorn/prefer-object-from-entries
      return industries.reduce(
        (acc, industry) => ({
          ...acc,
          [industry.node.id]:
            industriesState.some((otherIndustry) => otherIndustry.id === industry.node.id) ||
            // eslint-disable-next-line unicorn/prefer-object-from-entries
            industry.node.children?.edges.reduce(
              (childAcc, { node: childIndustry }) => ({
                ...childAcc,
                [childIndustry.id]: industriesState.some((otherIndustry) => otherIndustry.id === childIndustry.id)
              }),
              {}
            )
        }),
        {}
      )
    })
  }, [JSON.stringify(industries), JSON.stringify(industriesState)])

  return industriesObject
}

const extractSetIndustry =
  (
    industries: TransformedIndustryEdges,
    setIndustriesState: SetStateFn<IndustryFragment[]>
  ): ((selectedIndustry: IndustryFragment, selectedParentIndustryId?: string) => void) =>
  (selectedIndustry: IndustryFragment, selectedParentIndustryId?: string) => {
    setIndustriesState((prevIndustries: IndustryFragment[] | null) => {
      const prev = prevIndustries || []
      const toRemove = prev.some((industry) => industry.id === selectedIndustry.id)
      const topLevelIndustry = industries.find((industry) => industry.node.id === selectedIndustry.id)

      if (topLevelIndustry || !selectedParentIndustryId) {
        const childrenIds = topLevelIndustry?.node.children?.edges.map(({ node }) => node.id) ?? []

        if (toRemove) {
          return prev.filter((industry) => industry.id !== selectedIndustry.id)
        }

        return [...prev.filter((industry) => !childrenIds.includes(industry.id)), selectedIndustry]
      }

      // If a child industry is selected
      if (toRemove) {
        return prev.filter((industry) => industry.id !== selectedIndustry.id)
      }

      return [...prev.filter((industry) => industry.id !== selectedParentIndustryId), selectedIndustry]
    })
  }

export const GrowIndustryFilter = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const growSettings = settings.grow

  const growSettingsInput = userGrowSettingsToInput(settings)

  const { industries, loading } = useIndustries(growSettingsInput.opportunities?.locations)

  const industriesState = growSettings.industries

  const industriesObject = useIndustriesObject(industries, industriesState)

  const flattenedIndustries = industries.flatMap(({ node: industry }) => {
    const subIndustries =
      industry.children?.edges.map(({ node: subIndustry }) => ({
        ...subIndustry,
        parentId: industry.id
      })) ?? []
    return [industry, ...subIndustries]
  })

  const setIndustry = extractSetIndustry(industries, setChildState(saveSettings, 'grow.industries'))

  const addIndustryFromSearch = (industry: IndustryDownshiftItem | null): void => {
    if (!industry || industriesState.some((otherIndustry) => otherIndustry.id === industry.id)) return

    setIndustry(industry, industry.parentId)
  }

  const handleDelete =
    (industry: IndustryFragment & { parentId?: string }): (() => void) =>
    () => {
      setIndustry(industry, industry.parentId)
    }

  return (
    <GrowSettingsSections title={`${t`Industry`} (NACE)`}>
      {!!industriesState.length && (
        <div className='flex flex-wrap gap-1'>
          {industriesState.map((industry) => (
            <IndustryChip key={industry.id} industry={industry} onDelete={handleDelete(industry)} />
          ))}
        </div>
      )}

      <IndustriesSearchInput
        flattenedIndustries={flattenedIndustries}
        addIndustry={addIndustryFromSearch}
        removeIndustry={(industry) => handleDelete(industry)()}
        selectedIndustries={industriesState}
      />
      <div className='my-4 space-y-1'>
        {loading ? (
          <LoaderRound size='sm' className='mx-auto' />
        ) : (
          <>
            {industries.map(({ node: industry }) => (
              <Industry
                key={industry.id}
                industry={industry}
                setIndustry={setIndustry}
                checked={isBoolean(industriesObject[industry.id])}
                industries={industriesObject[industry.id]}
              />
            ))}
          </>
        )}
      </div>
    </GrowSettingsSections>
  )
}
