import { Trans, t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { Chip, IconButton, IconPenBox, Tooltip, Typography, cn } from '@strise/ui-components'
import React from 'react'
import { type CustomMetaFragment } from '~/graphqlTypes'

export const CustomMeta = ({
  className,
  customMeta,
  editMode,
  handleEdit
}: {
  customMeta?: CustomMetaFragment | null
  editMode?: boolean
  handleEdit?: () => void
} & React.HTMLAttributes<HTMLDivElement>): React.ReactNode => {
  if (!editMode && !customMeta) return null

  return editMode || !customMeta ? (
    <IconButton
      className='size-10 justify-self-center text-gray-60'
      variant='ghost'
      onClick={handleEdit}
      data-track='Toggle edit mode'
    >
      <IconPenBox size='md' />
    </IconButton>
  ) : (
    <Tooltip
      arrow
      content={
        <div className='grid place-items-center gap-2 p-1'>
          {customMeta.comment && <Typography variant='aLabelSmallBold'>"{customMeta.comment}"</Typography>}
          <Typography className='text-gray-20' variant='aLabelSmall'>
            <Trans>Edited by</Trans> {customMeta.lastModifiedBy?.name ?? t`Deleted user`},{' '}
            {formatDate(customMeta.lastModifiedAt, { relative: false })}
          </Typography>
        </div>
      }
    >
      <Chip
        className={cn(
          'mx-2 flex h-6 w-fit rounded-md bg-accent-blue-shade-5 text-accent-blue-shade-30 hover:bg-accent-blue-shade-5',
          className
        )}
        size='sm'
        label={<Trans>Edited</Trans>}
      />
    </Tooltip>
  )
}
