import React from 'react'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { type CompanyMetaFragment, type ReviewCreditScoreValueFragment } from '~/graphqlTypes'
import { type BaseCreditScore, CreditScore } from '~/utils/apiTable/CreditScore'

export const ReviewCreditScore = ({
  company,
  item
}: {
  company: CompanyMetaFragment | null
  item: ReviewCreditScoreValueFragment
}): React.ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const creditReport: BaseCreditScore = {
    ...item.value,
    __typename: 'CreditReportV2' as const
  }
  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <CreditScore company={company} creditReport={creditReport} />
    </ReviewItem>
  )
}
