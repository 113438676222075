import { Box, type BoxProps } from './Box'
import { Modal, ModalContent, ModalHeader, type ModalHeaderProps, type ModalProps } from './Modal'
import { Button, type ButtonProps, Typography, type TypographyProps, cn } from '@strise/ui-components'
import * as React from 'react'

export interface DialogProps extends ModalProps {
  body?: React.ReactNode
  containerProps?: BoxProps
  contentProps?: BoxProps
  footerProps?: BoxProps
  headerProps?: Omit<ModalHeaderProps, 'onClose'>
  modalContentProps?: BoxProps
  onClose?: () => void
  title?: string
  titleProps?: TypographyProps
}

export const Dialog = React.forwardRef(
  (
    {
      body,
      children,
      className,
      containerProps,
      contentProps,
      footerProps,
      headerProps,
      modalContentProps,
      onClose,
      title,
      titleProps,
      ...props
    }: DialogProps,
    ref
  ) => {
    const { className: containerPropsClassName, ...restContainerProps } = containerProps ?? {}
    return (
      <Modal
        className={className}
        ref={ref}
        contentMaxWidth={444}
        contentProps={{ maxHeight: 'calc(100% - 64px)', ...modalContentProps }}
        {...props}
      >
        <div
          className={cn('p-8', containerPropsClassName, containerProps?.bgcolor ?? 'bg-background-paper')}
          {...restContainerProps}
        >
          {title && (
            <ModalHeader color='text.primary' data-id='dialog-title' pb={8} mt={0} {...headerProps} onClose={onClose}>
              <Typography variant='subtitle1' {...titleProps}>
                {title}
              </Typography>
            </ModalHeader>
          )}

          <ModalContent p={0} {...contentProps}>
            {body && (
              <Box height='100%' data-id='dialog-body'>
                {body}
              </Box>
            )}
          </ModalContent>
        </div>
        <Box display='flex' {...footerProps}>
          {children}
        </Box>
      </Modal>
    )
  }
)

export interface ConfirmDialogProps extends Omit<DialogProps, 'body'> {
  cancelButtonProps?: ButtonProps
  cancelText?: string
  confirmButtonProps?: ButtonProps
  confirmText: string
  danger?: boolean
  disabled?: boolean
  loading?: boolean
  onCancel?: () => void
  onConfirm: () => void
  showCloseButton?: boolean
}

export const ConfirmDialog = ({
  cancelButtonProps,
  cancelText,
  children,
  className,
  confirmButtonProps,
  confirmText,
  danger,
  disabled,
  footerProps,
  loading,
  onCancel,
  onConfirm,
  showCloseButton,
  ...props
}: ConfirmDialogProps): React.ReactNode => {
  return (
    <Dialog
      className={className}
      body={children}
      {...props}
      onRequestClose={onCancel}
      onClose={showCloseButton ? onCancel : undefined}
      footerProps={footerProps}
    >
      {onCancel && (
        <Button
          className='h-12 w-full'
          variant='contained'
          palette='tertiary'
          onClick={onCancel}
          {...cancelButtonProps}
        >
          {cancelText}
        </Button>
      )}

      <Button
        className='h-12 w-full'
        variant='contained'
        palette={danger ? 'danger' : 'secondary'}
        onClick={onConfirm}
        loading={loading}
        disabled={disabled}
        {...confirmButtonProps}
      >
        {confirmText}
      </Button>
    </Dialog>
  )
}

interface PromptDialogProps extends Omit<DialogProps, 'body'> {
  buttonText: string
  disabled?: boolean
  loading?: boolean
  onConfirm: () => void
}

export const PromptDialog = ({
  buttonText,
  children,
  className,
  disabled,
  loading,
  onConfirm,
  ...props
}: PromptDialogProps): React.ReactNode => {
  return (
    <Dialog className={className} body={children} {...props}>
      <Button
        data-id='prompt-dialog-button'
        className='h-12 flex-1 grow justify-start font-medium'
        variant='contained'
        palette='primary'
        onClick={onConfirm}
        disabled={disabled}
        loading={loading}
      >
        {buttonText}
      </Button>
    </Dialog>
  )
}
