import { NationFlag, extractAddress, formatDate } from '@strise/app-shared'
import { IconBirthdayCake, Typography } from '@strise/ui-components'
import * as React from 'react'
import type { GlobalPersonBaseFragment, PersonBaseFragment } from '~/graphqlTypes'

interface PersonReviewInfoProps {
  person: PersonBaseFragment | GlobalPersonBaseFragment
}

const extractBirthDate = (person: PersonBaseFragment | GlobalPersonBaseFragment): string | null => {
  if ('birthDateV2' in person) {
    return person.birthDateV2
  }
  return person.birthDate ? formatDate(person.birthDate) : null
}

export const PersonReviewInfo = ({ person }: PersonReviewInfoProps): React.ReactNode => {
  const birthDate = extractBirthDate(person)

  return (
    <Typography variant='aLabelSmall' className='flex items-center gap-2 text-text-secondary'>
      {person.address && (
        <span className='flex items-center gap-2'>
          <NationFlag className='size-4' countryIsoCode={person.primaryCountry?.isoAlpha2Code} />
          {extractAddress(person.address)}
        </span>
      )}

      {birthDate && (
        <span className='flex items-center gap-2 whitespace-nowrap'>
          <IconBirthdayCake size='md' className='shrink-0' />
          {birthDate}
        </span>
      )}
    </Typography>
  )
}
