import { Trans, t } from '@lingui/macro'
import { Alert, IconButton, IconCopy, Input, Tooltip } from '@strise/ui-components'
import * as React from 'react'

interface IDVUrlProps {
  onUrlCopy: () => void
  url: string | null
}

export const IDVUrl = ({ onUrlCopy, url }: IDVUrlProps) => {
  if (!url) {
    return (
      <Alert variant='danger'>
        <Trans>Something went wrong. Please try again</Trans>
      </Alert>
    )
  }

  return (
    <div className='flex items-center gap-2'>
      <Input value={url} disabled className='w-full text-text-primary' />
      <Tooltip content={t`Copy URL`}>
        <IconButton data-track='IDV Check / Copy URL' variant='outlined' palette='tertiary' onClick={onUrlCopy}>
          <IconCopy />
        </IconButton>
      </Tooltip>
    </div>
  )
}
