import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useEntityQuery } from '~/graphqlOperations'
import {
  type BaseEntityLikeFragment,
  type EntityDataSourcesFragment,
  type EntityLikeMetaFragment,
  type OwnerChartNodeEntityFragment,
  type ShareholderFragment
} from '~/graphqlTypes'

export const useEntityLikeName = (id: string | null): string | null => {
  const { data } = useEntityQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: id! },
    skip: !id
  })
  const entity = data?.entity
  return entity?.name ?? null
}

/**
 * Identify "slim" entities that can be merged
 */
export const useIsMergeableEntity = (
  entity: BaseEntityLikeFragment | ShareholderFragment | OwnerChartNodeEntityFragment | null | undefined
): entity is (BaseEntityLikeFragment | ShareholderFragment | OwnerChartNodeEntityFragment) & {
  __typename: 'Company'
  isSlim: true
} => {
  const { USER_ASSISTED_ENTITY_MERGING: userAssistedEntityMergingEnabled } = useCurrentUserFeatures()

  if (!entity || !userAssistedEntityMergingEnabled) {
    return false
  }

  return entity.__typename === 'Company' && entity.isSlim
}

export const hasEntityAccess = (entity: BaseEntityLikeFragment | null | undefined): boolean => {
  return entity && 'access' in entity ? entity.access.hasAccess : false
}

export const isEntityActive = (entity: BaseEntityLikeFragment | null | undefined): boolean => {
  return entity && 'lifeStatus' in entity ? entity.lifeStatus.isActive : true
}

export const isGraniteEntity = (entity: BaseEntityLikeFragment | null | undefined): boolean => {
  if (entity) {
    if (entity.__typename == 'Company') {
      return (
        entity.isGlobalCompany ||
        entity.primaryCountry?.isoAlpha2Code === 'DE' ||
        entity.primaryCountry?.isoAlpha2Code === 'GB' ||
        entity.primaryCountry?.isoAlpha2Code === 'NL' ||
        entity.primaryCountry?.isoAlpha2Code === 'BE' ||
        entity.primaryCountry?.isoAlpha2Code === 'LU'
      )
    }
    if (entity.__typename == 'Person') {
      return (
        entity.isGlobalPerson ||
        entity.primaryCountry?.isoAlpha2Code === 'DE' ||
        entity.primaryCountry?.isoAlpha2Code === 'GB' ||
        entity.primaryCountry?.isoAlpha2Code === 'NL' ||
        entity.primaryCountry?.isoAlpha2Code === 'BE' ||
        entity.primaryCountry?.isoAlpha2Code === 'LU'
      )
    }
  }

  return false
}

export const hasDataSources = (
  entity: BaseEntityLikeFragment
): entity is EntityLikeMetaFragment & { dataSources: EntityDataSourcesFragment } => {
  return 'dataSources' in entity
}

export type EntityIdentity = Pick<BaseEntityLikeFragment, 'id' | '__typename'>
