import { uuid } from '@jsplumb/browser-ui'
import { t } from '@lingui/macro'
import { toast, useCurrentUser, useIsSupervisor } from '@strise/app-shared'
import { type SetStateFn } from '@strise/react-utils'
import {
  type CreateEntityDispositionInput,
  EntityDispositionKind,
  EntityDispositionStatusKind,
  ReviewSectionKind
} from '@strise/types'
import { Button, DialogFooter, cn } from '@strise/ui-components'
import React from 'react'
import { dialogBgColor } from '~/components/PepAndSanctions/dispositionDialogUtils'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useCreateEntityDispositionsMutation, useDeleteEntityDispositionsMutation } from '~/graphqlOperations'
import { type PepInfoFragment, type PersonBaseFragment } from '~/graphqlTypes'
import { refreshReviewSections } from '~/state'

export const PepDispositionDialogActions = ({
  extractInputs,
  onCompleted,
  pepInfos,
  person,
  setIsAuditTrailOpen
}: {
  extractInputs: (status: EntityDispositionStatusKind) => CreateEntityDispositionInput[]
  onCompleted: () => void
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment
  setIsAuditTrailOpen: SetStateFn<boolean>
}): React.ReactNode => {
  const currentUser = useCurrentUser()
  const isSupervisor = useIsSupervisor()
  const [statusLoading, setStatusLoading] = React.useState<EntityDispositionStatusKind | null>(null)
  const features = useCurrentUserFeatures()

  const handleUpdateReviewCache = () => {
    const currentNonce = refreshReviewSections()[ReviewSectionKind.PepsAndSanctions] ?? 0
    refreshReviewSections({ [ReviewSectionKind.PepsAndSanctions]: currentNonce + 1 })
  }

  const handleCompleted = (): void => {
    onCompleted()
    setStatusLoading(null)
    handleUpdateReviewCache()
    toast.success(t`PEP dispositioning saved`)
  }

  const [create] = useCreateEntityDispositionsMutation({ onCompleted: handleCompleted })
  const [remove, { loading: deleteLoading }] = useDeleteEntityDispositionsMutation({
    onCompleted: handleUpdateReviewCache
  })

  const handleDelete = async (): Promise<void> => {
    await remove({
      variables: {
        entity: person.id,
        includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
        includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING,
        kind: EntityDispositionKind.Pep
      }
    })
  }

  const handleSave = (status: EntityDispositionStatusKind) => async (): Promise<void> => {
    const inputs = extractInputs(status)

    if (!inputs.length) {
      toast.error(t`No PEPs selected`)
      return
    }

    setStatusLoading(status)

    const nextPepInfos: PepInfoFragment[] = pepInfos.map((pepInfo) => {
      const matchingInput = inputs.find((input) => input.externalId === pepInfo.id)

      return {
        ...pepInfo,
        disposition: matchingInput
          ? {
              id: uuid(),
              status: matchingInput.status,
              createdBy: currentUser,
              created: new Date().toISOString(),
              comment: matchingInput.comment ?? null,
              __typename: 'EntityDisposition'
            }
          : null
      }
    })

    await create({
      variables: {
        entity: person.id,
        includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
        includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING,
        inputs
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createEntityDispositions: {
          __typename: 'MutationQuery',
          person: {
            ...person,
            pepInfos: nextPepInfos
          }
        }
      }
    })

    // TODO: Company network cache update
    // TODO: Risk summary cache update
  }

  const disabled = !!statusLoading || deleteLoading

  return (
    <DialogFooter>
      <div className={cn('flex w-full gap-4 items-center', dialogBgColor)}>
        {isSupervisor && (
          <Button
            variant='ghost'
            palette='danger'
            onClick={handleDelete}
            loading={deleteLoading}
            disabled={disabled}
            data-track='Pep disposition dialog / Reset'
          >
            Reset all (admin)
          </Button>
        )}
        <Button
          variant='ghost'
          onClick={() => setIsAuditTrailOpen(true)}
          disabled={disabled}
          data-track='Pep disposition dialog / Open audit trail'
        >
          {t`See audit trail`}
        </Button>
        <Button
          className='ml-auto'
          variant='contained'
          palette='primary'
          onClick={handleSave(EntityDispositionStatusKind.ConfirmedTrue)}
          loading={statusLoading === EntityDispositionStatusKind.ConfirmedTrue}
          disabled={disabled}
          data-track='Pep disposition dialog / Confirm PEP status'
        >
          {t`Confirm PEP status`}
        </Button>
        <Button
          variant='contained'
          palette='secondary'
          onClick={handleSave(EntityDispositionStatusKind.ConfirmedFalse)}
          loading={statusLoading === EntityDispositionStatusKind.ConfirmedFalse}
          disabled={disabled}
          data-track='Pep disposition dialog / Mark as false positive(s)'
        >
          {t`Mark as false positive(s)`}
        </Button>
      </div>
    </DialogFooter>
  )
}
