import * as React from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { SidepanelCompanyOwnersCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCompanyOwnersCard'
import { SidepanelGlobalCompanyHeaderCard } from '~/components/Sidepanel/SidepanelCards/SidepanelGlobalCompanyHeaderCard'
import { SidepanelOwnershipsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelOwnershipsCard'
import { SidepanelRolesCard } from '~/components/Sidepanel/SidepanelCards/SidepanelRolesCard'
import { SidepanelSanctionsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelSanctionsCard'
import { SidepanelSimilarEntities } from '~/components/Sidepanel/SidepanelCards/SidepanelSimilarEntities'
import { SidepanelTableCard } from '~/components/Sidepanel/SidepanelTableCard'
import { type TabRefs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { type SidepanelGlobalCompanyFragment } from '~/graphqlTypes'
import { hasEntityAccess, isEntityActive } from '~/utils/entity'
import { SidepanelTab } from '~/utils/urls'

export const SidepanelGlobalCompanyLayout: React.FC<{
  globalCompany: SidepanelGlobalCompanyFragment
  tabRefs: TabRefs
}> = ({ globalCompany, tabRefs }) => {
  const isActive = isEntityActive(globalCompany)
  const [showOriginal, setShowOriginal] = useState(false)

  return (
    <>
      <Helmet>
        <title>{globalCompany.name}</title>
      </Helmet>
      <div className={isActive ? undefined : 'text-text-secondary'}>
        <SidepanelGlobalCompanyHeaderCard globalCompany={globalCompany} ref={tabRefs[SidepanelTab.Company]} />
      </div>
      {hasEntityAccess(globalCompany) && (
        <>
          <SidepanelSimilarEntities />

          <SidepanelTableCard
            entity={globalCompany}
            trackId='Global company information'
            table={globalCompany.companyInformation}
          />

          <SidepanelSanctionsCard entity={globalCompany} />

          <SidepanelRolesCard
            entity={globalCompany}
            sidepanelTab={SidepanelTab.People}
            ref={tabRefs[SidepanelTab.People]}
          />

          <SidepanelCompanyOwnersCard company={globalCompany} showOriginal={showOriginal} />

          <SidepanelOwnershipsCard
            ref={tabRefs[SidepanelTab.Ownership]}
            sidepanelTab={SidepanelTab.Ownership}
            entity={globalCompany}
            showOriginal={showOriginal}
            setShowOriginal={setShowOriginal}
          />
        </>
      )}
    </>
  )
}
