import { t } from '@lingui/macro'
import { EntityFlag, extractIsActive } from '@strise/app-shared'
import { useContext, useKeyboardShortcut } from '@strise/react-utils'
import {
  IconArrowLeft,
  IconArrowRight,
  IconButton,
  IconCollapseAnimated,
  IconCross,
  IconExpandAnimated,
  Link as UILink,
  cn
} from '@strise/ui-components'
import React, { useEffect } from 'react'
import { DataSourceTooltip } from '~/components/DataSourceTooltip'
import { useNavigateEntity } from '~/components/EntityLink/entityLinkUtils'
import { EntityMetaItems } from '~/components/EntityMeta/EntityMetaItems'
import { SanctionIcon } from '~/components/SanctionIcon'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { SidepanelNavHistoryContext } from '~/components/Sidepanel/SidepanelNavHistoryContext'
import { type SupportedSidepanelEntityFragment } from '~/components/Sidepanel/utils/sidepanelUtils'
import { useIsMobile } from '~/utils/hooks'
import { TestIDs } from '~/utils/testIDs'
import { useActiveContentView } from '~/utils/viewsHooks'
import { POPPER_Z_INDEX } from '~/utils/zIndexes'

const SidepanelNavigation: React.FC<{
  id: string
}> = ({ id }) => {
  const activeContentView = useActiveContentView()
  const navigateEntity = useNavigateEntity()
  const navigating = React.useRef(false)
  const { currentIndex, setCurrentIndex, setSidepanelHistory, sidepanelHistory } =
    useContext(SidepanelNavHistoryContext)

  useEffect(() => {
    if (navigating.current) {
      navigating.current = false
      return
    }

    if (id === sidepanelHistory[currentIndex]) return

    setSidepanelHistory((prevHistory) => {
      const newHistory = [...prevHistory.slice(0, currentIndex + 1), id]
      setCurrentIndex(newHistory.length - 1)
      return newHistory
    })
  }, [id])

  const handleNavigate =
    (newIndex: number): ((event: React.MouseEvent) => void) =>
    (event: React.MouseEvent) => {
      if (!activeContentView) return

      navigating.current = true
      setCurrentIndex(newIndex)

      const matchingHistoryEntityId = sidepanelHistory[newIndex]
      if (!matchingHistoryEntityId) return

      navigateEntity(matchingHistoryEntityId, event.ctrlKey || event.metaKey)
    }

  return (
    <header className='flex w-32 shrink-0 items-center justify-between'>
      <DataSourceTooltip content={t`Back`}>
        <IconButton
          className='h-full grow'
          variant='contained'
          palette='tertiary'
          onClick={handleNavigate(currentIndex - 1)}
          disabled={currentIndex === 0}
          data-track='Sidepanel / Navigation / Back button'
          aria-label={t`Back Button`}
        >
          <IconArrowLeft display='block' />
        </IconButton>
      </DataSourceTooltip>
      <DataSourceTooltip content={t`Forward`}>
        <IconButton
          className='h-full grow'
          variant='contained'
          palette='tertiary'
          onClick={handleNavigate(currentIndex + 1)}
          disabled={currentIndex >= sidepanelHistory.length - 1}
          data-track='Sidepanel / Navigation / Forward button'
          aria-label={t`Forward Button`}
        >
          <IconArrowRight display='block' />
        </IconButton>
      </DataSourceTooltip>
    </header>
  )
}

const SidepanelHeaderContent: React.FC<{
  entity: SupportedSidepanelEntityFragment | null | undefined
}> = ({ entity }) => {
  const { scrollEl, showHeaderEntity } = useContext(SidepanelContext)

  if (!entity || !showHeaderEntity) return null

  const isCompany = entity.__typename === 'Company'
  const isPerson = entity.__typename === 'Person'

  const isActive = extractIsActive(entity)

  const toTop = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()
    if (scrollEl.current) {
      scrollEl.current.scrollTop = 0
    }
  }

  return (
    <>
      <div className='flex flex-row items-center'>
        <UILink
          href=''
          variant='h3'
          className={cn('mr-1 truncate', !isActive && 'text-text-secondary line-through')}
          onClick={toTop}
        >
          {entity.name}
        </UILink>
        {(isCompany || isPerson) && (
          <EntityFlag className='my-0 ml-2' entityOrId={entity} chipProps={{ palette: 'tertiary' }} />
        )}
        <SanctionIcon className='ml-1' entityId={entity.id} sanctionsLink />
      </div>
      <EntityMetaItems entity={entity} />
    </>
  )
}

export const SidepanelHeader = ({
  entity,
  id,
  onClose
}: {
  entity: SupportedSidepanelEntityFragment | null | undefined
  id: string
  onClose: () => void
}): React.ReactNode => {
  const [expandCollapseHover, setExpandCollapseHover] = React.useState(false)
  const { expanded, setExpanded } = useContext(SidepanelContext)
  const toggleExpanded = (): void => setExpanded((prev) => !prev)

  const isMobile = useIsMobile()

  useKeyboardShortcut('Escape', () => {
    onClose()
  })

  const handleExpandClick = (): void => {
    // Force React hover state to false when clicking the expand/collapse button
    setExpandCollapseHover(false)
    toggleExpanded()
  }

  return (
    <div className='relative flex h-sub-header bg-secondary-shade-10' style={{ zIndex: POPPER_Z_INDEX }}>
      <div className='flex'>
        <DataSourceTooltip content={t`Close sidepanel`} aria-label={t`Close sidepanel`}>
          <IconButton
            className='h-full w-16'
            variant='contained'
            palette='tertiary'
            data-track='Sidepanel / Close button'
            onClick={onClose}
            data-id={TestIDs.SidePanel.Header.CloseButton}
          >
            <IconCross />
          </IconButton>
        </DataSourceTooltip>
        {!isMobile && (
          <DataSourceTooltip content={expanded ? t`Minimize` : t`Expand`} open={expandCollapseHover}>
            <IconButton
              onMouseEnter={() => setExpandCollapseHover(true)}
              onMouseLeave={() => setExpandCollapseHover(false)}
              className='w-16 legacy-xs:hidden legacy-md:inline-flex'
              variant='contained'
              palette='tertiary'
              aria-label={expanded ? t`Minimize` : t`Expand`}
              onClick={handleExpandClick}
              data-track='Sidepanel / Expand button'
            >
              {expanded ? (
                <IconCollapseAnimated isHovered={expandCollapseHover} />
              ) : (
                <IconExpandAnimated isHovered={expandCollapseHover} />
              )}
            </IconButton>
          </DataSourceTooltip>
        )}
      </div>
      <div className='flex grow basis-0 flex-col justify-center overflow-hidden px-4'>
        <SidepanelHeaderContent entity={entity} />
      </div>
      <SidepanelNavigation id={id} />
    </div>
  )
}
