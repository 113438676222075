import { AuthError, useLogout, useUserTeams } from '@strise/app-shared'
import { getBrowserGlobals } from '@strise/react-utils'
import { filterNullishValues } from '@strise/ts-utils'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { setClientVariable } from '~/apolloClient/apolloClientUtils'
import { useClearApolloClientCache } from '~/apolloClient/useClearApolloClientCache'
import { QUERY_PARAMS, STORAGE_KEYS } from '~/constants'
import { TeamContext, type TeamContextProps } from '~/contexts/TeamContext/TeamContext'
import { type TeamEdgeFragment } from '~/graphqlTypes'
import { portfolio, team } from '~/state'

const resolveTeam = (teams: TeamEdgeFragment[]): TeamEdgeFragment | null => {
  const browserGlobals = getBrowserGlobals()
  try {
    const resolveIds = [
      browserGlobals &&
        new browserGlobals.window.URLSearchParams(getBrowserGlobals()?.window.location.search).get(QUERY_PARAMS.team),
      browserGlobals?.window.localStorage.getItem(STORAGE_KEYS.team),
      teams[0]?.node.id
    ]

    return (
      // filter by resolve ids and return the first match from teams, ensuring that precedence is given to the query param and localStorage
      filterNullishValues(resolveIds.map((resolveId) => teams.find(({ node }) => node.id === resolveId) ?? null))[0] ??
      null
    )
  } catch (e) {
    return null
  }
}

export const TeamContextProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const [currentTeam, setCurrentTeam] = React.useState<TeamContextProps | null>(null)
  const logout = useLogout()
  const teams = useUserTeams()
  const navigate = useNavigate()

  const clearCache = useClearApolloClientCache()

  const setTeam = React.useCallback(
    async (newTeamId: string) => {
      // clean current cache
      await clearCache()

      const params = new URLSearchParams()
      params.set(QUERY_PARAMS.team, newTeamId)
      navigate(
        { pathname: getBrowserGlobals()?.window.location.pathname, search: params.toString() },
        { replace: true }
      )
      getBrowserGlobals()?.window.location.reload()
    },
    [clearCache]
  )

  React.useEffect(() => {
    const resolvedTeamEdge = resolveTeam(teams)
    const portfolioId = resolvedTeamEdge?.node.portfolios.edges[0]?.node.id

    if (!resolvedTeamEdge || !portfolioId) {
      logout(resolvedTeamEdge ? AuthError.NoPortfolio : AuthError.NoTeam)
    } else {
      getBrowserGlobals()?.window.localStorage.setItem(STORAGE_KEYS.team, resolvedTeamEdge.node.id)
      team(resolvedTeamEdge.node.id)
      portfolio(portfolioId)

      setClientVariable('team', resolvedTeamEdge.node.id)
      setClientVariable('portfolio', portfolioId)

      setCurrentTeam({
        id: resolvedTeamEdge.node.id,
        name: resolvedTeamEdge.node.name,
        portfolioId,
        customerId: resolvedTeamEdge.node.customer?.id ?? '',
        role: resolvedTeamEdge.role,
        status: resolvedTeamEdge.status,
        setTeam
      })
    }

    return () => {
      team(null)
      portfolio(null)
      setClientVariable('team', '')
      setClientVariable('portfolio', '')
    }
  }, [])

  if (!currentTeam) return null

  return <TeamContext.Provider value={currentTeam}>{children}</TeamContext.Provider>
}
