import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconPenBox = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M16 .586 23.414 8l-15 15H1v-7.414l15-15ZM3 16.414V21h4.586l13-13L16 3.414l-13 13Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m7.293 22.707-6-6 1.414-1.414 6 6-1.414 1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconPenBox.displayName = 'IconPenBox'
