import { cn } from '../../utils/className'
import { type IconComponent, type IconProps, iconVariants } from '../../utils/iconUtils'
import { type AnimationConfig, animated, useSpring } from '@react-spring/web'
import { interpolatePath } from 'd3-interpolate-path'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

export interface AnimatedIconProps extends IconProps {
  /** Customizable animation config from @react-spring/web */
  animationConfig?: AnimationConfig
  /** Optional controlled hover state - need JavaScript to be able to trigger the animation */
  isHovered?: boolean
}

interface SvgMorphingWrapperProps extends AnimatedIconProps {
  /** Initial frame of the animation */
  SvgComponentA: IconComponent
  /** Hover frame of the animation */
  SvgComponentB: IconComponent
}

/**
 * Wrapper component for morphing animations between two SVG components based on hover state.
 * Currently only supports paths and rects. Both components need to have the same amount of paths and rects,
 * and the paths has to be in the same order, or else the animation ends up being funky.
 */
export const SvgMorphingWrapper = React.forwardRef<SVGSVGElement, SvgMorphingWrapperProps>(
  ({ SvgComponentA, SvgComponentB, animationConfig, className, isHovered: controlledHover, size, ...props }, ref) => {
    const [uncontrolledHover, setUncontrolledHovered] = useState(false)

    const hovered = controlledHover === undefined ? uncontrolledHover : controlledHover

    // Hidden SVG refs
    const svgARef = useRef<SVGSVGElement | null>(null)
    const svgBRef = useRef<SVGSVGElement | null>(null)

    // Path element values
    const [pathsA, setPathsA] = useState<string[]>([])
    const [pathsB, setPathsB] = useState<string[]>([])

    // Rect element values
    const [rectsA, setRectsA] = useState<Array<{ height: number; width: number; x: number; y: number }>>([])
    const [rectsB, setRectsB] = useState<Array<{ height: number; width: number; x: number; y: number }>>([])

    useEffect(() => {
      if (svgARef.current !== null && svgBRef.current !== null) {
        // Reason for using Array.from, as typing does not work with spread on NodeList - NodeList docs:
        // Although NodeList is not an Array, it is possible to iterate over it with forEach().
        // It can also be converted to a real Array using Array.from().

        // Path element value extraction
        // eslint-disable-next-line unicorn/prefer-spread
        const extractedPathsA = Array.from(svgARef.current.querySelectorAll('path')).map(
          (p) => p.getAttribute('d') || ''
        )
        // eslint-disable-next-line unicorn/prefer-spread
        const extractedPathsB = Array.from(svgBRef.current.querySelectorAll('path')).map(
          (p) => p.getAttribute('d') || ''
        )
        setPathsA(extractedPathsA)
        setPathsB(extractedPathsB)

        // Rect element value extraction
        // eslint-disable-next-line unicorn/prefer-spread
        const extractedRectsA = Array.from(svgARef.current.querySelectorAll('rect')).map((rect) => ({
          x: Number.parseFloat(rect.getAttribute('x') || '0'),
          y: Number.parseFloat(rect.getAttribute('y') || '0'),
          width: Number.parseFloat(rect.getAttribute('width') || '0'),
          height: Number.parseFloat(rect.getAttribute('height') || '0')
        }))
        // eslint-disable-next-line unicorn/prefer-spread
        const extractedRectsB = Array.from(svgBRef.current.querySelectorAll('rect')).map((rect) => ({
          x: Number.parseFloat(rect.getAttribute('x') || '0'),
          y: Number.parseFloat(rect.getAttribute('y') || '0'),
          width: Number.parseFloat(rect.getAttribute('width') || '0'),
          height: Number.parseFloat(rect.getAttribute('height') || '0')
        }))
        setRectsA(extractedRectsA)
        setRectsB(extractedRectsB)
      }
    }, [])

    const springs = useSpring({
      to: {
        t: hovered ? 1 : 0
      },
      config: {
        tension: 400,
        friction: 50,
        ...animationConfig
      }
    })

    const handleMouseEnter = (): void => {
      if (controlledHover === undefined) {
        setUncontrolledHovered(true)
      }
    }

    const handleMouseLeave = (): void => {
      if (controlledHover === undefined) {
        setUncontrolledHovered(false)
      }
    }

    if (pathsA.length !== pathsB.length) {
      console.error('Both SVG components must have the same number of paths!')
      return null
    }

    if (rectsA.length !== rectsB.length) {
      console.error('Both SVG components must have the same number of rects!')
      return null
    }

    // This will create an animation value for each path pair, each transitioning from 0 to 1 on hover
    const interpolators = pathsA.map((dFrom, index) => interpolatePath(dFrom, pathsB[index] || ''))

    return (
      <>
        <SvgComponentA ref={svgARef} className='hidden' />
        <SvgComponentB ref={svgBRef} className='hidden' />
        <svg
          className={cn(
            iconVariants({
              size
            }),
            className
          )}
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          ref={ref}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...props}
        >
          {rectsA.map((rect, index) => (
            <animated.rect
              key={`rect-${index}`}
              x={springs.t.to((t) => rect.x + t * ((rectsB[index]?.x || 0) - rect.x))}
              y={springs.t.to((t) => rect.y + t * ((rectsB[index]?.y || 0) - rect.y))}
              width={springs.t.to((t) => rect.width + t * ((rectsB[index]?.width || 0) - rect.width))}
              height={springs.t.to((t) => rect.height + t * ((rectsB[index]?.height || 0) - rect.height))}
              stroke='currentColor'
              strokeWidth='2'
              fill='transparent'
            />
          ))}
          {interpolators.map((interpolator, index) => (
            <animated.path
              key={index}
              d={springs.t.to((t) => interpolator(t))}
              stroke='currentColor'
              strokeWidth='2'
              fill='transparent'
            />
          ))}
        </svg>
      </>
    )
  }
)
SvgMorphingWrapper.displayName = 'SvgMorphingWrapper'
