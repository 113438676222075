import { decode, encode } from './string'
import { filterNullishValues } from '@strise/ts-utils'

const ISODateRegex = /\d{4}-[01]\d-[0-3]\dT[0-2](?:\d:[0-5]){2}\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/

export const validateBoolean = (value: string): boolean | null => {
  if (value === 'true') return true
  if (value === 'false') return false
  return null
}

export const validateString = (value: string): string => value
export const validateStringOrNull = (value: string | null): string | null => {
  if (value === 'null') return null
  return value
}

export const validateArray =
  <T>(validateFn: (value: string) => T | null): ((values: string) => T[]) =>
  (values: string): T[] => {
    return filterNullishValues(values.split(',').map(validateFn))
  }
export const validateNullableArray =
  <T extends string>(validateFn: (value: string) => T | null): ((values: string) => (T | null)[]) =>
  (values: string) => {
    return values.split(',').map(validateFn)
  }

export const validateDate = (value: string): Date | null => {
  if (value.match(ISODateRegex)) return new Date(value)
  return null
}
export const validateNumber = (value: string): number | null => {
  const parsed = Number.parseInt(value, 10)
  return Number.isNaN(parsed) ? null : parsed
}

// Types below from https://github.com/microsoft/TypeScript/issues/30611#issuecomment-1295497089
type EnumValueType = string | number | symbol
type EnumType = { [key in EnumValueType]: EnumValueType }

export const validateEnum = <T>(enumToValidate: EnumType & T): ((value: string | number) => T[keyof T] | null) => {
  const enumValues = Object.values(enumToValidate)
  return (value: string | number): T[keyof T] | null => {
    if (enumValues.includes(value)) return value as T[keyof T]
    return null
  }
}

export const validateObject = <T extends object>(value: string): T | null => {
  try {
    return JSON.parse(decode(value)) as T
  } catch {
    return null
  }
}

export const serializeObject = (value: object): string => {
  return encode(JSON.stringify(value))
}
