import { getBrowserGlobals } from './browserGlobals'
import { useEffect, useState } from 'react'

interface UseAppVersionManagerParams {
  fetchInterval?: number
  onSetVersion?: (version: string) => void
  pathname: string
  storageKey: string
  versionUrl: string
}

/**
 * A custom React hook that manages application version checking and updates.
 *
 * This hook performs the following:
 * 1. Fetches the version from a specified URL
 * 2. Periodically checks for version updates based on the provided interval
 * 3. Updates the version state if a new version is detected
 * 4. Stores the version in localStorage
 * 5. Triggers a hard refresh when a version mismatch is detected
 * 6. Executes an optional callback when the version is updated
 *
 * @param {Object} params - The hook parameters
 * @param {number} [params.fetchInterval=120000] - Interval for checking version updates in milliseconds
 * @param {(version: string) => void} [params.onSetVersion] - Callback function when version is updated
 * @param {string} params.pathname - The current pathname, used for triggering checks on route change
 * @param {string} params.storageKey - LocalStorage key for storing the version
 * @param {string} params.versionUrl - URL to fetch the deployed version
 */
export const useAppVersionManager = ({
  fetchInterval = 120_000,
  onSetVersion,
  pathname,
  storageKey,
  versionUrl
}: UseAppVersionManagerParams): void => {
  const [version, setVersion] = useState<string | undefined>()

  useEffect(() => {
    if (!versionUrl) return

    /**
     * Fetches the current version from the specified URL.
     * Updates the version state if a new version is detected.
     */
    const fetchVersion = async (): Promise<void> => {
      try {
        const response = await fetch(versionUrl)
        const data: string = await response.text()

        if (data !== version) {
          setVersion(data)
          onSetVersion?.(data)
        }
      } catch (error) {
        console.error('Failed to fetch version:', error)
      }
    }

    fetchVersion()
    // Set up periodic version checks
    const versionCheckInterval = setInterval(fetchVersion, fetchInterval)

    // Clean up the interval on component unmount
    return (): void => {
      clearInterval(versionCheckInterval)
    }
  }, [versionUrl, version, fetchInterval, onSetVersion, pathname])

  useEffect(() => {
    if (version) {
      const currentVersion = localStorage.getItem(storageKey) || ''

      // Update the stored version if it's different from the deployed version
      if (currentVersion !== version) {
        localStorage.setItem(storageKey, version)
      }

      // Trigger a hard refresh if there's a version mismatch
      if (currentVersion && currentVersion !== version) {
        hardRefresh()
      }
    }
  }, [version, storageKey])
}

export const hardRefresh = async (reloadAfterClear = true): Promise<void> => {
  const window = getBrowserGlobals()?.window

  if (window && 'caches' in window) {
    const cacheKeys = await caches.keys()

    await Promise.all(cacheKeys.map(async (key) => await caches.delete(key)))

    if (reloadAfterClear) window.location.reload()
  }
}
