import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconLike = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M17.24 6.14c.268-.807.17-1.636-.079-2.337a4.94 4.94 0 0 0-1.172-1.839c-.48-.47-1.136-.9-1.876-.958-.39-.03-.8.046-1.176.275-.372.226-.645.563-.835.95-.202.413-.398.96-.558 1.455-.319.983-.816 1.866-1.503 2.553a9.007 9.007 0 0 0-2.087 3.268l-.252.688h-.378A1.992 1.992 0 0 0 6 9.695H3a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h3c.51 0 .975-.191 1.328-.505l1.339 1.004h9.754a3.789 3.789 0 0 0 3.249-1.84c.519-.864.87-1.819 1.036-2.814l.129-.77c.11-.661.165-1.329.165-1.998v-.932c0-1.198-.193-2.387-.572-3.523a3.104 3.104 0 0 0-2.945-2.123h-3.006l.462-1.156.3-.899ZM6 19.693v-8H3v8h3Zm2-.5v-7h1.098l.733-1.998a7.007 7.007 0 0 1 1.624-2.543c.952-.952 1.595-2.128 1.991-3.35.156-.478.316-.913.452-1.19A.524.524 0 0 1 13.967 3c.09.011.322.098.62.39.29.284.545.673.69 1.08.145.41.155.766.065 1.036l-.281.843-1.538 3.844h5.96c.476 0 .898.304 1.048.755.31.932.469 1.908.469 2.89v.932c0 .56-.046 1.118-.138 1.67l-.129.77a6.038 6.038 0 0 1-.778 2.115 1.789 1.789 0 0 1-1.534.868H9.333l-1.333-1Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconLike.displayName = 'IconLike'
