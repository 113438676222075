import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconWarningExclamation = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      {...props}
    >
      <path d='M7.3335 1.33325H8.66683V11.9999H7.3335V1.33325Z' fill='currentColor' />
      <path d='M7.3335 13.3333H8.66683V14.6666H7.3335V13.3333Z' fill='currentColor' />
    </svg>
  )
)
IconWarningExclamation.displayName = 'IconWarningExclamation'
