import { t } from '@lingui/macro'
import { Empty } from '@strise/app-shared'
import { useEntityAmsQuery } from '@strise/app-shared/src/graphqlOperations'
import React from 'react'
import { Event } from '~/components/Events/Event'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { TruncatedList } from '~/components/TruncatedList'
import { useTeamId } from '~/contexts/TeamContext/TeamContext'
import { type SidepanelPrivatePersonFragment } from '~/graphqlTypes'
import { EventsLoader } from '~/loaders/EventLoader'

// Only PrivatePerson has ams for now
export const SidepanelAmsCard = ({ entity }: { entity: SidepanelPrivatePersonFragment }) => {
  const teamId = useTeamId()
  const { data, loading } = useEntityAmsQuery({
    variables: {
      id: entity.id,
      teamId
    }
  })

  const ams = data?.entity && 'ams' in data.entity ? data.entity.ams : null

  return (
    <SidepanelCard title={t`Adverse Media Screening`} id='ams'>
      <div className='bg-secondary-shade-5 px-4 pt-4'>
        {loading && <EventsLoader size={5} />}
        {ams && (
          <TruncatedList context='sidepanel' items={ams} truncateAfter={10} className='flex flex-col gap-y-4'>
            {(item) => (
              <Event entityId={entity.id} event={item} disableInteractions bgClassName='bg-background-paper' />
            )}
          </TruncatedList>
        )}
        {ams && ams.length === 0 && <Empty title={t`No events found`} />}
      </div>
    </SidepanelCard>
  )
}
