import { defineMessage } from '@lingui/macro'
import { type SetStateFn, usePersistentState } from '@strise/react-utils'
import { type TableHeadCellProps } from '@strise/ui-components-legacy'
import * as React from 'react'
import { STORAGE_KEYS } from '~/constants'

export const portfolioColumnTitles = {
  name: defineMessage({ message: 'Company' }),
  flags: defineMessage({ message: 'Flags' }),
  status: defineMessage({ message: 'Status' }),
  statusModified: defineMessage({ message: 'Status modified' }),
  assignees: defineMessage({ message: 'Assignees' }),
  employees: defineMessage({ message: 'Employees' }),
  revenue: defineMessage({ message: 'Revenue' }),
  hq: defineMessage({ message: 'HQ' }),
  tags: defineMessage({ message: 'Tags' })
}

export type PortfolioField = keyof typeof portfolioColumnTitles

export interface PortfolioTableColumn {
  dataId?: string
  field: PortfolioField
  headCellProps: TableHeadCellProps
  removable: boolean
  sortable: boolean
  title?: React.ReactNode
}

export const portfolioColumns: PortfolioTableColumn[] = [
  {
    field: 'name',
    sortable: true,
    removable: false,
    headCellProps: {
      width: '300px'
    }
  },
  {
    field: 'flags',
    sortable: true,
    removable: false,
    headCellProps: {
      width: '120px'
    }
  },
  {
    field: 'status',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '220px'
    },
    dataId: 'Company Table Header / Status'
  },
  {
    field: 'statusModified',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '200px'
    }
  },
  {
    field: 'assignees',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '250px'
    },
    dataId: 'Company Table Header / Assignees'
  },
  {
    field: 'tags',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '290px'
    },
    dataId: 'Company Table Header / Tags'
  },
  {
    field: 'employees',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '150px'
    }
  },
  {
    field: 'revenue',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '150px'
    }
  },
  {
    field: 'hq',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '250px'
    }
  }
]

export type PortfolioEnabledColumns = {
  assignees: boolean
  employees: boolean
  hq: boolean
  revenue: boolean
  status: boolean
  statusModified: boolean
  tags: boolean
}

export const usePortfolioColumns = (): {
  enabledColumns: PortfolioEnabledColumns
  includeVariables: Omit<PortfolioEnabledColumns, 'status'>
  setEnabledColumns: SetStateFn<PortfolioEnabledColumns>
} => {
  const [enabledColumns, setEnabledColumns] = usePersistentState<PortfolioEnabledColumns>(
    STORAGE_KEYS.portfolioCompaniesColumns,
    {
      hq: true,
      revenue: true,
      status: true,
      statusModified: true,
      employees: true,
      tags: true,
      assignees: true
    }
  )

  const includeVariables = React.useMemo(() => {
    const portfolioIncludeVariables = ['hq', 'revenue', 'statusModified', 'employees', 'tags', 'assignees'] as const

    const variablesMap = Object.fromEntries(
      // Doing this to make sure we don't pass or forget any variables when upgrading the localstorage state
      portfolioIncludeVariables.map((key) => {
        return [key, Boolean(enabledColumns[key])]
      })
    ) as Omit<PortfolioEnabledColumns, 'status'>

    return variablesMap
  }, [enabledColumns])

  return {
    enabledColumns,
    setEnabledColumns,
    includeVariables
  }
}
