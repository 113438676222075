import { type MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { CompanyStatus, CompanyStatusModifiedByKind } from '@strise/types'

export const companyStatuses = [
  CompanyStatus.Account,
  CompanyStatus.Prospect,
  CompanyStatus.Following,
  CompanyStatus.InQualification,
  CompanyStatus.Qualified,
  CompanyStatus.Disqualified
]

export const companyStatusModifiedByKindToTitle: {
  [key in CompanyStatusModifiedByKind]?: MessageDescriptor
} = {
  [CompanyStatusModifiedByKind.User]: defineMessage({ message: 'a user' }),
  [CompanyStatusModifiedByKind.ReviewTrigger]: defineMessage({
    message: 'a review trigger'
  }),
  [CompanyStatusModifiedByKind.ConnectedApp]: defineMessage({
    message: 'a connected app'
  })
}
