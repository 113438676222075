import { i18n } from '@lingui/core'
import { SelectAllCheckbox, type SortField, SortableTableHeadCell } from '@strise/app-shared'
import { TableHeadCell, TableRow } from '@strise/ui-components-legacy'
import * as React from 'react'
import {
  type PortfolioField,
  type PortfolioTableColumn,
  portfolioColumnTitles
} from '~/views/Portfolio/utils/portfolioTableColumns'

export const PortfolioTableHead: React.FC<{
  columns: PortfolioTableColumn[]
  hide: boolean
  selectAll: () => void
  sortFields: Array<SortField<PortfolioField>>
  updateSortField: (field: PortfolioField) => () => void
}> = ({ columns, hide, selectAll, sortFields, updateSortField }) => {
  return (
    <TableRow visibility={hide ? 'hidden' : 'inherit'}>
      <TableHeadCell width={56} size='large'>
        <SelectAllCheckbox
          id='portfolio-select-all'
          palette='tertiary'
          onCheckedChange={selectAll}
          selectedCount={0}
          totalCount={0}
          data-track='Portfolio / Select all checkbox'
        />
      </TableHeadCell>

      {columns.map((column) => {
        const columnWithTitle = { ...column, title: i18n._(portfolioColumnTitles[column.field]) }
        return (
          <SortableTableHeadCell
            key={column.field}
            handleSort={updateSortField}
            size='large'
            column={columnWithTitle}
            data-id={column.dataId}
            sort={
              sortFields[sortFields.length - 1]?.field === column.field ? sortFields[sortFields.length - 1] : undefined
            }
          />
        )
      })}
    </TableRow>
  )
}
