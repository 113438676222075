import { type Messages, i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { getBrowserGlobals } from '@strise/react-utils'
import * as React from 'react'
import { languageToISO, useDisplayLanguage } from '~/contexts/DisplayLanguageContext/displayLanguageContextUtils'
import { messages as da } from '~/locales/da/messages'
import { messages as en } from '~/locales/en/messages'
import { messages as no } from '~/locales/no/messages'
import { messages as sv } from '~/locales/sv/messages'

const displayLanguageMap: Record<string, Messages> = {
  en,
  sv,
  no,
  da
}

export const DisplayLanguageContextProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const displayLanguage = useDisplayLanguage()

  React.useEffect(() => {
    const iso = languageToISO[displayLanguage]
    if (!iso) return

    const loadedMessages = displayLanguageMap[iso]
    if (loadedMessages) {
      i18n.loadAndActivate({ locale: iso, messages: loadedMessages })
    }

    // Set the lang attribute on the html element to the selected language
    const browserGlobals = getBrowserGlobals()
    if (browserGlobals?.document.documentElement.lang) {
      browserGlobals.document.documentElement.lang = iso
    }
  }, [displayLanguage])

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
