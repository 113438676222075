import { getBrowserGlobals } from './browserGlobals'
import domToImage, { type Options } from 'dom-to-image'

const logError = (context: { id: string; name: string }, reason: string): void =>
  console.error(`Failed to transform element to image\nContext: ${JSON.stringify(context)}\nMessage: ${reason}`)

export const getElementAsEncodedBase64 = async (
  element: HTMLElement | null,
  context: { id: string; name: string },
  options?: Options
): Promise<string | null> => {
  if (!element) {
    logError(context, 'Empty element')
    return null
  }

  const blob = await domToImage.toBlob(element, options).catch((err: unknown) => {
    logError(context, `toBlob: ${JSON.stringify(err)}`)
    return null
  })

  if (!blob) {
    logError(context, 'Empty blob')
    return null
  }

  const encodedBase64String = await blobToBase64(blob).catch((err: unknown) => {
    logError(context, `blobToBase64: ${JSON.stringify(err)}`)
    return null
  })

  if (!encodedBase64String) {
    logError(context, 'Empty base64 string')
    return null
  }

  // Extremely large images creates some error in API
  if (encodedBase64String.length > 9_900_000) {
    logError(context, `Base64 string too large (${encodedBase64String.length})`)
    return null
  }

  return encodedBase64String
}

const blobToBase64 = async (blob: Blob): Promise<string> => {
  const arrayBuffer = await blob.arrayBuffer().catch((err: unknown) => {
    throw new Error(`Failed to convert blob to array buffer: ${JSON.stringify(err)}`)
  })
  const byteArray = new Uint8Array(arrayBuffer)

  if (!byteArray.length) {
    throw new Error('Empty byte array')
  }

  const byteArrayString = byteArray.reduce((acc, i) => acc + String.fromCharCode.apply(null, [i]), '')
  const encodedBase64String = getBrowserGlobals()?.window.btoa(byteArrayString)

  if (!encodedBase64String) {
    throw new Error('Empty base64 string')
  }

  return encodedBase64String
}

export const saveElementAsPng = async (
  element: HTMLElement | null,
  fileName: string,
  options?: { background?: string } & Options
): Promise<void> => {
  const browserGlobals = getBrowserGlobals()

  if (!element || !browserGlobals) return

  if (options?.background) {
    element.style.background = options.background
  }

  const dataUrl = await domToImage.toPng(element, options ?? {})
  const linkElement = browserGlobals.document.createElement('a')
  linkElement.download = fileName
  linkElement.href = dataUrl
  linkElement.click()
}
