import { t } from '@lingui/macro'
import { IdNameChip } from '@strise/app-shared'
import { setChildState, useContext } from '@strise/react-utils'
import { ApplicationSearchReturnType } from '@strise/types'
import * as React from 'react'
import { EntitySearchInputMultiple } from '~/components/Entity/EntitySearchInput'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'
import { GrowSettingsSections } from '~/views/Grow/components/GrowSettingsPanel'

export const GrowRealEstateCollateralCreditorFilter = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const realEstateCollateralCreditorsState = settings.grow.realEstateCollateralCreditors
  const saveRealEstateCollateralCreditorsSettings = setChildState(saveSettings, 'grow.realEstateCollateralCreditors')

  const handleDeleteCreditor = (creditor: BaseEntityLikeFragment): void => {
    saveRealEstateCollateralCreditorsSettings(
      realEstateCollateralCreditorsState.filter((prevCreditor) => prevCreditor.id !== creditor.id)
    )
  }

  return (
    <GrowSettingsSections title={t`Real estate collateral creditor`}>
      <div className='flex flex-wrap gap-1'>
        {realEstateCollateralCreditorsState.map((entity) => (
          <IdNameChip
            variant='outlined'
            palette='secondary'
            key={entity.id}
            value={entity}
            onDelete={() => handleDeleteCreditor(entity)}
          />
        ))}
      </div>
      <EntitySearchInputMultiple
        inlineSearch={false}
        selectedEntities={realEstateCollateralCreditorsState}
        onChange={saveRealEstateCollateralCreditorsSettings}
        entityKindFilter={ApplicationSearchReturnType.Company}
        dataTrack='Opportunity Settings / Real estate collateral creditor select'
        clearSearchOnSelect={false}
      />
    </GrowSettingsSections>
  )
}
