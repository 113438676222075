import { useCurrentUser } from '@strise/app-shared'
import { Duration } from '@strise/types'
import { LoaderRound } from '@strise/ui-components'
import * as React from 'react'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { usePortfolioHealthQuery } from '~/graphqlOperations'
import { PortfolioHealthChat } from '~/views/Home/PortfolioHealth/PortfolioHealthChat'
import { PortfolioHealthHeaderCard } from '~/views/Home/PortfolioHealth/PortfolioHealthHeaderCard'
import { PortfolioHealthReviewMetricsSection } from '~/views/Home/PortfolioHealth/PortfolioHealthReviewMetricsSection'
import { PortfolioHealthRiskAssessmentSection } from '~/views/Home/PortfolioHealth/PortfolioHealthRiskAssessmentSection'
import { PortfolioHealthRiskFactorsSection } from '~/views/Home/PortfolioHealth/PortfolioHealthRiskFactorsSection'

export const PortfolioHealthView = () => {
  const user = useCurrentUser()
  const team = useTeam()
  const [duration, setDuration] = React.useState<Duration>(Duration.Day)

  const { data, loading } = usePortfolioHealthQuery({
    variables: {
      team: team.id,
      duration
    },
    fetchPolicy: 'cache-and-network'
  })

  return (
    <div className='relative legacy-xs:min-w-0 legacy-md:min-w-0 legacy-lg:min-w-[1000px]'>
      {loading && (
        <div className='flex justify-center items-center w-full m-auto'>
          <LoaderRound palette='primary' size='lg' />
        </div>
      )}
      {!loading && data?.portfolioHealth && (
        <div className='max-w-7xl mx-auto'>
          <PortfolioHealthHeaderCard
            teamName={team.name}
            teamId={team.id}
            userName={user.name}
            duration={duration}
            onDurationChange={setDuration}
          />

          <div className='px-4'>
            <PortfolioHealthReviewMetricsSection
              duration={duration}
              reviewMetrics={data.portfolioHealth.reviewMetrics}
            />
            <div className='grid grid-cols-4 gap-6'>
              <div className='col-span-4 lg:col-span-3'>
                <PortfolioHealthRiskFactorsSection riskFactors={data.portfolioHealth.riskFactors} />
              </div>
              <div className='col-span-4 lg:col-span-1 '>
                <PortfolioHealthRiskAssessmentSection riskDistribution={data.portfolioHealth.riskDistribution} />
              </div>
            </div>
          </div>

          <PortfolioHealthChat />
        </div>
      )}
    </div>
  )
}
