import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { type LinkProps, ReactRouterLink, UserAvatar, useCurrentUser, useLogout } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import {
  Button,
  type ButtonProps,
  IconChevronDownSmall,
  IconChevronRightSmall,
  type IconComponent,
  IconEmployees,
  IconGrow,
  IconHelp,
  IconHome,
  IconInvite,
  IconOnboarding,
  IconPortfolio,
  IconReview,
  IconSettings,
  IconSparkline,
  IconStriseDesign,
  LinkButton,
  Typography,
  VisuallyHidden,
  cn
} from '@strise/ui-components'
import { Dropdown, MenuItem, colors } from '@strise/ui-components-legacy'
import * as React from 'react'
import { ChatBubble } from '~/components/Chat'
import { DevUtilsModal } from '~/components/DevUtilsModal/DevUtilsModal'
import { InviteUsers } from '~/components/InviteUsers'
import { SelectTeam } from '~/components/SelectTeam'
import { QUERY_PARAMS } from '~/constants'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { apiEnvState } from '~/state'
import { contentViewToFeatureMap, contentViewToTitle } from '~/utils/contentViewUtils'
import { showToursArticle } from '~/utils/onboard'
import { TestIDs } from '~/utils/testIDs'
import { ContentViews } from '~/utils/urls'
import { useHelpCenterLink } from '~/utils/userHooks'
import { useActiveContentView } from '~/utils/viewsHooks'
import { SettingsModalContext } from '~/views/Settings/SettingsModalContextProvider'

const NavButton = ({
  children,
  className,
  dataTestId,
  dataTrack,
  to,
  ...props
}: { dataTestId?: string; dataTrack: string } & ButtonProps & Pick<LinkProps, 'to'>): React.ReactNode => {
  return (
    <Button asChild className={cn('px-5', className)} data-track={dataTrack} data-id={dataTestId} {...props}>
      <ReactRouterLink
        to={to}
        queryParamsToPreserve={[
          QUERY_PARAMS.team,
          QUERY_PARAMS.api,
          QUERY_PARAMS.sidepanelEntityId,
          QUERY_PARAMS.sidepanelTab
        ]}
        className='hover:no-underline'
      >
        {children}
      </ReactRouterLink>
    </Button>
  )
}

type MenuTabProps = {
  Icon: IconComponent
  collapsed: boolean
  dataTestId?: string
  dataTrack: string
  path: ContentViews
} & ButtonProps

const MenuTab: React.FC<MenuTabProps> = ({ Icon, children, className, collapsed, path, ...props }) => {
  const currentPath = useActiveContentView()

  const isActive = currentPath === path

  return (
    <NavButton
      variant='contained'
      palette='tertiary'
      className={cn(className, 'h-header w-full whitespace-nowrap')}
      style={{
        color: isActive ? colors.blue[50] : undefined,
        background: isActive ? colors.gray[5] : undefined
      }}
      to={`/${path}`}
      {...props}
    >
      <div className='flex w-full items-center'>
        <Icon className='shrink-0' />
        {collapsed && <VisuallyHidden>{children}</VisuallyHidden>}
        {!collapsed && (
          <Typography className='ml-2 font-regular' component='span' variant='subtitle1'>
            {children}
          </Typography>
        )}
      </div>
    </NavButton>
  )
}

const menuPaths = [
  {
    path: ContentViews.Home,
    Icon: IconHome
  },
  {
    path: ContentViews.Activity,
    Icon: IconSparkline
  },
  {
    path: ContentViews.Portfolio,
    Icon: IconPortfolio
  },
  {
    path: ContentViews.Review,
    Icon: IconReview
  },
  {
    path: ContentViews.Grow,
    Icon: IconGrow
  }
]

export const MenuNav = ({
  expanded,
  navOpen,
  setNavOpen
}: {
  expanded: boolean
  navOpen: boolean
  setNavOpen: (open: boolean) => void
}): React.ReactNode => {
  const apiEnv = apiEnvState().get()

  const features = useCurrentUserFeatures()
  const user = useCurrentUser()
  const helpCenterLink = useHelpCenterLink()
  const team = useTeam()
  const logout = useLogout()

  const { setIsSettingsOpen } = useContext(SettingsModalContext)

  const [isInviteUserOpen, setIsInviteUserOpen] = React.useState<boolean>(false)
  const [isDevUtilsOpen, setIsDevUtilsOpen] = React.useState(false)

  const openInviteUserModal = (): void => setIsInviteUserOpen(true)

  const enrichedMenuPaths = menuPaths.map((menuPath) => {
    const feature = contentViewToFeatureMap[menuPath.path]
    return {
      ...menuPath,
      label: i18n._(contentViewToTitle[menuPath.path]),
      hide: feature ? !features[feature] : false
    }
  })

  const selectClassOptionName = 'border-t border-secondary-shade-80'

  const openSelectOptions = [
    {
      text: <SelectTeam onSelect={() => setNavOpen(false)} />,
      startIcon: <IconEmployees size='sm' />,
      className: selectClassOptionName
    },
    {
      text: (
        <div>
          <Typography>
            <Trans>Settings</Trans>
          </Typography>
        </div>
      ),
      startIcon: <IconSettings size='sm' />,
      onClick: () => {
        setNavOpen(false)
        setIsSettingsOpen(true)
      },
      className: selectClassOptionName,
      'data-id': TestIDs.Navigation.DropdownMenu.settings
    },
    {
      text: (
        <div>
          <Typography>
            <Trans>Tour</Trans>
          </Typography>
        </div>
      ),
      startIcon: <IconOnboarding size='sm' />,
      onClick: showToursArticle,
      dataTrack: 'Menu / Tour',
      dataId: 'Menu / Tour',
      className: selectClassOptionName
    },
    {
      text: (
        <LinkButton
          href={helpCenterLink}
          rel='noopener noreferrer'
          target='_blank'
          className='pl-0 text-white hover:bg-secondary-shade-80'
        >
          <Typography>
            <Trans>Help center</Trans>
          </Typography>
        </LinkButton>
      ),
      dataTrack: 'Menu / Help',
      startIcon: <IconHelp size='sm' />,
      className: selectClassOptionName
    },
    {
      text: (
        <div>
          <Typography>
            <Trans>Invite users</Trans>
          </Typography>
        </div>
      ),
      startIcon: <IconInvite size='sm' />,
      onClick: openInviteUserModal,
      dataTrack: 'Menu / Invite',
      className: 'border-t border-secondary-shade-80'
    },
    {
      text: (
        <div className='ml-2'>
          <Typography className='text-semantic-danger-shade-20'>
            <Trans>Log out</Trans>
          </Typography>
        </div>
      ),
      onClick: async () => await logout(),
      className: selectClassOptionName
    },
    ...(apiEnv === 'dev' || apiEnv === 'local'
      ? [{ text: 'Dev superpowers', startIcon: <IconStriseDesign />, onClick: () => setIsDevUtilsOpen(true) }]
      : [])
  ]

  return (
    <nav className='group/nav flex h-full flex-col' data-id='nav-bar-wrapper'>
      <Dropdown
        className={cn('h-[64px] w-full whitespace-nowrap', navOpen && 'w-[240px]')}
        paperProps={{ className: 'shadow-none' }}
        popperProps={{ className: 'w-full' }}
        onOutsideClick={() => {
          setNavOpen(false)
        }}
        onToggle={(open) => {
          setNavOpen(open)
        }}
        ToggleComponent={
          <Button
            className={cn(
              'flex size-full items-center justify-start gap-2 overflow-x-hidden py-2 pl-2 pr-0 text-start duration-0',
              navOpen && 'bg-secondary-main text-white hover:bg-secondary-main'
            )}
            variant='contained'
            data-track='Open MenuNav Dropdown'
            data-id={TestIDs.Navigation.DropdownMenu.trigger}
            aria-label={t`open menu`}
          >
            <UserAvatar user={user} className='size-[44px]' alt={t`user avatar`} />
            <div
              className={cn(
                'flex-1 opacity-0 delay-100 group-hover/nav:opacity-100 group-hover/nav:transition-opacity',
                (navOpen || expanded) && 'opacity-100'
              )}
            >
              {(expanded || navOpen) && (
                <div className='flex items-center justify-between pr-6'>
                  <div className='grid items-center'>
                    <Typography variant='aLabelSmallBold'>{user.name}</Typography>
                    <Typography variant='aLabelSmall'>{team.name}</Typography>
                  </div>
                  {navOpen ? <IconChevronDownSmall /> : <IconChevronRightSmall />}
                </div>
              )}
            </div>
          </Button>
        }
      >
        {({ toggle }) =>
          openSelectOptions.map(({ className, onClick, text, ...props }, index) => (
            <MenuItem
              key={index}
              className={cn('w-full bg-secondary-main text-white', className)}
              onClick={() => {
                onClick?.()
                toggle()
              }}
              {...props}
            >
              {text}
            </MenuItem>
          ))
        }
      </Dropdown>

      {apiEnvState().get() !== 'PROD' && (
        <Typography className='bg-accent-pink-main p-2 text-white' component='div' variant='body2'>
          {apiEnvState().get()}
        </Typography>
      )}

      {enrichedMenuPaths.map(({ Icon, hide, label, path, ...props }, index) => {
        return (
          !hide && (
            <MenuTab
              key={index}
              className='overflow-x-hidden'
              Icon={Icon}
              path={path}
              collapsed={!expanded}
              dataTrack={`Menu / ${path}`}
              dataTestId={`nav-bar-menu-button-${path}`}
              {...props}
            >
              {label}
            </MenuTab>
          )
        )
      })}

      <div className='absolute bottom-[60px] w-full'>
        <ChatBubble expanded={expanded} />
      </div>

      {isInviteUserOpen && <InviteUsers team={team} setOpen={setIsInviteUserOpen} />}
      <DevUtilsModal open={isDevUtilsOpen} setOpen={setIsDevUtilsOpen} />
    </nav>
  )
}
