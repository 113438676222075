import { type SimpleUserFragment } from '../../graphqlTypes'
import { UserAvatar } from '../UserAvatar'
import { useSortedAssignees } from './assigneeHooks'
import { type DivProps } from '@strise/react-utils'
import { Tooltip, Typography, type TypographyProps, cn } from '@strise/ui-components'
import * as React from 'react'

const MAX_RENDERED_ASSIGNEES = 6

const AssignedUser = ({
  avatarClassName,
  className,
  showName,
  user,
  variant = 'aLabel',
  ...props
}: {
  avatarClassName?: string
  className?: string
  showName?: boolean
  user: SimpleUserFragment
  variant?: TypographyProps['variant']
} & DivProps): React.ReactNode => {
  const avatar = <UserAvatar user={user} className={cn('size-8', avatarClassName)} />

  return (
    <div className={cn('flex items-center', className)} {...props}>
      {showName ? avatar : <Tooltip content={user.name}>{avatar}</Tooltip>}

      {showName && (
        <Typography className={cn('ml-2 truncate')} component='div' variant={variant}>
          {user.name}
        </Typography>
      )}
    </div>
  )
}

export const Assignees: React.FC<
  {
    assignees: Array<{ node: SimpleUserFragment }>
    showName?: boolean
  } & DivProps
> = ({ assignees, className, showName = true, ...props }) => {
  const sorted = useSortedAssignees(assignees)

  const firstAssignee = assignees[0]?.node

  return (
    <div className={cn('flex size-full items-center', className)} {...props}>
      <div className='flex items-center'>
        {assignees.length > 1 &&
          sorted.slice(0, MAX_RENDERED_ASSIGNEES).map(({ node }, index) => {
            return (
              <AssignedUser
                className={cn('size-8', { '-ml-1': index > 0 })}
                key={node.id}
                user={node}
                style={{ zIndex: assignees.length - index }}
              />
            )
          })}
        {assignees.length > MAX_RENDERED_ASSIGNEES && (
          <Tooltip
            content={
              <div className='space-y-2 p-1'>
                {sorted.slice(MAX_RENDERED_ASSIGNEES).map(({ node }) => {
                  return <AssignedUser key={node.id} user={node} variant='aLabelSmall' showName />
                })}
              </div>
            }
          >
            <Typography className='ml-2'>+{assignees.length - MAX_RENDERED_ASSIGNEES}</Typography>
          </Tooltip>
        )}
      </div>
      {assignees.length === 1 && firstAssignee && <AssignedUser showName={showName} user={firstAssignee} />}
    </div>
  )
}
