import { t } from '@lingui/macro'
import { Typography, type TypographyProps, cn } from '@strise/ui-components'
import * as React from 'react'

export const CompanyStatusSelectPaperHeaderText: React.FC<{ multiple?: boolean } & TypographyProps> = ({
  className,
  multiple = false,
  ...props
}) => {
  const title = multiple ? t`Select one or multiple alternatives` : t`Select one alternative`

  return (
    <Typography className={cn(className, 'p-4')} component='div' variant='aLabelSmall' {...props}>
      {title}
    </Typography>
  )
}
