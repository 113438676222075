import { t } from '@lingui/macro'
import { useIsSupervisor } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { omniEntityUrl } from '@strise/ts-utils'
import { TableRowKind } from '@strise/types'
import { Button, Typography } from '@strise/ui-components'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { SidepanelTransformedTableRow } from '~/components/Sidepanel/SidepanelTransformedTableRow'
import { type SidepanelCompanyFragment } from '~/graphqlTypes'
import { type TransformedApiTableRow } from '~/utils/apiTable/apiTableUtils'
import { useTransformedTable } from '~/utils/apiTable/apiTableValueBaseUtils'

const CorporatePurpose: React.FC<
  {
    company: SidepanelCompanyFragment
  } & DivProps
> = ({ className, company, ...props }) => {
  const [showAll, toggleShowAll] = useToggle(false)

  const purpose = company.corporatePurpose

  if (!purpose) return null

  const truncatedPurpose = purpose.substring(0, 450)

  return (
    <div className={className} {...props}>
      <Typography className='py-4' variant='subtitle1'>{t`Corporate purpose`}</Typography>
      <Typography variant='body1'>{showAll ? purpose : truncatedPurpose}</Typography>
      {truncatedPurpose !== purpose && (
        <Button
          className='px-0'
          size='sm'
          variant='ghost'
          color='primary'
          onClick={toggleShowAll}
          data-track='Corporate purpose / Toggle'
        >
          {showAll ? t`Show less` : t`Show more`}
        </Button>
      )}
    </div>
  )
}

export const SidepanelCompanyDetailsCard = React.memo(({ company }: { company: SidepanelCompanyFragment }) => {
  const isSupervisor = useIsSupervisor()
  const transformedTable = useTransformedTable(company, company.companyInformation)

  const customRows: TransformedApiTableRow[] = [
    {
      label: 'Omni',
      key: 'omni',
      kind: TableRowKind.Website,
      hide: !isSupervisor,
      paginationThreshold: null,
      sections: [
        {
          label: null,
          paginationThreshold: null,
          items: [
            {
              content: 'Omni link',
              href: omniEntityUrl(company.id)
            }
          ]
        }
      ]
    }
  ]

  const rows = [...(transformedTable?.rows ?? []), ...customRows]

  return (
    <SidepanelCard title={transformedTable?.title} loading={!company}>
      <div className='px-4'>
        {rows.map((row, index) => {
          const isLast = index === rows.length - 1
          return <SidepanelTransformedTableRow key={index} row={row} hideDivider={isLast} context='Company / Details' />
        })}
        <CorporatePurpose company={company} />
      </div>
      {transformedTable && <DataSources dataSources={transformedTable.dataSources} />}
    </SidepanelCard>
  )
})
