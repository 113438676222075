import { Trans, t } from '@lingui/macro'
import { Empty, formatDate } from '@strise/app-shared'
import { formatNumber } from '@strise/ts-utils'
import { Typography } from '@strise/ui-components'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import * as React from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { type CollateralFragment, type SidepanelCompanyFragment } from '~/graphqlTypes'

const CollateralRow: React.FC<{
  collateral: CollateralFragment
}> = ({ collateral }) => {
  const amount = [formatNumber(collateral.amount), collateral.currency].join(' ')

  return (
    <TableRow style={{ verticalAlign: 'top' }}>
      <TableCell size='large' pl={0}>
        <Typography variant='aLabel'>
          <div className='text-text-secondary'>{collateral.description}</div>
          {collateral.creditor ? (
            <EntityLink entity={collateral.creditor} className='font-regular' />
          ) : (
            <div>{collateral.creditorName || '-'}</div>
          )}
        </Typography>
      </TableCell>
      <TableCell size='large' textAlign='right' numeric>
        {amount}
      </TableCell>
      <TableCell size='large' pr={0} color='text.secondary' textAlign='right'>
        {formatDate(collateral.date, { relative: false })}
      </TableCell>
    </TableRow>
  )
}

export const SidepanelCompanyCollateralCard = ({ company }: { company: SidepanelCompanyFragment }): React.ReactNode => {
  if (!company.collaterals) return null

  const collaterals = company.collaterals.edges.map((edge) => edge.node)

  return (
    <SidepanelCard title={t`Collateral`}>
      <div className='flex flex-col px-4'>
        {!collaterals.length && (
          <Empty title={t`No collateral found`}>
            <Trans>We couldn't find any collateral on this company</Trans>
          </Empty>
        )}

        {!!collaterals.length && (
          <Table>
            <tbody>
              {collaterals.map((collateral, index) => {
                return <CollateralRow key={index} collateral={collateral} />
              })}
            </tbody>
          </Table>
        )}
      </div>
      <DataSources dataSources={company.dataSources.collateral} />
    </SidepanelCard>
  )
}
