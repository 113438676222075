import { IconLock } from '@strise/ui-components'
import * as React from 'react'
import { Detail } from '~/components/Sidepanel/Detail'
import { TransformedTableRow } from '~/components/TransformedTable/TransformedTableRow'
import { type TransformedApiTableRow } from '~/utils/apiTable/apiTableUtils'

export const SidepanelTransformedTableRow = ({
  context,
  hideDivider,
  row
}: {
  context: string
  hideDivider: boolean
  row: TransformedApiTableRow
}): React.ReactNode => {
  if (row.hide) return null

  const label = row.disabled ? (
    <div className='flex items-center'>
      {row.label} <IconLock className='ml-1 text-secondary-shade-50' size='md' />
    </div>
  ) : (
    row.label
  )

  return (
    <Detail label={label} showEmpty={row.showEmpty} hideDivider={hideDivider}>
      <TransformedTableRow className='whitespace-normal' row={row} context={context} />
    </Detail>
  )
}
