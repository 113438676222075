import { type BaseEntityLikeFragment } from '@strise/app-shared/src/graphqlTypes'
import { useContext } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { IconConnect, Typography, cn } from '@strise/ui-components'
import React from 'react'
import { EntityMetaItems } from '~/components/EntityMeta/EntityMetaItems'
import { LastVisited } from '~/components/LastVisited'
import { RecentlyVisitedEntitiesContext } from '~/contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContext'

const NAME_MAX_LENGTH = 50

const SuggestedMergeEntity = ({
  dataTrackId,
  entity,
  isLast,
  onClick
}: {
  dataTrackId?: string
  entity: BaseEntityLikeFragment
  isLast: boolean
  onClick: () => void
}): React.ReactNode => {
  const { recentlyVisitedEntitiesMap } = useContext(RecentlyVisitedEntitiesContext)
  const visitedAt = recentlyVisitedEntitiesMap[entity.id]?.visitedAt ?? 0

  const trimmedName = ellipsis(entity.name ?? '', NAME_MAX_LENGTH)
  return (
    <div className={cn('flex items-center justify-between border-b border-tertiary-main p-1', isLast && 'border-none')}>
      <div className='flex flex-col items-start gap-2'>
        <div className='flex cursor-pointer items-center gap-2 text-text-link hover:underline'>
          <Typography
            variant='body1'
            onClick={onClick}
            data-track={`Merge Entity / Suggestions / Select Entity ${dataTrackId}`}
          >
            {trimmedName}
            <IconConnect size='md' className='mb-1 ml-2' />
          </Typography>
        </div>
        <EntityMetaItems entity={entity} />
      </div>
      <LastVisited lastVisited={visitedAt} />
    </div>
  )
}

export default SuggestedMergeEntity
