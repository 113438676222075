import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { Img, ReactRouterLink, formatDate, toast } from '@strise/app-shared'
import { undoToast } from '@strise/app-shared/src/utils/toasts'
import { getBrowserGlobals } from '@strise/react-utils'
import { type DivProps } from '@strise/react-utils'
import { NotificationCategory, NotificationKind, NotificationSettingStatus, NotificationStatus } from '@strise/types'
import {
  Button,
  Chip,
  IconBell,
  IconCheck,
  IconCheckSmall,
  IconCrossSmall,
  IconDotSmall,
  IconNotificationAll,
  IconNotificationLess,
  Link,
  LoaderRound,
  Typography,
  cn
} from '@strise/ui-components'
import { type DropdownToggleFn } from '@strise/ui-components-legacy'
import qs from 'qs'
import * as React from 'react'
import { DropdownMenu } from '~/components/DropdownMenu'
import { InviteUsers } from '~/components/InviteUsers'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import {
  useCompanyNotificationSettingQuery,
  useUpdateCompanyNotificationSettingsMutation,
  useUpdateNotificationMutation
} from '~/graphqlOperations'
import { type NotificationFragment } from '~/graphqlTypes'
import { useTriggerConflictModal } from '~/utils/hooks'
import { ContentViews } from '~/utils/urls'
import { useActiveContentView } from '~/utils/viewsHooks'

const getButtonThemeClass = (
  unread: boolean,
  theme: 'light' | 'dark'
): 'text-text-primary' | 'text-text-primaryContrast' | 'text-text-secondary' => {
  if (theme === 'light') {
    return 'text-text-primary'
  }
  return unread ? 'text-text-primaryContrast' : 'text-text-secondary'
}

const NotificationActions: React.FC<{
  notification: NotificationFragment
  offset: number
  size: number
  theme: 'light' | 'dark'
}> = ({ notification, offset, size, theme }) => {
  // Used to trigger undo toasts. Doing it like this so we can set functions in the required order
  const [settingsUpdatedNonce, setSettingsUpdatedNonce] = React.useState(0)
  const [notificationUpdatedNonce, setNotificationUpdatedNonce] = React.useState(0)

  const { data: companyNotificationSettingData } = useCompanyNotificationSettingQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
      id: notification.entity?.id!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
      team: notification.team?.id!
    },
    skip: !notification.entity?.id || !notification.team
  })
  const companyNotificationSettingStatus =
    companyNotificationSettingData?.company.notificationSetting?.status ?? NotificationSettingStatus.Enabled

  const unread = notification.status === NotificationStatus.Unread

  const buttonThemeClass = getButtonThemeClass(unread, theme)

  const handleNotificationCompleted = (): void => setNotificationUpdatedNonce((prevState) => prevState + 1)
  const [updateNotification, { data: updateNotificationsData, loading: notificationLoading }] =
    useUpdateNotificationMutation({
      onCompleted: handleNotificationCompleted
    })

  const handleSettingsCompleted = (): void => setSettingsUpdatedNonce((prevState) => prevState + 1)
  const [updateSettings, { data: updateSettingsData, loading: settingsLoading }] =
    useUpdateCompanyNotificationSettingsMutation({
      onCompleted: handleSettingsCompleted
    })

  const loading = notificationLoading || settingsLoading

  const handleMarkAsRead = async (event: React.MouseEvent): Promise<void> => {
    event.stopPropagation()
    await updateNotification({
      variables: {
        notification: notification.id,
        status: NotificationStatus.Read
      }
    })
  }
  const handleShowLess = async (): Promise<void> => {
    await updateNotification({
      variables: {
        notification: notification.id,
        status: NotificationStatus.Ignored
      }
    })
  }

  const handleTurnOffSettings = async (): Promise<void> => {
    if (!notification.entity) return

    await updateSettings({
      variables: {
        entity: notification.entity.id,
        status: NotificationSettingStatus.Disabled,
        size,
        offset,
        team: notification.team?.id
      }
    })
  }

  const handleTurnOnSettings = async (event: React.MouseEvent): Promise<void> => {
    event.stopPropagation()
    if (!notification.entity) return

    await updateSettings({
      variables: {
        entity: notification.entity.id,
        status: NotificationSettingStatus.Enabled,
        size,
        offset,
        team: notification.team?.id
      }
    })
  }

  React.useEffect(() => {
    if (!notificationUpdatedNonce) return

    if (updateNotificationsData?.updateNotifications.notification.status === NotificationStatus.Ignored) {
      undoToast({
        label: t`Notification updated`,
        body: <>{t`This feedback will help us provide you with even more relevant notifications.`}</>,
        onUndo: handleMarkAsRead,
        i18n
      })
    } else {
      toast.success(t`Notification updated`)
    }
  }, [notificationUpdatedNonce])

  React.useEffect(() => {
    if (!settingsUpdatedNonce) return

    if (
      updateSettingsData?.updateEntityNotificationSettings.company.notificationSetting?.status ===
      NotificationSettingStatus.Disabled
    ) {
      undoToast({
        label: t`Notification turned off for ${notification.entity?.name ?? ''}`,
        body: <>{t`This feedback will help us provide you with even more relevant notifications.`}</>,
        onUndo: handleTurnOnSettings,
        i18n
      })
    } else {
      toast.success(t`Notification settings updated`)
    }
  }, [settingsUpdatedNonce])

  const menuItems = [
    {
      startIcon: <IconCheck />,
      title: t`Mark as read`,
      onClick: handleMarkAsRead,
      hide: !unread
    },
    {
      startIcon: <IconBell />,
      title: t`Show less of this`,
      onClick: handleShowLess,
      hide: notification.status === NotificationStatus.Ignored
    },
    {
      startIcon: <IconNotificationAll />,
      title: t`Show all`,
      onClick: handleMarkAsRead,
      hide: notification.status !== NotificationStatus.Ignored
    },
    {
      startIcon: <IconNotificationLess />,
      title: notification.entity ? t`Turn off for ${notification.entity.name}` : t`Turn off`,
      onClick: handleTurnOffSettings,
      hide: !notification.entity || companyNotificationSettingStatus === NotificationSettingStatus.Disabled
    },
    {
      startIcon: <IconNotificationAll />,
      title: notification.entity ? t`Turn on for ${notification.entity.name}` : t`Turn on`,
      onClick: handleTurnOnSettings,
      hide: !notification.entity || companyNotificationSettingStatus === NotificationSettingStatus.Enabled
    }
  ]

  return (
    <DropdownMenu
      menuItems={menuItems}
      buttonProps={{
        className: cn('rounded-full', buttonThemeClass),
        loading,
        variant: 'ghost',
        palette: 'secondary'
      }}
      dataTrack='Notifications / Toggle notification dropdown'
    />
  )
}

const getUrl = (notification: NotificationFragment): URL | null => {
  if (!notification.url) return null

  try {
    return new URL(notification.url)
  } catch {
    return null
  }
}

type NotificationThemeClasses = {
  bgClass: string
  borderClass: string
  dateColorClass: string
  textColorClass: string
}

const getThemeClasses = (unread: boolean, theme: 'light' | 'dark'): NotificationThemeClasses => {
  if (theme === 'light') {
    return {
      bgClass: unread ? 'bg-primary-shade-5' : 'bg-white',
      dateColorClass: unread ? 'text-accent-blue-shade-50' : 'text-secondary-shade-60',
      textColorClass: unread ? 'text-text-primary' : 'text-secondary-shade-60',
      borderClass: 'border-b border-secondary-shade-5'
    }
  }
  return {
    bgClass: unread ? 'bg-secondary-shade-80' : 'bg-secondary-shade-90',
    dateColorClass: unread ? 'text-accent-blue-shade-20' : 'text-text-secondary',
    textColorClass: unread ? 'text-text-primaryContrast' : 'text-text-secondary',
    borderClass: 'border-b border-secondary-shade-80'
  }
}

interface NotificationProps extends DivProps {
  notification: NotificationFragment
  offset: number
  refetchNotifications: () => void
  size: number
  theme: 'light' | 'dark'
  toggle?: DropdownToggleFn
}

export const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(
  ({ notification, offset, refetchNotifications, size, theme, toggle }, ref): React.ReactNode => {
    const { id: teamId } = useTeam()
    const triggerConflictModal = useTriggerConflictModal()

    const [hovered, setHovered] = React.useState(false)
    const [inviteUserDialogOpen, setInviteUserDialogOpen] = React.useState(false)
    const unread = notification.status === NotificationStatus.Unread
    const { bgClass, borderClass, dateColorClass, textColorClass } = getThemeClasses(unread, theme)

    const activeContentView = useActiveContentView()
    const homeView = ContentViews.Home

    const handleNotificationCompleted = (): void => {
      toast.success(t`Notification updated`)
    }
    const [updateNotification, { loading: mutationLoading }] = useUpdateNotificationMutation({
      onCompleted: handleNotificationCompleted
    })

    const handleMouseOver = (): void => setHovered(true)
    const handleMouseLeave = (): void => setHovered(false)
    const handleFocus = (): void => setHovered(true)
    const handleBlur = (): void => setHovered(false)

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
      // Close dropdown/mark as read on click
      // Disabled event.preventDefault() to make url work
      toggle?.(event, false)
    }

    const handleApprove = async (): Promise<void> => {
      switch (notification.kind) {
        case NotificationKind.UserRequested: {
          setInviteUserDialogOpen(true)
          break
        }
        default: {
          await updateNotification({
            variables: {
              notification: notification.id,
              status: NotificationStatus.Approved
            }
          })
        }
      }
    }

    const handleResolveConflict = (
      event: React.MouseEvent<HTMLElement>,
      entityId: string,
      notificationTeam?: string
    ): void => {
      event.preventDefault()
      // If the team in the notification differes from the current team, we need to trigger a team switch which does a full page reload
      // We can avoid this if the team is the same, and just trigger the modal
      if (teamId !== notificationTeam) {
        triggerConflictModal(entityId, notificationTeam)
      }
      triggerConflictModal(entityId)
    }

    const handleDecline = async (): Promise<void> => {
      await updateNotification({
        variables: {
          notification: notification.id,
          status: NotificationStatus.Disapproved
        }
      })
    }

    const handleInviteUserCompleted = (): void => {
      refetchNotifications()
    }

    const url = getUrl(notification)

    const content = (
      <>
        <div
          className={cn('relative px-4 pb-6 pt-4', borderClass, bgClass)}
          ref={ref}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
          data-track='Notifications / Click notification'
        >
          <div className='flex items-center gap-4'>
            <Img
              src={notification.image}
              className={cn('size-12', { 'rounded-full': !notification.flag })}
              alt={t`notification image`}
            />
            <div className='flex grow flex-col gap-2'>
              <Typography className={textColorClass} variant='aLabelSmall'>
                {notification.team?.name}
              </Typography>
              <Typography variant='aLabelBold' className={textColorClass}>
                {notification.title}
              </Typography>
              <Typography variant='body1' className={cn(textColorClass, hovered && url && 'underline')}>
                {notification.body}
              </Typography>
              {notification.message && (
                // eslint-disable-next-line tailwindcss/enforces-shorthand
                <div className='whitespace-pre-line rounded-b-lg rounded-r-lg bg-secondary-shade-5 p-4 text-text-primary'>
                  <Typography className='hyphens-auto [overflow-wrap:anywhere]'>{notification.message}</Typography>
                </div>
              )}
              {!mutationLoading &&
                notification.category === NotificationCategory.Choice &&
                notification.status !== NotificationStatus.Disapproved &&
                notification.status !== NotificationStatus.Approved && (
                  <div className='flex flex-wrap justify-between gap-2'>
                    {notification.otherUser && (
                      <div className='flex items-center justify-between gap-2'>
                        <Typography className={cn(textColorClass)}>
                          <Trans>Email</Trans>
                        </Typography>
                        <Chip label={notification.otherUser.email} />
                      </div>
                    )}
                    <div className='flex items-center justify-end gap-2'>
                      <Button
                        size='sm'
                        className='ml-auto rounded pl-4 pr-2'
                        variant='contained'
                        palette={theme === 'light' ? 'secondary' : 'tertiary'}
                        onClick={handleDecline}
                        data-track='Notifications / Decline'
                      >
                        <Trans>Decline</Trans>
                        <IconCrossSmall />
                      </Button>
                      <Button
                        size='sm'
                        className='rounded pl-4 pr-2'
                        variant='contained'
                        palette={theme === 'light' ? 'secondary' : 'tertiary'}
                        onClick={handleApprove}
                        data-track='Notifications / Approve'
                      >
                        <Trans>Approve</Trans>
                        <IconCheckSmall />
                      </Button>
                    </div>
                  </div>
                )}
              {!mutationLoading &&
                notification.entity?.id &&
                (notification.kind === NotificationKind.OwnershipConflict ||
                  notification.kind === NotificationKind.RoleConflict) && (
                  <Button
                    size='sm'
                    variant='contained'
                    palette={theme === 'light' ? 'secondary' : 'tertiary'}
                    className='h-7 w-fit'
                    // not entirely sure why this is needed, as we make sure that the entity exist on the conditional level
                    onClick={(event) =>
                      notification.entity && handleResolveConflict(event, notification.entity.id, notification.team?.id)
                    }
                    data-track='Notifications / Resolve Conflict'
                  >
                    <Trans>Resolve Conflict</Trans>
                  </Button>
                )}

              {!mutationLoading && notification.status === NotificationStatus.Disapproved && (
                <Typography className={cn(textColorClass)}>
                  <Trans>Declined</Trans>
                </Typography>
              )}

              {!mutationLoading && notification.status === NotificationStatus.Approved && (
                <Typography className={cn(textColorClass)}>
                  <Trans>Approved</Trans>
                </Typography>
              )}
            </div>
            <div className='min-w-24 shrink-0 self-start text-right'>
              <Typography variant='aLabelSmallBold' className={cn(dateColorClass)}>
                {unread && <IconDotSmall className='text-accent-blue-shade-20' size='md' />}
                {formatDate(notification.created)}
              </Typography>
            </div>
            {/** Place the actions vertically centered at the right side of the notification */}
            <div className='absolute right-4 top-1/2 -translate-y-1/2'>
              <NotificationActions notification={notification} size={size} offset={offset} theme={theme} />
            </div>
          </div>
          {mutationLoading && <LoaderRound size='sm' palette='tertiary' />}
        </div>
        {inviteUserDialogOpen && notification.team && (
          <InviteUsers
            team={notification.team}
            setOpen={setInviteUserDialogOpen}
            emails={notification.otherUser?.email ? [notification.otherUser.email] : []}
            onCompleted={handleInviteUserCompleted}
          />
        )}
      </>
    )

    // Return an unclickable notification if something happens with url
    if (!url) {
      return content
    }

    const pathnameWithContentView =
      activeContentView && Object.values(ContentViews).some((path) => url.pathname.includes(path))
        ? url.pathname
        : `/${activeContentView ?? homeView}${url.pathname}`

    // Do a traditional link on team switching to reload app state
    if (notification.team && teamId !== notification.team.id) {
      const href = (getBrowserGlobals()?.window.location.origin || '') + pathnameWithContentView + url.search

      return (
        <Link href={href} onClick={handleClick} data-notification-id={notification.id} className='no-underline'>
          {content}
        </Link>
      )
    }

    const params = qs.parse(url.search, { ignoreQueryPrefix: true })

    return (
      <ReactRouterLink
        data-notification-id={notification.id}
        className='no-underline'
        onClick={handleClick}
        to={pathnameWithContentView}
        newQueryParams={params}
      >
        {content}
      </ReactRouterLink>
    )
  }
)
