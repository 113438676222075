import { cva } from 'class-variance-authority'

export const lineLoaderVariants = cva('animate-line bg-[size:20%] bg-repeat-y', {
  variants: {
    size: {
      sm: 'h-1.5'
    },
    palette: {
      primary: 'bg-gradient-to-r from-blue-5 to-primary-main',
      secondary: 'bg-gradient-to-r from-gray-70 to-secondary-main'
    }
  },
  defaultVariants: {
    size: 'sm',
    palette: 'secondary'
  }
})
