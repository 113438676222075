import { Trans } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { type StyleProps } from '@strise/react-utils'
import { Typography, cn } from '@strise/ui-components'
import * as React from 'react'

export const LastVisited = ({
  className,
  lastVisited,
  ...props
}: { lastVisited?: number } & StyleProps): React.ReactNode => {
  if (!lastVisited) {
    return null
  }
  return (
    <Typography className={cn('text-text-secondary', className)} variant='aLabelSmall' {...props}>
      <Trans>Last visited:</Trans>
      <span className='block'>{formatDate(new Date(lastVisited))}</span>
    </Typography>
  )
}
