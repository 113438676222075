import { Trans } from '@lingui/macro'
import { IdNameChip } from '@strise/app-shared'
import { setChildState, useContext } from '@strise/react-utils'
import { Combobox, type ComboboxItem, IconSearch, Typography } from '@strise/ui-components'
import * as React from 'react'
import { useTeamTags } from '~/components/Tags/tagUtils'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type CompanyTagFragment } from '~/graphqlTypes'

export const GrowTagsFilter = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const saveTagSettings = setChildState(saveSettings, 'grow.tags')
  const { tags: tagEdges } = useTeamTags()
  const tags = settings.grow.tags

  const options = tagEdges.map((tag) => ({ label: tag.node.name, id: tag.node.id, value: tag.node }))

  const tagValues = tags.map((tag) => ({ label: tag.name, id: tag.id, value: tag }))

  const addHandler = (item: ComboboxItem<CompanyTagFragment>): void => {
    saveTagSettings([...tags, item.value])
  }

  const removeHandler =
    (item: ComboboxItem<CompanyTagFragment>): (() => void) =>
    () => {
      saveTagSettings(tags.filter((tag) => tag.id !== item.id))
    }

  return (
    <div className='grid gap-2'>
      <Typography variant='aLabelSmallBold'>
        <Trans>Tags</Trans>
      </Typography>
      <div className='flex flex-wrap gap-2'>
        {tags.map((tag) => (
          <IdNameChip
            value={tag}
            key={tag.id}
            onDelete={() => removeHandler({ id: tag.id, label: tag.id, value: tag })}
          />
        ))}
      </div>
      <Combobox
        variant='outlined'
        inlineSearch
        closeOnSelect
        startIcon={<IconSearch size='md' className='mr-2 shrink-0' />}
        showItemsAsChips
        hideSelected
        onAdd={addHandler}
        onRemove={removeHandler}
        items={options}
        value={tagValues}
        data-track='Grow Filter / Tags Changed'
      >
        <Trans>Search tags</Trans>
      </Combobox>
    </div>
  )
}
