import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { IdentityVerificationStatus } from '@strise/types'
import { Button } from '@strise/ui-components'
import * as React from 'react'

interface IdvCheckResetButtonProps {
  loadingStatus: IdentityVerificationStatus | null
  onUpdateStatus: (status: IdentityVerificationStatus) => Promise<void>
  setOpen: SetStateFn<boolean>
}

export const IdvCheckResetButton = ({ loadingStatus, onUpdateStatus, setOpen }: IdvCheckResetButtonProps) => {
  const handleClick = async () => {
    await onUpdateStatus(IdentityVerificationStatus.NoStatus)
    setOpen(false)
  }

  return (
    <Button
      data-track='IDV Check / Reset'
      palette='danger'
      variant='ghost'
      disabled={!!loadingStatus}
      loading={loadingStatus === IdentityVerificationStatus.NoStatus}
      onClick={handleClick}
    >
      {t`Reset`}
    </Button>
  )
}
