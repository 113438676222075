import { getBrowserGlobals } from './browserGlobals'
import { useCallback, useLayoutEffect } from 'react'

const INPUT_NODES = new Set(['INPUT', 'TEXTAREA'])
export const useKeyboardShortcut = (
  keyboardKey: string,
  onKeyPress: (e: KeyboardEvent) => void,
  disabled?: boolean
): null => {
  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return
      if (INPUT_NODES.has(getBrowserGlobals()?.document.activeElement?.nodeName ?? '')) return
      if (e.key === keyboardKey) onKeyPress(e)
    },
    [keyboardKey, onKeyPress]
  )

  useLayoutEffect(() => {
    const { window } = getBrowserGlobals() ?? {}

    if (!disabled && window) {
      const { document } = window
      document.addEventListener('keydown', listener)

      return (): void => {
        document.removeEventListener('keydown', listener)
      }
    }
  }, [disabled, listener])

  return null
}
