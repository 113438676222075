import { t } from '@lingui/macro'
import { Button, Tooltip, Typography } from '@strise/ui-components'
import * as React from 'react'
import { MergeEntityDialog } from '~/components/Ownerships/edit-owner/MergeEntityDialog'
import { type EntityLikeMetaFragment } from '~/graphqlTypes'

const ConnectOwnershipButton = ({ entity }: { entity: EntityLikeMetaFragment }) => {
  const [showDialog, setShowDialog] = React.useState(false)

  return (
    <div className='absolute -top-14 left-1/2 -translate-x-1/2'>
      <Tooltip content={t`Missing owners from national registry`}>
        <Button
          size='sm'
          className='border border-blue-10 border-dashed bg-blue-5/30 hover:bg-blue-5 focus:bg-blue-5 active:bg-blue-10 px-2 h-8 group rounded-sm'
          data-track='Ownership / Connect Owner Button / Open Dialog'
          onClick={() => setShowDialog(true)}
        >
          <Typography
            variant='aLabelSmall'
            className='whitespace-nowrap opacity-50 group-hover:opacity-100 transition-opacity'
          >
            {t`Connect owner`}
          </Typography>
        </Button>
      </Tooltip>
      <div className='absolute left-1/2 top-full w-0 h-6 border-l border-dashed border-secondary-shade-10'></div>
      {showDialog && <MergeEntityDialog entity={entity} handleClose={() => setShowDialog(false)} />}
    </div>
  )
}

export default ConnectOwnershipButton
