import * as React from 'react'
import { PepsAndSanctionsCardContent } from '~/components/Sidepanel/SidepanelCards/PepsAndSanctionsCardContent'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { usePersonPepInfoQuery } from '~/graphqlOperations'
import { type SidepanelPersonFragment } from '~/graphqlTypes'
import { useEntitySanctionInfo } from '~/utils/sanctions'

interface SidepanelPersonPepCardProps {
  person: SidepanelPersonFragment
}

export const SidepanelPersonPepsAndSanctionsCard = React.forwardRef<HTMLDivElement, SidepanelPersonPepCardProps>(
  ({ person }, ref) => {
    const features = useCurrentUserFeatures()

    const { data: personPepData, loading: personPepLoading } = usePersonPepInfoQuery({
      variables: {
        entity: person.id,
        includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
        includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING
      },
      fetchPolicy: 'cache-and-network',
      skip: !features.PEP_AND_SANCTIONS_V2
    })

    const { loading: sanctionsLoading, sanctionInfo } = useEntitySanctionInfo(person.id)

    if (!features.PEP_AND_SANCTIONS_V2) return null

    const pepInfos = personPepData?.person.pepInfo ? [personPepData.person.pepInfo] : personPepData?.person.pepInfos

    return (
      <PepsAndSanctionsCardContent
        ref={ref}
        person={person}
        pepInfos={pepInfos}
        pepLoading={personPepLoading}
        sanctionInfo={sanctionInfo}
        sanctionsLoading={sanctionsLoading}
      />
    )
  }
)
