import { RiskClassActionKind, type RiskClassSettingsInput } from '@strise/types'
import { type RiskClassSettingsFragment } from '~/graphqlTypes'

export const isDefaultRiskClassSettingsThatHasNeverBeenChanged = (
  settings?: RiskClassSettingsInput,
  serverSettings?: RiskClassSettingsFragment['riskClassSettings']
): boolean => {
  const neverChangedOnServer = serverSettings?.high?.action == null
  const isDefaultSettings =
    settings?.high?.action === RiskClassActionKind.CompleteReview &&
    settings.medium?.action === RiskClassActionKind.MoveToManualReview &&
    settings.low?.action === RiskClassActionKind.CompleteReview

  return neverChangedOnServer && isDefaultSettings
}
