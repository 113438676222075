import { Trans, t } from '@lingui/macro'
import { toast, useCurrentUser } from '@strise/app-shared'
import { type SetStateFn } from '@strise/react-utils'
import { Button, Tooltip, Typography, cn } from '@strise/ui-components'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import * as React from 'react'
import { SelectCompanyAssignee } from '~/components/Assignee/SelectCompanyAssignee'
import { useAssigneeMutations } from '~/components/Assignee/assigneeHooks'
import { SelectCompaniesStatus } from '~/components/CompanyStatus/SelectCompaniesStatus'
import { companyInPortfolio } from '~/components/CompanyStatus/companyStatusUtils'
import { ContactStrise } from '~/components/ContactStrise'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useUpdateCreditMonitoringMutation } from '~/graphqlOperations'
import { type CompanyMetaFragment } from '~/graphqlTypes'

const CreditMonitoringCompanyStatusModal = ({
  company,
  editableCompanyStatus = true,
  enableCreditMonitoring,
  setModalOpen
}: {
  company: CompanyMetaFragment
  editableCompanyStatus?: boolean
  enableCreditMonitoring: () => void
  setModalOpen: SetStateFn<boolean>
}): React.ReactNode => {
  const inPortfolio = companyInPortfolio(company.statusV2?.status)

  const disabledExplanation = inPortfolio ? '' : t`You must add the company to your portfolio before you can edit this.`
  const handleCancel = (): void => setModalOpen(false)

  const handleConfirm = (): void => {
    enableCreditMonitoring()
    setModalOpen(false)
  }

  return (
    <ConfirmDialog
      isOpen
      onRequestClose={handleCancel}
      confirmText={t`Confirm`}
      cancelText={t`Cancel`}
      title={editableCompanyStatus ? t`Add company to portfolio` : t`Assign company`}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      contentMaxWidth={500}
      confirmButtonProps={{
        disabled: !inPortfolio || !company.assignee.edges.length
      }}
    >
      <Typography className='mb-2'>
        {editableCompanyStatus ? (
          <Trans>The company must be in your portfolio and assigned to be monitored</Trans>
        ) : (
          <Trans>The company must be assigned to be monitored</Trans>
        )}
      </Typography>
      <SelectCompaniesStatus
        className='mb-2 h-12 w-full'
        companies={[company]}
        removable={false}
        disabled={!editableCompanyStatus}
        data-track-context='sidepanel-credit'
      />

      <Tooltip content={disabledExplanation}>
        <SelectCompanyAssignee
          disabled={!inPortfolio}
          assignees={company.assignee.edges}
          companyIds={[company.id]}
          className='h-12'
          indicatorVariant='show'
          data-track-context='sidepanel-credit'
        />
      </Tooltip>
    </ConfirmDialog>
  )
}

export const CreditReportMonitorButton = ({
  className,
  company,
  context,
  hasCreditReport,
  isMonitored,
  ...props
}: {
  company: CompanyMetaFragment
  context: 'Sidepanel' | 'Review'
  hasCreditReport: boolean
  isMonitored: boolean
} & React.HTMLAttributes<HTMLElement>): React.ReactNode => {
  const currentUser = useCurrentUser()
  const { assign, assignLoading } = useAssigneeMutations()

  const features = useCurrentUserFeatures()
  const [companyStatusModalOpen, setCompanyStatusModalOpen] = React.useState(false)
  const handleCompleted = (): void => {
    toast.success(t`Monitoring updated`)
  }
  const [updateCreditMonitoring, { data: updateCreditMonitoringData, loading: updateCreditMonitoringLoading }] =
    useUpdateCreditMonitoringMutation({
      onCompleted: handleCompleted
    })

  // We override as the isMonitred in ReviewCompanyData is not automatically updated by mutation
  const isMonitoredOverriden =
    updateCreditMonitoringData?.updateCreditMonitoring.company.creditReportV2?.isMonitored ?? isMonitored

  const isCreditMonitoringAvailable = !company.legalForm?.hasNorwegianSoleTraderCreditData
  if (!isCreditMonitoringAvailable || !hasCreditReport) return null

  const inPortfolio = companyInPortfolio(company.statusV2?.status)

  const enableCreditMonitoring = async (): Promise<void> => {
    await updateCreditMonitoring({
      variables: { company: company.id, enabled: true }
    })
  }

  const isLoadingRequests = updateCreditMonitoringLoading || assignLoading

  const handleMonitor = async (): Promise<void> => {
    if (isLoadingRequests) return

    if (!inPortfolio) {
      setCompanyStatusModalOpen(true)
      return
    }

    if (context === 'Review') {
      // assign to user doing the review
      await assign({
        companies: [company.id],
        existingAssignees: [
          {
            node: currentUser
          }
        ],
        usersToAssign: [currentUser]
      })
    }
    enableCreditMonitoring()
  }

  const handleStopMonitor = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault()
    if (isLoadingRequests) return

    await updateCreditMonitoring({
      variables: { company: company.id, enabled: false }
    })
  }

  if (!features.CREDIT_DATA_MONITORING) {
    return (
      <ContactStrise
        className={cn('w-[205px]', className)}
        intention={t`enable credit data monitoring`}
        additionalText={t`to enable credit data monitoring`}
        {...props}
      />
    )
  }

  if (!isMonitoredOverriden) {
    return (
      <>
        <Button
          variant='contained'
          palette='primary'
          className={className}
          onClick={handleMonitor}
          loading={isLoadingRequests}
          data-track='Button / Sidepanel / Credit report / Monitor'
          {...props}
        >
          <Trans>Monitor</Trans>
        </Button>
        {companyStatusModalOpen && (
          <CreditMonitoringCompanyStatusModal
            company={company}
            setModalOpen={setCompanyStatusModalOpen}
            editableCompanyStatus={context !== 'Review'}
            enableCreditMonitoring={enableCreditMonitoring}
          />
        )}
      </>
    )
  }

  return (
    <Button
      variant='ghost'
      palette='tertiary'
      className={className}
      loading={updateCreditMonitoringLoading}
      onClick={handleStopMonitor}
      data-track='Button / Sidepanel / Credit report / Stop monitoring'
      {...props}
    >
      <Trans>Stop monitoring</Trans>
    </Button>
  )
}
