import { Trans, t } from '@lingui/macro'
import { Empty } from '@strise/app-shared'
import { Typography } from '@strise/ui-components'
import { type StyleProps, Table, TableCell, TableRow, type TableRowProps } from '@strise/ui-components-legacy'
import * as React from 'react'
import { type CreditReportFragment } from '~/graphqlTypes'

const CreditReportIncomeRow: React.FC<
  {
    cellProps?: StyleProps
    title: string
    values: Array<string | null>
  } & TableRowProps
> = ({ cellProps, className, title, values, ...props }) => {
  return (
    <TableRow className={className} borderColor='black' borderBottom='1px solid' {...props}>
      <TableCell pt={4} {...cellProps}>
        <Typography>{title}</Typography>
      </TableCell>
      {values.map((value, index) => (
        <TableCell key={index} textAlign='right' {...cellProps}>
          <Typography>{value ?? '-'}</Typography>
        </TableCell>
      ))}
    </TableRow>
  )
}

export const SidepanelCreditReportIncome = ({
  creditReport
}: {
  creditReport: CreditReportFragment | null | undefined
}): React.ReactNode => {
  const income = creditReport?.incomes

  if (!income?.incomeYears.length) {
    return (
      <Empty title={t`No registered incomes found`}>
        <Trans>We couldn't find any registered incomes on this company</Trans>
      </Empty>
    )
  }

  return (
    <Table my={4}>
      <thead>
        <TableRow borderColor='black'>
          <TableCell>
            <Trans>in {creditReport?.currencyCode} '000</Trans>
          </TableCell>
          {income.incomeYears.map((year, index) => (
            <TableCell key={index} textAlign='right'>
              <Typography>{year}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </thead>
      <tbody>
        <CreditReportIncomeRow
          title={t`Municipality code`}
          values={income.municipalityCodes}
          cellProps={{ pt: 8, color: 'secondary.50' }}
        />
        <CreditReportIncomeRow title={t`Gross income`} values={income.grossIncomes} />
        <CreditReportIncomeRow
          title={t`Tax class`}
          values={income.taxClasses}
          cellProps={{ pt: 8, color: 'secondary.50' }}
          borderBottom='none'
        />
        <CreditReportIncomeRow title={t`Taxes paid`} values={income.sumTaxes} cellProps={{ color: 'secondary.50' }} />
        <CreditReportIncomeRow title={t`Net income`} values={income.netIncomes} />
        <CreditReportIncomeRow title={t`Net wealth`} values={income.netWealths} />
      </tbody>
    </Table>
  )
}
