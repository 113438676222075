import { t } from '@lingui/macro'
import { setChildState, useContext } from '@strise/react-utils'
import * as React from 'react'
import { FromToFilter } from '~/components/FromToFilter'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { GrowSettingsSections } from '~/views/Grow/components/GrowSettingsPanel'

export const GrowInceptionFilter = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)

  const inceptionSettings = settings.grow.inception
  const saveInceptionSettings = setChildState(saveSettings, 'grow.inception')

  const handleLessThanChange = (value: number | null): void => {
    saveInceptionSettings({ ...inceptionSettings, lessThan: value })
  }
  const handleMoreThanChange = (value: number | null): void => {
    saveInceptionSettings({ ...inceptionSettings, moreThan: value })
  }

  return (
    <GrowSettingsSections title={t`Inception`}>
      <div className='flex items-center'>
        <FromToFilter
          fromValue={inceptionSettings.moreThan}
          toValue={inceptionSettings.lessThan}
          setFromValue={handleMoreThanChange}
          setToValue={handleLessThanChange}
          fromLabel={t`More than (months)`}
          toLabel={t`Less than (months)`}
        />
      </div>
    </GrowSettingsSections>
  )
}
