import { IconCheckSmall } from '@strise/ui-components'
import { Button, type ButtonProps, cn } from '@strise/ui-components'
import * as React from 'react'

export const TeamActivityCompleteButton: React.FC<{ trackId: string } & ButtonProps> = ({
  children,
  className,
  trackId,
  ...props
}) => (
  <Button
    variant='contained'
    palette='tertiary'
    size='sm'
    className={cn('rounded-sm border pl-0 pr-2 text-sm', className)}
    type='button'
    data-track={trackId}
    startIcon={<IconCheckSmall />}
    {...props}
  >
    {children}
  </Button>
)
