import { type MessageDescriptor, i18n } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import { companyStatuses, flagSeverityToTitle, useSortedAssignees } from '@strise/app-shared'
import { companyStatusTitles } from '@strise/app-shared/src/i18n'
import { objectEntries, toTitleCase } from '@strise/ts-utils'
import {
  type CompanyStatus,
  type ContentLanguage,
  EventType,
  type FlagSeverity,
  type PageInput,
  type Primitive,
  SortOrdering,
  UserField,
  type UserSortInput
} from '@strise/types'
import { type ComboboxItem, type ComboboxPaginationProps } from '@strise/ui-components'
import * as React from 'react'
import { User } from '~/components/Assignee/User'
import { useYou } from '~/components/Assignee/assigneeHooks'
import { useSortTags, useTeamTags } from '~/components/Tags/tagUtils'
import { useSearchLocationsQuery, useTopicsQuery } from '~/graphqlOperations'
import {
  type CompanyTagFragment,
  type RegionFragment,
  type SimpleUserFragment,
  type TopicFragment
} from '~/graphqlTypes'
import { countryLabels, useCountryOptions } from '~/utils/country'
import { enumOptions, getTitle } from '~/utils/enum'
import { defaultTeamUsersPageLimit, useTeamUsers } from '~/utils/teamUsers'

export type FilterOptionsHook<T extends Primitive | object> = ({
  currentValues,
  searchValue
}: {
  currentValues: T[]
  searchValue: string | null | undefined
}) => {
  disableBackendSearch?: boolean
  hasNextPage?: boolean
  items: Array<ComboboxItem<T>>
  loading: boolean
  paginationProps?: ComboboxPaginationProps
  value: Array<ComboboxItem<T>>
}

export const useLoadingCountryOptions: FilterOptionsHook<ContentLanguage> = ({ currentValues }) => {
  const options = useCountryOptions()
  const value = enumOptions(currentValues, countryLabels)
  return { value, items: options, loading: false }
}

export const useTagOptions: FilterOptionsHook<CompanyTagFragment> = ({ currentValues }) => {
  const { loading, tags: teamTags } = useTeamTags()
  const tagEdges = useSortTags(teamTags)
  const options = tagEdges.map(({ node }) => ({
    label: node.name,
    id: node.id,
    value: node
  }))
  const value = currentValues.map((tag) => ({
    label: tag.name,
    id: tag.id,
    value: tag
  }))

  return { value, items: options, loading }
}

export const extractCompanyStatusOptions: FilterOptionsHook<CompanyStatus> = ({ currentValues }) => {
  return {
    value: enumOptions(currentValues, companyStatusTitles),
    items: enumOptions(companyStatuses, companyStatusTitles),
    loading: false
  }
}

export const extractSeverityOptions: FilterOptionsHook<FlagSeverity> = ({ currentValues }) => {
  const options = objectEntries(flagSeverityToTitle).map(([severity, severityTranslation]) => ({
    label: i18n._(severityTranslation),
    id: severity as string,
    value: severity
  }))
  const value = enumOptions(currentValues, flagSeverityToTitle)
  return { value, items: options, loading: false }
}

export const useTopicsOptions: FilterOptionsHook<TopicFragment> = ({ currentValues }) => {
  const { data, loading } = useTopicsQuery()

  const options =
    data?.topics.edges.map(({ node }) => ({
      label: node.name ? toTitleCase(node.name) : t`Unknown`,
      value: node,
      id: node.id
    })) ?? []

  const value = currentValues.map((topic) => ({
    label: topic.name ? toTitleCase(topic.name) : t`Unknown`,
    value: topic,
    id: topic.id
  }))

  return { value, items: options, loading }
}

export const useLocationOptions: FilterOptionsHook<RegionFragment> = ({ currentValues, searchValue }) => {
  const { data, loading } = useSearchLocationsQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { q: searchValue! },
    skip: !searchValue
  })

  const options =
    data?.locationsV2.edges.map((location) => ({
      label: location.node.name ?? t`Unknown`,
      value: location.node,
      id: location.node.id
    })) ?? []

  const value = currentValues.map((location) => ({
    label: location.name ?? t`Unknown`,
    value: location,
    id: location.id
  }))

  return { value, items: options, loading }
}

export const useAssigneeOptions: FilterOptionsHook<SimpleUserFragment | null> = ({ currentValues, searchValue }) => {
  const [maxTotalCount, setMaxTotalCount] = React.useState<number | null>(null)
  const [page, setPage] = React.useState<PageInput<UserSortInput>>({
    limit: defaultTeamUsersPageLimit,
    offset: 0,
    sort: [{ field: UserField.Name, ordering: SortOrdering.Ascending }]
  })

  const {
    hasNextPage,
    loading: teamUsersLoading,
    teamUsers
  } = useTeamUsers({
    variables: {
      q: searchValue,
      page
    },
    setPage,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const tCount = data.team.users.totalCount
      if (maxTotalCount === null && tCount && searchValue === '') {
        // Setting maxTotalCount initially to know the maximum amount with an empty `q` search string
        setMaxTotalCount(tCount)
      }
    }
  })

  const nonNullCurrentValues = currentValues.filter((val) => val !== null)

  // As we now use search and pagination backend, we need to merge assignees and teamUsers
  // Create a map of assignee ids for more efficient lookup
  const assigneeIds = new Set(nonNullCurrentValues.map((assignee) => assignee.id))
  // Filter out assignees from teamUsers
  const filteredTeamUsers = teamUsers.filter((teamUser) => !assigneeIds.has(teamUser.node.id))
  // Merge assignees and teamUsers
  const assigneeEnrichedTeamUsers: Array<{ node: SimpleUserFragment }> = [
    ...nonNullCurrentValues.map((user) => ({ node: user })),
    ...filteredTeamUsers
  ].flat()

  // If maxTotalCount is set, and we have fetched the equal amount of users, we can disable backend search - which will rarely be needed for most teams.
  const disableBackendSearch = !!maxTotalCount && maxTotalCount <= assigneeEnrichedTeamUsers.length

  const youUserEdges = useYou(assigneeEnrichedTeamUsers)
  const sortedUserEdges = useSortedAssignees(youUserEdges)
  const options: Array<ComboboxItem<SimpleUserFragment | null>> = [
    {
      id: String(null),
      label: t`No assignee`,
      value: null
    },
    ...sortedUserEdges.map(({ node }) => {
      return {
        id: node.id,
        label: node.name,
        value: node,
        renderNode: <User user={node} />
      }
    })
  ]
  const value = currentValues.map((user) => {
    return {
      id: user?.id ?? String(null),
      label: user?.name ?? t`No assignee`,
      value: user
    }
  })

  const handlePageChange = (direction: 'next' | 'previous'): void => {
    const newOffset =
      direction === 'next'
        ? page.offset + defaultTeamUsersPageLimit
        : Math.max(0, page.offset - defaultTeamUsersPageLimit)
    setPage((prevPage) => ({
      ...prevPage,
      offset: newOffset
    }))
  }

  const paginationProps: ComboboxPaginationProps = {
    disabled: teamUsersLoading,
    loading: teamUsersLoading,
    showPrevious: page.offset > 0,
    showNext: hasNextPage,
    onPrevious: () => handlePageChange('previous'),
    onNext: () => handlePageChange('next')
  }

  return {
    value,
    items: options,
    loading: teamUsersLoading,
    paginationProps: disableBackendSearch ? undefined : paginationProps,
    disableBackendSearch
  }
}

const filterableEventTypes = [
  EventType.Announcements,
  EventType.Correspondence,
  EventType.Courts,
  EventType.FlaggedEvents,
  EventType.Media
] as const

export const eventTypeTitles: {
  [key in EventType]?: MessageDescriptor
} = {
  [EventType.FlaggedEvents]: defineMessage({ message: 'Flagged events' }),
  [EventType.Announcements]: defineMessage({ message: 'Announcements' }),
  [EventType.Courts]: defineMessage({ message: 'Courts' }),
  [EventType.Correspondence]: defineMessage({ message: 'Correspondence' }),
  [EventType.Media]: defineMessage({ message: 'Media' })
}

export const extractEventTypesOptions: FilterOptionsHook<EventType> = ({ currentValues }) => {
  const options = filterableEventTypes.map((eventType) => {
    const title = getTitle(eventTypeTitles[eventType])

    return { id: eventType as string, label: title, value: eventType }
  })

  const value = currentValues.map((eventType) => {
    const title = getTitle(eventTypeTitles[eventType])

    return { id: eventType as string, label: title, value: eventType }
  })

  return { value, items: options, loading: false }
}
