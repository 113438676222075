import { AuditRisk } from '@strise/types'
import * as React from 'react'
import { BaseRiskChip } from '~/components/Risk/BaseRiskChip'
import { getTitle } from '~/utils/enum'
import { auditRiskTitles, auditToRiskColor, riskChipClasses } from '~/utils/risk'

interface AuditRiskChipProps {
  className?: string
  onClick?: () => void
  risk: AuditRisk | null
}

export const AuditRiskChip = ({ className, onClick, risk }: AuditRiskChipProps): React.ReactNode => {
  const nonNullableAuditRisk = risk || AuditRisk.None

  return (
    <BaseRiskChip
      className={className}
      colorClass={riskChipClasses[auditToRiskColor[nonNullableAuditRisk]]}
      label={getTitle(auditRiskTitles[nonNullableAuditRisk])}
      onClick={onClick}
    />
  )
}
