import { IdentityVerificationStatus, ReviewIdVerificationStatus } from '@strise/types'
import React from 'react'
import { IDVCheckItem } from '~/components/IDVCheck/IDVCheckItem'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { reviewEntityToPerson } from '~/components/Review/reviewValueTransformers'
import { type ReviewIdvRoleValueFragment } from '~/graphqlTypes'

const statusMap: Record<ReviewIdVerificationStatus, IdentityVerificationStatus> = {
  [ReviewIdVerificationStatus.NoStatus]: IdentityVerificationStatus.NoStatus,
  [ReviewIdVerificationStatus.PendingDocument]: IdentityVerificationStatus.PendingDocument,
  [ReviewIdVerificationStatus.PendingReview]: IdentityVerificationStatus.PendingReview,
  [ReviewIdVerificationStatus.Approved]: IdentityVerificationStatus.Approved,
  [ReviewIdVerificationStatus.Declined]: IdentityVerificationStatus.Declined,
  [ReviewIdVerificationStatus.NotRequired]: IdentityVerificationStatus.NotRequired,
  [ReviewIdVerificationStatus.Failed]: IdentityVerificationStatus.Failed,
  [ReviewIdVerificationStatus.IdvRequestCreated]: IdentityVerificationStatus.IdvRequestCreated
}

export const ReviewIdvRole = ({
  companyId,
  item,
  refetchIdvRoles
}: {
  companyId: string
  item: ReviewIdvRoleValueFragment
  refetchIdvRoles: () => void
}) => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const idvRole = {
    person: reviewEntityToPerson(item.value.person),
    idvRequest: item.value.idvRequest
      ? {
          ...item.value.idvRequest,
          status: statusMap[item.value.idvRequest.status],
          __typename: 'IdentityVerificationRequest' as const
        }
      : null,
    __typename: 'IdentityVerificationRole' as const
  }
  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <IDVCheckItem companyId={companyId} refetchIdvRoles={refetchIdvRoles} role={idvRole} />
    </ReviewItem>
  )
}
