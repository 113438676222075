import { Pagination } from '@strise/app-shared/src/i18n'
import { type SetStateFn } from '@strise/react-utils'
import { Divider } from '@strise/ui-components'
import * as React from 'react'

export const PortfolioTablePagination: React.FC<{
  count: number
  page: number
  rowsPerPage: number
  setPage: (page: number) => void
  setRowsPerPage: SetStateFn<number>
}> = ({ count, page, rowsPerPage, setPage, setRowsPerPage }) => {
  return (
    <div className='sticky bottom-0 z-[11] bg-secondary-shade-5'>
      <Pagination
        pageIndex={page}
        pageSize={rowsPerPage}
        totalResults={count}
        setPageIndex={setPage}
        setPageSize={setRowsPerPage}
      />

      <Divider />
    </div>
  )
}
