import { type DivProps } from '@strise/react-utils'
import { IconSearch, Typography, type TypographyProps, cn } from '@strise/ui-components'
import * as React from 'react'

export const Empty: React.FC<
  {
    children?: React.ReactNode
    title: string
    titleProps?: TypographyProps
  } & DivProps
> = ({ children, className, title, titleProps, ...props }) => {
  return (
    <div className={cn('flex flex-col', className)} {...props}>
      <IconSearch className='mb-4 block size-[42px] text-text-secondary' />
      <div>
        <Typography variant='subtitle1' {...titleProps}>
          {title}
        </Typography>
        <Typography variant='body1'>{children}</Typography>
      </div>
    </div>
  )
}
