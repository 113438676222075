import { languageToCountryCode } from '../../utils/language'
import { type NationFlagBaseProps } from './NationFlag'
import { NationFlags } from './NationFlags'
import { type DivProps } from '@strise/react-utils'
import { filterNullishValues } from '@strise/ts-utils'
import { type ContentLanguage } from '@strise/types'
import * as React from 'react'

export const LanguageNationFlags = ({
  className,
  iconProps,
  languages,
  ...props
}: { iconProps?: NationFlagBaseProps; languages: ContentLanguage[] } & DivProps): React.ReactNode => {
  const countryIsoCodes = filterNullishValues(languages.map((language) => languageToCountryCode[language]))

  return <NationFlags className={className} countryIsoCodes={countryIsoCodes} iconProps={iconProps} {...props} />
}
