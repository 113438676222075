import { type NormalizedCacheObject } from '@apollo/client/index.js'
import { InvalidationPolicyCache } from '@nerdwallet/apollo-cache-policies'
import possibleTypesResult from '@strise/types/src/graphqlPossibleTypes'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import { SCHEMA_VERSION, SCHEMA_VERSION_KEY, invalidationPolicies } from '~/apolloClient/invalidationPolicies'
import { typePolicies } from '~/apolloClient/typePolicies'
import { apiEnvState } from '~/state'

const { possibleTypes } = possibleTypesResult

export const initCache = async (
  window: Window
): Promise<{ cache: InvalidationPolicyCache; persistor: CachePersistor<NormalizedCacheObject> | null }> => {
  const apiEnv = apiEnvState().get()

  // Initialize the cache with custom policies
  const cache = new InvalidationPolicyCache({
    possibleTypes,
    typePolicies,
    invalidationPolicies
  })

  // Skip persistence for LOCAL environment
  if (apiEnv === 'LOCAL') {
    // eslint-disable-next-line no-console
    console.log('Skipping cache persistence for local API')
    return { cache, persistor: null }
  }

  // Create a cache persistor to store cache in localStorage
  const persistor = new CachePersistor({
    key: 'strise-cache',
    debug: apiEnv !== 'PROD',
    cache,
    maxSize: 4_000_000, // 4 MB
    storage: new LocalStorageWrapper(window.localStorage)
  })

  // Check if the stored schema version matches the current version
  if (window.localStorage.getItem(SCHEMA_VERSION_KEY) === SCHEMA_VERSION) {
    // If versions match, restore the persisted cache
    await persistor.restore()
  } else {
    // If versions don't match, purge the outdated cache
    await persistor.purge()
    // Update the schema version in localStorage
    window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  }

  return { cache, persistor }
}
