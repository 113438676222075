import React from 'react'
import { OwnerDetail } from '~/components/OwnerDetail'
import { ReviewItem } from '~/components/Review/ReviewItem'
import {
  reviewCustomMetaToCustomMeta,
  reviewEntityToEntity,
  reviewEntityToPerson
} from '~/components/Review/reviewValueTransformers'
import { type ReviewOwnerValueFragment } from '~/graphqlTypes'

export const ReviewOwner = ({
  item,
  rootEntityId
}: {
  item: ReviewOwnerValueFragment
  rootEntityId: string
}): React.ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const entity = item.value.entity ? reviewEntityToEntity(item.value.entity) : null

  const uboMeta = {
    ...item.value.beneficialOwnershipMeta,
    reason: item.value.beneficialOwnershipMeta?.reason ?? null,
    familyRelationships: item.value.beneficialOwnershipMeta?.familyRelationships
      ? item.value.beneficialOwnershipMeta.familyRelationships.map((familyRelationship) => ({
          person: {
            ...reviewEntityToPerson(familyRelationship.entity)
          },
          translatedRelationships: familyRelationship.translatedRelationships,
          __typename: 'FamilyRelationship' as const
        }))
      : [],
    meta: item.value.beneficialOwnershipMeta?.meta
      ? {
          ...item.value.beneficialOwnershipMeta.meta,
          __typename: 'FieldValuePair' as const
        }
      : null,
    __typename: 'BeneficialOwnerMeta' as const
  }

  const customMeta = item.value.customMeta ? reviewCustomMetaToCustomMeta(item.value.customMeta) : null

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <OwnerDetail
        entity={entity}
        entityName={item.value.entityName}
        ownership={item.value.ownershipPercentage}
        uboMeta={uboMeta}
        customMeta={customMeta}
        shareClasses={item.value.shareClasses}
        rootEntityId={rootEntityId}
      />
    </ReviewItem>
  )
}
