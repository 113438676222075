import { Typography, type TypographyProps } from '../Typography'
import * as React from 'react'

export type LabelProps = TypographyProps & React.HTMLProps<HTMLLabelElement>
export const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, ...props }, ref): React.ReactNode => (
    <Typography ref={ref} className={className} component='label' variant='body2' {...props} />
  )
)
Label.displayName = 'Label'
