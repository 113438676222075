import { Button, Calendar, IconCalendar, Popover, Typography } from '../..'
import { cn } from '../../utils/className'
import { type DataProps } from '@strise/react-utils'
import { type Locale, format } from 'date-fns'
import { enGB } from 'date-fns/locale'
import * as React from 'react'
import { type DateRange } from 'react-day-picker'

interface DatePickerProps extends DataProps {
  disablePortal?: boolean
  disabled?: boolean
  locale?: Locale
  onChange?: (date: Date | undefined) => void
  placeholder?: string
  value?: Date
}

interface DatePickerRangeProps extends DataProps {
  disablePortal?: boolean
  disabled?: boolean
  locale?: Locale
  onChange?: (dates: DateRange | undefined) => void
  placeholder?: string
  value?: DateRange
}

/** For the DatePicker to work, it needs some base styles from @react-day-picker
 * Include the styles like this: import '@strise/ui-components/src/react-day-picker.css'
 * */
const DatePicker = React.forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    { disablePortal, disabled = false, locale = enGB, onChange, placeholder = 'Pick a date', value, ...restProps },
    ref
  ) => {
    const handleDateChange = React.useCallback(
      (newDate: Date | undefined) => {
        onChange?.(newDate)
      },
      [onChange]
    )

    const formattedDate = React.useMemo(() => {
      return value ? format(value, 'PP', { locale }) : placeholder
    }, [value, placeholder, locale])

    return (
      <Popover
        disablePortal={disablePortal}
        className='rounded-md bg-background-paper shadow-sm shadow-gray-50'
        content={
          <Calendar
              mode='single'
              locale={locale}
              selected={value}
              onSelect={handleDateChange}
              captionLayout='dropdown-years'
              {...restProps}
            />
        }
      >
        <Button
          ref={ref}
          disabled={disabled}
          variant='outlined'
          className={cn('w-full justify-start text-left rounded-sm', !value && 'text-gray-70')}
        >
          <IconCalendar className='mr-2 size-4' />
          <Typography component='span'>{formattedDate}</Typography>
        </Button>
      </Popover>
    )
  }
)

DatePicker.displayName = 'DatePicker'

const DatePickerRange = React.forwardRef<HTMLButtonElement, DatePickerRangeProps>(
  (
    { disablePortal, disabled = false, locale = enGB, onChange, placeholder = 'Pick a date', value, ...restProps },
    ref
  ) => {
    const [open, setOpen] = React.useState(false)

    const handleDateChange = React.useCallback(
      (newDate: DateRange | undefined) => {
        onChange?.(newDate)
      },
      [onChange]
    )

    const formattedDate = React.useMemo(() => {
      if (value?.from && value.to) {
        return `${format(value.from, 'PPP', { locale })} - ${format(value.to, 'PPP', { locale })}`
      }
      return placeholder
    }, [value, placeholder, locale])

    return (
      <Popover
        open={open}
        onOpenChange={setOpen}
        disablePortal={disablePortal}
        className='rounded-md bg-background-paper shadow-sm shadow-gray-50'
        content={
          <>
            <Calendar
              locale={locale}
              defaultMonth={new Date(new Date().setMonth(new Date().getMonth() - 1))}
              mode='range'
              disabled={{ after: new Date() }}
              classNames={{
                months: 'flex flex-col sm:flex-row space-y-4 space-x-8 sm:space-y-0 justify-center'
              }}
              numberOfMonths={2}
              selected={value}
              onSelect={handleDateChange}
              captionLayout='dropdown-years'
              {...restProps}
            />
            <Button variant='contained' palette='secondary' className='w-full' onClick={() => setOpen(false)}>
              OK
            </Button>
          </>
        }
      >
        <Button
          ref={ref}
          disabled={disabled}
          variant='outlined'
          onClick={() => setOpen(true)}
          className={cn('w-full justify-start text-left rounded-sm', !value && 'text-gray-70')}
        >
          <IconCalendar className='mr-2 size-4' />
          <Typography component='span'>{formattedDate}</Typography>
        </Button>
      </Popover>
    )
  }
)

DatePickerRange.displayName = 'DatePickerRange'

export { DatePicker, DatePickerRange }

export { type DateRange } from 'react-day-picker'
