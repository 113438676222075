/**
 * Gets browser builtin globals in a server-side-rendering-safe way
 *
 * @returns The globals if in a browser environment, or undefined if in an
 * SSR environment
 */
export function getBrowserGlobals(): { document: Document; window: Window & typeof globalThis } | undefined {
  // eslint-disable-next-line no-restricted-globals
  return typeof window === 'undefined' ? undefined : { window, document }
}
