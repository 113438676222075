import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconChevronUp = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m12 4.586 10.707 10.707-1.414 1.414L12 7.414l-9.293 9.293-1.414-1.414L12 4.586Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconChevronUp.displayName = 'IconChevronUp'
