import { Trans } from '@lingui/macro'
import { useSizeLimitedPersistentState } from '@strise/react-utils'
import { Button, Typography } from '@strise/ui-components'
import * as React from 'react'
import { EntityMeta } from '~/components/EntityMeta/EntityMeta'
import { FlyIcon } from '~/components/Review/FlyIcon'
import { PrivatePersonReviewCardContent } from '~/components/Review/PrivatePersonReviewCardContent'
import { STORAGE_KEYS } from '~/constants'
import { type PrivatePersonBaseFragment, type TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { ReviewCardContentView, type ReviewState, defaultReviewState } from '~/utils/reviewUtils'
import { TestIDs } from '~/utils/testIDs'

interface PrivatePersonReviewCardProps {
  filterHeight: number | undefined
  privatePerson: PrivatePersonBaseFragment
  teamId: string
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}

export const PrivatePersonReviewCard = React.forwardRef<HTMLDivElement, PrivatePersonReviewCardProps>(
  ({ filterHeight, privatePerson, teamId, teamReviewSettings }, ref) => {
    const [cardView, setCardView] = React.useState<ReviewCardContentView>(() => ReviewCardContentView.BEFORE_REVIEW)

    const stateKey = `${STORAGE_KEYS.reviewStatePrivatePersons}-${privatePerson.id}-${teamId}`
    const [reviewState, setReviewState, resetReviewState] = useSizeLimitedPersistentState<ReviewState>(
      stateKey,
      defaultReviewState,
      {
        maxSize: 5,
        daysToLive: 7
      }
    )

    return (
      <div ref={ref} className='min-h-[250px]'>
        <div
          className='sticky z-[4] overflow-hidden'
          style={{ top: filterHeight }}
          data-id={TestIDs.Review.Card.Header}
        >
          <EntityMeta className='bg-background-paper px-4 py-2' variant='large' entity={privatePerson} link />
        </div>
        <div className='border-t-2 border-solid border-tertiary-main bg-background-paper py-4 min-h-[150px]'>
          {cardView === ReviewCardContentView.BEFORE_REVIEW && (
            <div className='grid grid-cols-[1fr_max-content] grid-rows-[1fr_min-content] items-center gap-4 px-4 pt-4'>
              <div className='col-[1/3] mb-1 self-center'>
                <FlyIcon />
              </div>
              <Typography className='text-text-secondary'>
                <Trans>This person has not been reviewed yet.</Trans>
              </Typography>
              <Button
                variant='contained'
                palette='primary'
                className='mt-0'
                data-track='Private Person Review / Start Review'
                onClick={() => setCardView(ReviewCardContentView.IN_REVIEW)}
              >
                <Trans>Start Review</Trans>
              </Button>
            </div>
          )}
          {cardView === ReviewCardContentView.IN_REVIEW && (
            <PrivatePersonReviewCardContent
              privatePersonId={privatePerson.id}
              resetReviewState={resetReviewState}
              reviewState={reviewState}
              setReviewState={setReviewState}
              teamReviewSettings={teamReviewSettings}
            />
          )}
        </div>
      </div>
    )
  }
)
