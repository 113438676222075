import { type SetStateFn } from '@strise/react-utils'
import { type Primitive } from '@strise/types'
import { Combobox, type ComboboxItem, type ComboboxPaginationProps } from '@strise/ui-components'
import * as React from 'react'
import { FilterButtonText } from '~/components/Filter/Filter'

export const EditFilterArray = <T extends Primitive | object>({
  dataTrack,
  disableBackendSearch,
  editLabel,
  inputValue,
  loading,
  options,
  paginationProps,
  search,
  setInputValue,
  setValue,
  value,
  ...props
}: {
  dataTrack: string
  disableBackendSearch?: boolean
  editLabel: string
  inputValue: string
  loading?: boolean
  options: Array<ComboboxItem<T>>
  paginationProps?: ComboboxPaginationProps
  search?: boolean
  setInputValue: SetStateFn<string>
  setValue: SetStateFn<T[]>
  value: Array<ComboboxItem<T>>
}): React.ReactNode => {
  const changeHandler = (values: Array<ComboboxItem<T>>): void => {
    setValue(values.map((v) => v.value))
  }

  const resetHandler = (): void => {
    setValue([])
  }

  const filterButtonText = <FilterButtonText onReset={resetHandler} chipLabel={value.length} label={editLabel} />

  return (
    <Combobox
      variant='contained'
      palette='secondary'
      className='h-sub-header w-full bg-secondary-shade-90 px-4 text-white data-[state=open]:bg-secondary-shade-90'
      inputClassName='text-white'
      items={options}
      value={value}
      onChange={changeHandler}
      inlineSearch={search}
      disableSearch={!search}
      loading={loading}
      paginationProps={disableBackendSearch ? undefined : paginationProps}
      search={disableBackendSearch ? undefined : inputValue}
      setSearch={disableBackendSearch ? undefined : setInputValue}
      popoverProps={{ className: 'min-w-[200px]' }}
      data-track={dataTrack}
      customSelectedItemsRenderer={filterButtonText}
      {...props}
    >
      {filterButtonText}
    </Combobox>
  )
}
