import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconChevronLeftSmall = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M13.293 16.707 8.586 12l4.707-4.707 1.414 1.414L11.414 12l3.293 3.293-1.414 1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconChevronLeftSmall.displayName = 'IconChevronLeftSmall'
