import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconPhone = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m9.362 2 1.453 7.268-1.96 2.351a19.997 19.997 0 0 0 3.602 3.609l2.753-1.967 6.79.755v5.842a3 3 0 0 1-3.396 2.973l-2.275-.303A17 17 0 0 1 1.867 8.81l-.61-3.257A3 3 0 0 1 4.204 2h5.157Zm-.695 6.722L7.722 4H4.205a1 1 0 0 0-.983 1.184l.61 3.258a15.03 15.03 0 0 0 1.986 5.126 15.026 15.026 0 0 0 7.319 6.09c1.096.426 2.254.727 3.456.888l2.275.303A1 1 0 0 0 20 19.858v-4.052l-4.25-.473-3.306 2.361-.535-.382a21.998 21.998 0 0 1-4.813-4.702l-.79-1.054 2.36-2.834ZM20 11a7 7 0 0 0-7-7V2a9 9 0 0 1 9 9h-2Z'
        clipRule='evenodd'
      />
      <path fill='currentColor' fillRule='evenodd' d='M17 11a4 4 0 0 0-4-4V5a6 6 0 0 1 6 6h-2Z' clipRule='evenodd' />
    </svg>
  )
)
IconPhone.displayName = 'IconPhone'
