import { t } from '@lingui/macro'
import type { SetStateFn } from '@strise/react-utils'
import { ReviewValueStatusKind } from '@strise/types'
import React from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { ReviewEvents } from '~/components/Review/items/ReviewEvents'
import { reviewEntityToEntity } from '~/components/Review/reviewValueTransformers'
import type { ReviewAmsSectionFragment } from '~/graphqlTypes'
import type { ReviewState } from '~/utils/reviewUtils'

export const ReviewEventSections = ({
  reviewState,
  sections,
  setReviewState
}: {
  reviewState: ReviewState
  sections: ReviewAmsSectionFragment[]
  setReviewState: SetStateFn<ReviewState>
}): React.ReactNode => {
  const filteredSections = sections.filter((section) => !!section.events.filter((event) => event.value).length)

  if (!filteredSections.length) {
    return (
      <ReviewItem
        reviewMeta={{
          status: ReviewValueStatusKind.Ok,
          reason: t`No events`,
          __typename: 'ReviewValueMeta' as const
        }}
      />
    )
  }

  return filteredSections.map((section) => {
    const entity = reviewEntityToEntity(section.entity)

    return (
      <div key={entity.id}>
        <EntityLink entity={entity} />
        <ReviewEvents
          items={section.events}
          entityId={entity.id}
          reviewState={reviewState}
          setReviewState={setReviewState}
        />
      </div>
    )
  })
}
