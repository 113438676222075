import { FullPageLoader } from '../FullPageLoader'
import { useLogout } from './authUtils'
import * as React from 'react'

export const LogOutView = React.forwardRef((_, ref: React.ForwardedRef<HTMLDivElement>) => {
  const logout = useLogout()
  React.useEffect(() => {
    logout()
  }, [logout])
  return (
    <div ref={ref}>
      <FullPageLoader />
    </div>
  )
})
