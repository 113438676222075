import { t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import {
  type UpdateCompanyNotificationSettingsMutationFn,
  useUpdateCompanyNotificationSettingsMutation
} from '~/graphqlOperations'

export const useChangeCompanyNotificationSetting = (): {
  loading: boolean
  update: UpdateCompanyNotificationSettingsMutationFn
} => {
  const handleCompleted = (): void => {
    toast.success(t`Notification settings updated`)
  }
  const [update, { loading }] = useUpdateCompanyNotificationSettingsMutation({
    onCompleted: handleCompleted
  })

  return {
    update,
    loading
  }
}
