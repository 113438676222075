import { borders } from './borders'
import { colors } from './colors'
import { noWrap } from './noWrap'
import { numeric } from './numeric'
import { textDecoration } from './textDecoration'
import { transform } from './transform'
import { wrapText } from './wrapText'
import { compose, display, flexbox, grid, positions, shadows, sizing, spacing, typography } from '@mui/system'

export const handleStyleProps = compose(
  borders,
  display,
  flexbox,
  grid,
  colors,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
  wrapText,
  noWrap,
  textDecoration,
  transform,
  numeric
)
