import { SortableTableBase } from './SortableTableBase'
import { type SortableTableCommonProps } from './sortableTableUtils'
import { type SetStateFn } from '@strise/react-utils'
import { type SortDirection, filterNullishValues } from '@strise/ts-utils'
import { type GenericSortInput, type PageInput } from '@strise/types'
import { SortOrdering } from '@strise/types'
import * as React from 'react'

interface SortableBackendTableProps<K extends string, S extends GenericSortInput> extends SortableTableCommonProps<K> {
  page: PageInput<S>
  setPage: SetStateFn<PageInput<S>>
  totalRows: number
}

const sortOrderingMap: Record<SortOrdering, SortDirection> = {
  [SortOrdering.Ascending]: 'asc',
  [SortOrdering.Descending]: 'desc'
}

export const SortableBackendTable = <K extends string, S extends GenericSortInput>({
  // Data
  className,
  columns,

  // Initial state
  page,
  setPage,
  totalRows,

  ...props
}: SortableBackendTableProps<K, S>): React.ReactNode => {
  const sortFields = filterNullishValues(
    page.sort.map((sortItem) => {
      const matchingColumn = columns.find((column) => column.sortField === sortItem.field)
      if (!matchingColumn) return null

      return {
        field: matchingColumn.field,
        direction: sortOrderingMap[sortItem.ordering]
      }
    })
  )

  const columnsWithSort = columns.map((column) => ({
    ...column,
    sortable: !!column.sortField
  }))

  const updateSortField =
    (field: K): (() => void) =>
    () => {
      setPage((prevPage) => {
        const matchingColumn = columns.find((column) => column.field === field)

        if (!matchingColumn?.sortField) {
          return { ...prevPage, sort: [] as S[] }
        }

        const matchingSortField = prevPage.sort.find((sortItem) => sortItem.field === matchingColumn.sortField)

        if (!matchingSortField) {
          return {
            ...prevPage,
            sort: [
              {
                field: matchingColumn.sortField,
                ordering: SortOrdering.Ascending
              }
            ] as S[]
          }
        }

        if (matchingSortField.ordering === SortOrdering.Ascending) {
          return {
            ...prevPage,
            sort: [
              {
                field: matchingColumn.sortField,
                ordering: SortOrdering.Descending
              }
            ] as S[]
          }
        }

        return { ...prevPage, sort: [] as S[] }
      })
      setPageIndex(0)
    }

  const setPageIndex = (pageIndex: number): void => {
    setPage((prevPage) => ({ ...prevPage, offset: prevPage.limit * pageIndex }))
  }

  const setPageSize = (pageSize: number): void => {
    setPage((prevPage) => ({ ...prevPage, limit: pageSize, offset: 0 }))
  }

  const pageIndex = page.offset / page.limit

  return (
    <SortableTableBase
      className={className}
      columns={columnsWithSort}
      totalRows={totalRows}
      pageSize={page.limit}
      sortFields={sortFields}
      updateSortField={updateSortField}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      setPageSize={setPageSize}
      {...props}
    />
  )
}
