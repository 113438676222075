import { Trans } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { CompanyStatus } from '@strise/types'
import { Divider, Typography, cn } from '@strise/ui-components'
import React from 'react'
import { CompaniesStatusSelect } from '~/components/CompanyStatus/CompaniesStatusSelect'
import { TestIDs } from '~/utils/testIDs'

export const ReviewCompanyStatusSection = ({
  companyId,
  companyStatus,
  disabled,
  errors,
  noDivider,
  setCompanyStatus
}: {
  companyId: string
  companyStatus: CompanyStatus | null
  disabled: boolean
  errors: boolean
  noDivider?: boolean
  setCompanyStatus: SetStateFn<CompanyStatus | null>
}): React.ReactNode => {
  const companyStatusError = errors && companyStatus === CompanyStatus.InQualification

  const handleCompanyStatusChanged = (status: CompanyStatus | null): void => {
    setCompanyStatus(status)
  }

  return (
    <div className='my-2'>
      <Typography className='mb-2 mt-4' variant='subtitle1'>
        <Typography className='text-semantic-danger-main' component='span'>
          *
        </Typography>{' '}
        <Trans>Status</Trans>{' '}
        <Typography className='text-text-secondary' component='span'>
          <Trans>(Required)</Trans>
        </Typography>
      </Typography>
      <CompaniesStatusSelect
        className={cn('mb-2', companyStatusError && 'border border-semantic-danger-main')}
        companyIds={[companyId]}
        companyStatus={companyStatus}
        updateCompanyStatus={handleCompanyStatusChanged}
        contentProps={{ align: 'start', 'data-id': TestIDs.Review.Card.CompanyStatus.options }}
        removable={false}
        disabled={disabled}
        disabledStatuses={[CompanyStatus.InQualification]}
        data-id={TestIDs.Review.Card.CompanyStatus.trigger}
      />

      {companyStatusError && (
        <Typography className='text-semantic-danger-main'>
          <Trans>Set another company status</Trans>
        </Typography>
      )}

      {!noDivider && <Divider />}
    </div>
  )
}
