import { type RootNodeType } from './RootNode'
import { centerOnRootNode } from './ownerUtils'
import {
  IconArrowDown,
  IconButton,
  type IconButtonProps,
  IconCompany,
  IconMinus,
  IconPlus
} from '@strise/ui-components'
import { type FitViewOptions, useReactFlow } from '@xyflow/react'
import * as React from 'react'

export const ZoomButton = ({
  zoomVariant,
  ...props
}: { zoomVariant: 'in' | 'out' } & IconButtonProps): React.ReactNode => {
  const { zoomIn, zoomOut } = useReactFlow()

  return (
    <IconButton
      onClick={() => {
        if (zoomVariant === 'in') {
          zoomIn()
        } else {
          zoomOut()
        }
      }}
      {...props}
    >
      {zoomVariant === 'in' ? <IconPlus size='sm' /> : <IconMinus size='sm' />}
    </IconButton>
  )
}

export const FocusRootButton = ({ node, ...props }: { node: RootNodeType } & IconButtonProps): React.ReactNode => {
  const { setCenter } = useReactFlow()

  return (
    <IconButton
      onClick={() => {
        centerOnRootNode(node, setCenter)
      }}
      {...props}
    >
      <IconCompany size='sm' />
    </IconButton>
  )
}

export const ScrollToLastNodeButton = ({
  view,
  ...props
}: { view: FitViewOptions } & IconButtonProps): React.ReactNode => {
  const { fitView } = useReactFlow()

  return (
    <IconButton
      onClick={() => {
        fitView(view)
      }}
      {...props}
    >
      <IconArrowDown size='sm' />
    </IconButton>
  )
}
