import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { IconButton, IconCross, IconListSearch, Input, Typography, cn } from '@strise/ui-components'
import * as React from 'react'
import { useOnClickOutside, useToggle } from 'usehooks-ts'

export const SubHeaderStringFilter = ({
  dataTrackPrefix,
  filter,
  inputClassName,
  placeholder,
  setFilter
}: {
  dataTrackPrefix: string
  filter: string
  inputClassName: string
  placeholder: string
  setFilter: SetStateFn<string>
}): React.ReactNode => {
  const [isActive, toggleIsActive] = useToggle(false)
  const wrapperRef = React.useRef<HTMLDivElement | null>(null)
  const inputRef = React.useRef<null | HTMLInputElement>(null)

  useOnClickOutside(wrapperRef, toggleIsActive)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => setFilter(event.target.value)
  const handleResetInput = (): void => {
    if (!inputRef.current) return

    setFilter('')
    inputRef.current.focus()
  }

  if (!isActive) {
    return (
      <div>
        <IconButton
          className='h-full border-l border-solid border-divider px-4'
          variant='ghost'
          palette='tertiary'
          onClick={toggleIsActive}
          data-track={`${dataTrackPrefix} / Search filter / Open`}
          data-id='review-search-filter-button'
          data-testid='review-search-filter-button'
          aria-label={t`Open search filter`}
        >
          {filter && <Typography className='mr-4'>{filter}</Typography>}
          <IconListSearch />
        </IconButton>
      </div>
    )
  }

  const IconRight = (
    <>
      {filter && (
        <IconButton
          className='mr-2 rounded-full bg-secondary-shade-20 p-2'
          variant='contained'
          palette='tertiary'
          onClick={handleResetInput}
          data-track={`${dataTrackPrefix} / Search filter / Reset`}
          data-id='review-search-filter-cross-button-icon'
          aria-label={t`Reset search filter`}
        >
          <IconCross size='xs' />
        </IconButton>
      )}

      <IconListSearch />
    </>
  )

  return (
    <div ref={wrapperRef}>
      <Input
        variant='contained'
        palette='tertiary'
        className={cn(inputClassName, 'h-full border border-secondary-shade-20')}
        endIcon={IconRight}
        value={filter}
        onChange={handleInputChange}
        placeholder={placeholder}
        autoFocus={true}
        ref={inputRef}
        data-id='review-search-input-field'
        aria-label={placeholder}
      />
    </div>
  )
}
