import { type SetStateFn } from '@strise/react-utils'
import { ReviewSettingKind } from '@strise/types'
import { Checkbox, Typography, cn } from '@strise/ui-components'
import * as React from 'react'
import { ReviewAlert } from '~/components/Review/ReviewAlert'
import { ReviewCardInlineComments } from '~/components/Review/ReviewCardInlineComments'
import { ReviewCheckboxColumn } from '~/components/Review/ReviewCheckboxColumn'
import { type ReviewRowType } from '~/components/Review/reviewCardUtils'
import type { ReviewAlertFragment, TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { type ReviewCheckedMap, type ReviewInlineCommentsStateMap, extractTextColorClass } from '~/utils/reviewUtils'
import { TestIDs } from '~/utils/testIDs'

interface ReviewRow2Props {
  alerts: ReviewAlertFragment[]
  checkedMap: ReviewCheckedMap
  disabled?: boolean
  entityId: string
  errors: boolean
  inlineCommentsMap: ReviewInlineCommentsStateMap
  loading: boolean
  row: ReviewRowType
  setCheckedMap: SetStateFn<ReviewCheckedMap>
  setInlineCommentsMap: SetStateFn<ReviewInlineCommentsStateMap>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2'] | null
}

export const ReviewRow = ({
  alerts,
  checkedMap,
  disabled,
  entityId,
  errors,
  inlineCommentsMap,
  loading,
  row,
  setCheckedMap,
  setInlineCommentsMap,
  teamReviewSettings
}: ReviewRow2Props): React.ReactNode => {
  const rowDisabled = disabled
  const checked = !!checkedMap[row.key]
  const [isHovered, setIsHovered] = React.useState(false)
  const inlineCommentsEnabled = teamReviewSettings?.settings.find(
    (setting) => setting.kind === ReviewSettingKind.EnableInlineComments
  )?.enabled
  const colorClass = extractTextColorClass(errors && !checked, checked)

  if (!row.children) {
    return null
  }

  const matchingAlert = alerts.find((alert) => alert.kind === row.kind)

  const updateCheckedState = (key: string): void => {
    setCheckedMap({
      ...checkedMap,
      [key]: !checkedMap[key]
    })
  }

  const textColorClass = checked ? 'text-text-secondary' : 'text-text-primary'

  return (
    <div
      className={cn('relative flex border-b border-divider', isHovered ? 'bg-tertiary-main' : 'bg-transparent')}
      onMouseEnter={(): void => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-id={TestIDs.Review.Card.row(row.key)}
    >
      <ReviewCheckboxColumn className={cn(textColorClass)}>
        <div>
          <Checkbox
            containerProps={{ className: 'items-start' }}
            labelProps={{ className: cn('-mt-px leading-[1.3rem]', colorClass) }}
            label={row.label}
            disabled={rowDisabled || loading}
            id={`${entityId}-${row.key}`}
            data-track='Review / Toggle checkbox'
            checked={checked}
            onCheckedChange={() => updateCheckedState(row.key)}
          />
          {row.description && (
            <Typography variant='aLabelSmall' className='text-text-secondary'>
              {row.description}
            </Typography>
          )}
        </div>
      </ReviewCheckboxColumn>

      <div
        className={cn('grow px-1', textColorClass, {
          '[&>a]:text-text-secondary': checked
        })}
      >
        {matchingAlert && <ReviewAlert alert={matchingAlert} />}
        {row.children}
      </div>
      {inlineCommentsEnabled && (
        <ReviewCardInlineComments
          row={row}
          inlineCommentsMap={inlineCommentsMap}
          setInlineCommentsMap={setInlineCommentsMap}
          isHovered={isHovered}
        />
      )}
    </div>
  )
}
