import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconSpark = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m7 3.882-.044.12a5 5 0 0 1-2.954 2.954L3.882 7l.12.044a5 5 0 0 1 2.954 2.954l.044.12.045-.12a5 5 0 0 1 2.954-2.954l.12-.044-.12-.044a5 5 0 0 1-2.954-2.954L7 3.882Zm-1.92-.575L6.063.653h1.875l.982 2.654a3 3 0 0 0 1.773 1.773l2.654.982v1.876l-2.654.982a3 3 0 0 0-1.773 1.773l-.982 2.654H6.063l-.983-2.654A3 3 0 0 0 3.308 8.92L.653 7.938V6.062l2.655-.982A3 3 0 0 0 5.08 3.307ZM19.5 11.362c-.315.438-.7.823-1.138 1.138.439.315.823.7 1.138 1.138a5 5 0 0 1 1.138-1.138 5.001 5.001 0 0 1-1.138-1.138Zm-1.244-1.88.306-.829h1.876l.307.83a3 3 0 0 0 1.773 1.772l.83.307v1.876l-.83.307a3 3 0 0 0-1.773 1.772l-.307.83h-1.875l-.307-.83a3 3 0 0 0-1.773-1.772l-.83-.307v-1.876l.83-.307a3 3 0 0 0 1.773-1.772ZM11.5 18.362c-.315.438-.7.823-1.138 1.138.439.315.823.7 1.138 1.138a5 5 0 0 1 1.138-1.138 5.001 5.001 0 0 1-1.138-1.138Zm-1.245-1.88.307-.829h1.876l.307.83a3 3 0 0 0 1.773 1.772l.83.307v1.876l-.83.307a3 3 0 0 0-1.773 1.772l-.307.83h-1.876l-.306-.83a3 3 0 0 0-1.773-1.772l-.83-.307v-1.876l.83-.307a3 3 0 0 0 1.772-1.772Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconSpark.displayName = 'IconSpark'
