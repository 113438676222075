import { type MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { objectKeys } from '@strise/ts-utils'
import { ContentLanguage } from '@strise/types'
import type { ComboboxItem } from '@strise/ui-components'
import { useCurrentUserEnabledContentLanguages } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { enumOptions } from '~/utils/enum'

export const countryLabels: { [key in ContentLanguage]?: MessageDescriptor } = {
  [ContentLanguage.Norwegian]: defineMessage({ message: 'Norway' }),
  [ContentLanguage.Swedish]: defineMessage({ message: 'Sweden' }),
  [ContentLanguage.Danish]: defineMessage({ message: 'Denmark' }),
  [ContentLanguage.Finnish]: defineMessage({ message: 'Finland' }),
  [ContentLanguage.English]: defineMessage({ message: 'United Kingdom' }),
  [ContentLanguage.French]: defineMessage({ message: 'France' }),
  [ContentLanguage.German]: defineMessage({ message: 'Germany' }),
  [ContentLanguage.Spanish]: defineMessage({ message: 'Spain' }),
  [ContentLanguage.Belgian]: defineMessage({ message: 'Belgium' }),
  [ContentLanguage.Dutch]: defineMessage({ message: 'Netherlands' }),
  [ContentLanguage.Luxembourgish]: defineMessage({ message: 'Luxembourg' })
}

export const useCountryOptions = (): Array<ComboboxItem<ContentLanguage>> => {
  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()

  const filteredLanguages = objectKeys(countryLabels).filter((lang) => enabledContentLanguages.includes(lang))

  return enumOptions(filteredLanguages, countryLabels)
}
