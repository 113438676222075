import { type ApolloCache } from '@apollo/client/index.js'
import { type ReviewSectionKind } from '@strise/types'
import { ReviewCompanyDataFragmentDoc } from '~/graphqlOperations'
import { type ReviewCompanyDataFragment } from '~/graphqlTypes'

export const updateReviewCache = ({
  cache,
  entityId,
  excludeSections,
  includeSections,
  updateFn
}: {
  cache: ApolloCache<unknown>
  entityId: string
  excludeSections: ReviewSectionKind[],
  includeSections: ReviewSectionKind[],
  updateFn: (data: ReviewCompanyDataFragment) => ReviewCompanyDataFragment
}): void => {
  const reviewDataId = cache.identify({
    __typename: 'ReviewCompanyData',
    id: entityId,
    includeSections: includeSections,
    excludeSections: excludeSections
  })

  const reviewData = cache.readFragment<ReviewCompanyDataFragment>({
    id: reviewDataId,
    fragment: ReviewCompanyDataFragmentDoc,
    fragmentName: 'ReviewCompanyData'
  })

  if (!reviewData) return

  const updatedReviewData = updateFn(reviewData)

  cache.writeFragment({
    id: reviewDataId,
    fragment: ReviewCompanyDataFragmentDoc,
    fragmentName: 'ReviewCompanyData',
    data: updatedReviewData
  })
}
