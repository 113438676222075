import { t } from '@lingui/macro'
import { Typography } from '@strise/ui-components'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import * as React from 'react'
import { type EntityLikeMetaFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

export const UndoMergeDialog = ({
  entity,
  handleCancel,
  handleUndoMerge,
  loading
}: {
  entity: EntityLikeMetaFragment
  handleCancel: () => void
  handleUndoMerge: (mergeEntityId: string) => void
  loading: boolean
}): React.ReactNode => {
  return (
    <ConfirmDialog
      isOpen={true}
      onClose={handleCancel}
      onConfirm={() => handleUndoMerge(entity.id)}
      title={t`Reset changes`}
      confirmText={t`Reset changes`}
      cancelText={t`Cancel`}
      onCancel={handleCancel}
      confirmButtonProps={{
        palette: 'primary',
        'data-track': 'Edit ownership / Undo merge / Confirm',
        'data-id': TestIDs.SidePanel.Ownerships.undoMergeConfirmButton
      }}
      cancelButtonProps={{ 'data-track': 'Edit ownership / Undo merge / Cancel' }}
      loading={loading}
      contentMaxWidth={800}
    >
      <Typography variant='subtitle2'>{t`Are you sure you want to reset the changes to this owner?`}</Typography>
    </ConfirmDialog>
  )
}
