import { LineLoader, Skeleton, cn } from '@strise/ui-components'
import * as React from 'react'

const SidepanelHeaderCardLoader = (): React.ReactNode => (
  <div className='mb-2 bg-white p-4'>
    <div className='mb-2 flex justify-between'>
      <div className='flex items-center space-x-2'>
        <Skeleton className='size-[24px]' />
        <Skeleton className='h-8 w-28' />
        <Skeleton className='h-6 w-14' variant='circle' />
      </div>
      <div className='flex items-center space-x-4'>
        <Skeleton className='size-8' />
        <Skeleton className='size-8' />
        <Skeleton className='size-8' />
        <Skeleton className='h-10 w-32' />
      </div>
    </div>

    <div className='flex space-x-2'>
      <Skeleton className='h-16 w-full' />
      <Skeleton className='h-16 w-full' />
      <Skeleton className='h-16 w-full' />
      <Skeleton className='h-16 w-full' />
    </div>
  </div>
)

const MetaDetailLoader = ({
  className,
  customContent,
  fullWidth
}: {
  className?: string
  customContent?: React.ReactNode
  fullWidth?: boolean
}): React.ReactNode => (
  <div className={cn('flex min-h-[52px] items-center', className)}>
    <div className='mr-3 legacy-xs:w-1 legacy-md:w-1/3'>
      <Skeleton className='h-5 w-28' />
    </div>
    <div
      className={cn(
        'flex items-center legacy-xs:w-1',
        fullWidth ? 'justify-between legacy-md:w-2/3' : 'legacy-md:w-1/2'
      )}
    >
      {customContent || (
        <>
          <Skeleton className='mr-2 size-7' variant='circle' />
          <Skeleton className='h-5 w-48' />
        </>
      )}
    </div>
  </div>
)

const SidepanelMetaLoader = (): React.ReactNode => (
  <div className='mb-2 bg-white p-4'>
    <div className='mb-4 flex flex-col'>
      <MetaDetailLoader />
      <MetaDetailLoader
        customContent={
          <>
            <Skeleton className='size-7' variant='circle' />
            <Skeleton className='-ml-1 size-7' variant='circle' />
            <Skeleton className='-ml-1 size-7' variant='circle' />
          </>
        }
      />

      <MetaDetailLoader />
      <MetaDetailLoader
        customContent={
          <>
            <Skeleton className='mr-2 h-8 w-28' variant='circle' />
            <Skeleton className='mr-2 h-8 w-28' variant='circle' />
          </>
        }
      />

      <MetaDetailLoader
        className='mt-4'
        fullWidth
        customContent={
          <>
            <div className='flex items-center'>
              <Skeleton className='mr-5 size-12' variant='circle' />
              <Skeleton className='mr-2 h-5 w-52' />
            </div>
            <Skeleton className='h-12 w-[164px]' />
          </>
        }
      />
    </div>
  </div>
)

export const SidepanelCardLoader = (): React.ReactNode => (
  <div className='p-4'>
    <LineLoader />
  </div>
)

export const SidepanelContentLoader = (): React.ReactNode => {
  return (
    <>
      <SidepanelHeaderCardLoader />
      <SidepanelMetaLoader />
    </>
  )
}
