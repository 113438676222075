import { type PortfolioHealthReviewMetric } from '@strise/types'
import {
  Card,
  CardContent,
  CardFooter,
  IconArrowDownSmall,
  IconArrowUpSmall,
  Tooltip,
  Typography,
  cn
} from '@strise/ui-components'
import React from 'react'

interface PortfolioHealthReviewMetricCardProps {
  metric: PortfolioHealthReviewMetric
  subtitle?: string
  teamText?: string
  title: string
  tooltipText: string
  tooltipTextFooter?: string
}

export const PortfolioHealthReviewMetricCard = ({
  metric,
  subtitle,
  teamText,
  title,
  tooltipText,
  tooltipTextFooter
}: PortfolioHealthReviewMetricCardProps): React.ReactNode => {
  const { teamCount, userCount, userPercentageChange } = metric

  const getUserPercentageChangeColor = () => {
    if (userPercentageChange === null || userPercentageChange === undefined) {
      return ''
    }
    return userPercentageChange.startsWith('−') ? 'text-semantic-danger-shade-50' : 'text-blue-60'
  }

  const getUserPercentageIcon = () => {
    if (userPercentageChange === null || userPercentageChange === undefined) {
      return null
    }
    return userPercentageChange.startsWith('−') ? <IconArrowDownSmall size='md' /> : <IconArrowUpSmall size='md' />
  }

  const userPercentageChangeColor = getUserPercentageChangeColor()
  const userPercentageIcon = getUserPercentageIcon()

  return (
    <Card
      className='size-full border-gray-10 bg-gradient-to-b from-white from-50% to-blue-5 to-50% hover:border-gray-10 active:border-gray-10 p-0'
      variant='contained'
      highlight='bottom'
    >
      <Tooltip content={tooltipText} side='top'>
        <CardContent className='flex flex-col justify-start items-baseline rounded-b-md bg-white w-full py-8'>
          <Typography variant='subtitle2'>{title}</Typography>
          <div className='flex flex-row justify-start items-baseline gap-4 pt-2.5'>
            <Typography variant='h1'>{userCount}</Typography>
            <Typography
              variant='aLabelBold'
              component='span'
              className={cn(userPercentageChangeColor, 'flex items-center')}
            >
              {userPercentageChange}
              {userPercentageIcon}
            </Typography>
          </div>
          <Typography variant='body1' className='text-gray-60 min-h-6 flex items-baseline'>
            {subtitle}
          </Typography>
        </CardContent>
      </Tooltip>
      <Tooltip content={tooltipTextFooter}>
        <CardFooter className='gap-x-2'>
          <Typography variant='aLabelBold'>{teamCount}</Typography>
          <Typography variant='body1'>{teamText}</Typography>
        </CardFooter>
      </Tooltip>
    </Card>
  )
}
