import { createContext, useContext } from '@strise/react-utils'
import type * as React from 'react'

interface ContentViewContextProps {
  contentRef: React.MutableRefObject<HTMLDivElement | null> | null
  isLargeScreen: boolean
  isMediumScreen: boolean
  isSmallScreen: boolean
}

export const ContentViewContext = createContext<ContentViewContextProps>()

export const useScrollContentTo = (): ((opts: ScrollToOptions) => void) => {
  const { contentRef } = useContext(ContentViewContext)

  return (opts: ScrollToOptions) => {
    if (!contentRef?.current) return

    contentRef.current.scrollTo(opts)
  }
}
