import type { ApolloCache } from '@apollo/client'
import { type ReviewIdVerificationStatus, ReviewSectionKind } from '@strise/types'
import { updateReviewCache } from '~/components/Review/reviewCacheUtils'
import { IdvRolesDocument } from '~/graphqlOperations'
import {
  type IdvRequestFragment,
  type IdvRoleFragment,
  type IdvRolesQuery,
  type PersonBaseFragment,
  type ReviewCompanyDataFragment,
  type ReviewIdvRoleValueFragment
} from '~/graphqlTypes'
import { team } from '~/state'

export const updateIdvRolesCache = ({
  cache,
  companyId,
  idvRequest,
  person
}: {
  cache: ApolloCache<unknown>
  companyId: string
  idvRequest: IdvRequestFragment
  person: PersonBaseFragment
}) => {
  const idvRolesQueryData = cache.readQuery<IdvRolesQuery>({
    query: IdvRolesDocument,
    variables: { entity: companyId, team: team() }
  })

  if (!idvRolesQueryData) return

  const idvRoles = idvRolesQueryData.company.idvRoles

  const updatedIdvRoles = idvRoles.map((role) => {
    if (role.person.id === person.id) {
      return {
        ...role,
        idvRequest
      }
    }

    return role
  })

  cache.writeQuery({
    query: IdvRolesDocument,
    variables: { entity: companyId, team: team() },
    data: {
      company: {
        ...idvRolesQueryData.company,
        idvRoles: updatedIdvRoles
      }
    }
  })
}

export const updateReviewIdvRolesCache = ({
  cache,
  companyId,
  idvRoles
}: {
  cache: ApolloCache<unknown>
  companyId: string
  idvRoles: IdvRoleFragment[]
}) => {
  updateReviewCache({
    cache,
    entityId: companyId,
    includeSections: [ReviewSectionKind.ManagementAndRightsHolders],
    excludeSections: [],
    updateFn: (reviewCompany: ReviewCompanyDataFragment): ReviewCompanyDataFragment => {
      if (!reviewCompany.idvRoles) return reviewCompany

      const updatedReviewCompany: ReviewCompanyDataFragment = {
        ...reviewCompany,
        idvRoles: reviewCompany.idvRoles.map((reviewIdvRole): ReviewIdvRoleValueFragment => {
          if (!reviewIdvRole.value) return reviewIdvRole

          const matchingIdvRequest = idvRoles.find(
            (idvRole) => idvRole.person.id === reviewIdvRole.value?.person.id
          )?.idvRequest

          if (!matchingIdvRequest) return reviewIdvRole

          return {
            ...reviewIdvRole,
            value: {
              ...reviewIdvRole.value,
              idvRequest: {
                ...matchingIdvRequest,
                status: matchingIdvRequest.status as unknown as ReviewIdVerificationStatus,
                __typename: 'ReviewIdvRequest' as const
              }
            }
          }
        })
      }

      return updatedReviewCompany
    }
  })
}
