import { cn } from '../../utils/className'
import { lineLoaderVariants } from '../variants/lineLoaderVariants'
import { type DivProps } from '@strise/react-utils'
import { type VariantProps } from 'class-variance-authority'
import * as React from 'react'

export interface LineLoaderProps extends DivProps, VariantProps<typeof lineLoaderVariants> {}

export const LineLoader = React.forwardRef<HTMLDivElement, LineLoaderProps>(
  ({ className, palette, size, ...props }, ref) => {
    return (
      <div ref={ref} className={cn(lineLoaderVariants({ palette, size }), className)} role='status' {...props}>
        <span className='sr-only'>Loading...</span>
      </div>
    )
  }
)

LineLoader.displayName = 'LineLoader'
