import { t } from '@lingui/macro'
import { type PortfolioHealthRiskDistribution } from '@strise/types'
import { Card, CardContent, PieChartDonut, Typography } from '@strise/ui-components'
import * as React from 'react'

interface PortfolioHealthRiskAssessmentSectionProps {
  riskDistribution: PortfolioHealthRiskDistribution
}

const RISK_COLORS = {
  LOW: 'var(--chart-1)',
  MEDIUM: 'var(--chart-2)',
  HIGH: 'var(--chart-3)'
} as const

/**
 * Portfolio Health Risk Assessment Section Component
 *
 * Displays a donut chart showing the distribution of risk levels in the portfolio
 * with their respective percentages and counts.
 */
export const PortfolioHealthRiskAssessmentSection = ({
  riskDistribution
}: PortfolioHealthRiskAssessmentSectionProps) => {
  // Transform risk distribution data for chart consumption
  const riskDistributionData = riskDistribution.segments.map((segment) => ({
    risk: segment.name.toLowerCase(),
    total: Number(segment.count)
  }))

  // Chart configuration with risk levels and their visual representation
  const riskConfig = {
    total: {
      label: t`Total`
    },
    low: {
      label: `${t`Low Risk`} (${getSegmentPercentage(riskDistribution.segments, 'Low')})`,
      color: RISK_COLORS.LOW
    },
    medium: {
      label: `${t`Medium Risk`} (${getSegmentPercentage(riskDistribution.segments, 'Medium')})`,
      color: RISK_COLORS.MEDIUM
    },
    high: {
      label: `${t`High Risk`} (${getSegmentPercentage(riskDistribution.segments, 'High')})`,
      color: RISK_COLORS.HIGH
    }
  }

  return (
    <Card size='sm' className='p-0 hover:border-gray-10 active:border-gray-10' palette='white'>
      <CardContent className='px-4 py-6'>
        <Typography variant='subtitle2' className='pb-4'>{t`Portfolio risk assessment`}</Typography>
        <PieChartDonut
          config={riskConfig}
          data={riskDistributionData}
          dataKey='total'
          nameKey='risk'
          className='h-52 xl:h-[12.5rem]'
          innerRadius={30}
          outerRadius={60}
          showLegend
        />
      </CardContent>
    </Card>
  )
}

const getSegmentPercentage = (segments: PortfolioHealthRiskDistribution['segments'], name: string) =>
  segments.find((s) => s.name === name)?.percentage ?? '0%'
