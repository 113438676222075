/** @deprecated: use tailwind h-header */
export const HEADER_HEIGHT = 64
/** @deprecated: use tailwind h-sub-header */
export const SUB_HEADER_HEIGHT = 56
export const SIDEPANEL_WIDTH = {
  open: '752px',
  openExpanded: 'calc(100% - 150px)',
  max: '1180px',
  twOpen: {
    lg: 'legacy-lg:w-[752px]'
  },
  twOpenExpanded: {
    lg: 'legacy-lg:w-[calc(100%-150px)]'
  }
}

export const MAX_HIGHLIGHTED_FINANCIALS = 6

export const QUERY_PARAMS = {
  team: 'team',
  api: 'api',
  sidepanelEntityId: 'sidepanel-id',
  sidepanelExpanded: 'sidepanel-expanded',
  eventId: 'event-id',
  eventEntityId: 'event-context',
  eventMentions: 'event-mentions',
  sidepanelTab: 'sidepanel-tab',
  settingsTab: 'settings-tab',
  conflictId: 'conflict-id',
  sidepanelOwnerNodeId: 'sidepanel-owner-node-id'
} as const

// URL search params
export const QUERY_PARAMS_LEGACY = {
  mentions: QUERY_PARAMS.eventMentions,
  sidepanelTab: QUERY_PARAMS.sidepanelTab,
  settingsTab: QUERY_PARAMS.settingsTab
}

// The numbers are versioning and should never be reset except when incrementing localStorageVersion
export const STORAGE_KEYS = {
  localStorageVersion: 'localStorageVersion',
  portfolioCompaniesColumns: 'portfolioCompaniesColumns',
  portfolioRowsPerPage: 'portfolioRowsPerPage',
  recentlyVisitedEntities: 'recentlyVisitedEntities',
  recentlyUsedTags: 'recentlyUsedTags',
  reviewState: 'reviewState3',
  reviewState2: 'reviewState6',
  reviewStatePrivatePersons: 'reviewStatePrivatePersons',
  spoofUserId: 'spoofUserId',
  spoofUserUrl: 'spoofUserUrl',
  growProcessedCompanies: 'growProcessedCompanies',
  team: 'teamId'
}

// Headers
export const HEADER_REQUEST_ID: string = 'X-Request-ID'
