import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconRelations = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M16 4.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm-2 0c0 .929.362 1.773.952 2.4l-.378 1.133a4.978 4.978 0 0 0-2.913.547L8.69 5.94a3.5 3.5 0 1 0-2.32 1.95l3.18 2.828a4.972 4.972 0 0 0-.545 2.032l-1.755.585a3.5 3.5 0 1 0 .663 2.941l1.851-.617a4.996 4.996 0 0 0 5.848 2.075l1.489 1.489A3.004 3.004 0 0 0 20 23a3 3 0 1 0-.777-5.898l-1.171-1.172A4.977 4.977 0 0 0 19 13a4.988 4.988 0 0 0-1.675-3.734l.425-1.275A3.5 3.5 0 1 0 14 4.5Zm0 5.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm-8 5.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM19 20a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM5.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconRelations.displayName = 'IconRelations'
