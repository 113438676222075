import { hexToRGB } from '../utils/color'
import { getByPath } from '../utils/object'
import { type StyleOptions, type Theme, style } from '@mui/system'
import { isString } from 'lodash-es'

export const getPalettePath = (str: string | undefined): string[] => {
  if (!str) return []
  return str
    .replace(/^(primary|secondary|semantic\.[a-z]+|accent\.[a-z]+)\.?(\d+)?$/, (_, key: string, shade: string) => {
      if (shade) return `${key}.shade.${shade}`
      return `${key}.main`
    })
    .split('.')
}

export const getColor = (palette: Record<string, object>, paletteKey: string): string => {
  const [key, alpha = ''] = paletteKey.split(':')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const color = getByPath(palette, getPalettePath(key))

  if (color) {
    if (alpha) {
      const rgb = hexToRGB(color)
      if (rgb) return `rgba(${rgb.join(',')}, ${alpha})`
    }
    return color
  }

  return paletteKey
}

export const paletteStyle = (
  opts: Omit<StyleOptions<string>, 'transform'> & {
    transform?: (value: string, color: string, theme: Theme) => string
  }
): { (props: { theme: Theme }): unknown; filterProps: string[] } => {
  const styleFn = (props: { theme: Theme }): unknown =>
    style({
      ...opts,
      // @ts-expect-error
      transform: (value) => {
        if (!isString(value)) return null
        const color = getColor(props.theme.palette, value)
        if (opts.transform) return opts.transform(value, color, props.theme)
        return color
      }
    })(props)

  // eslint-disable-next-line functional/immutable-data
  styleFn.filterProps = [opts.prop]

  return styleFn
}
