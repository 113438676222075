import { AdverseFlag, formatDate } from '@strise/app-shared'
import { Typography, cn } from '@strise/ui-components'
import React from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { EventLink } from '~/components/EventLink'
import { type AdverseFlagFragment } from '~/graphqlTypes'

export const Flag = ({ entityId, flag }: { entityId: string; flag: AdverseFlagFragment }): React.ReactNode => {
  const isNotThisCompany = entityId !== flag.entity.id
  return (
    <div className='flex'>
      <AdverseFlag severity={flag.severity} size='xl' className='mr-2 mt-1 shrink-0' />
      <div>
        <div className='mb-1 flex items-center'>
          {isNotThisCompany && (
            <EntityLink
              entity={flag.entity}
              noFlags
              noLifeStatus
              color='text.primary'
              className='text-text-primary underline'
            />
          )}

          <Typography variant='aLabelSmall' className={cn(isNotThisCompany && 'ml-2')}>
            {formatDate(flag.date, { relative: true })}
          </Typography>
        </div>
        {flag.event ? (
          <EventLink entityId={flag.entity.id} eventId={flag.event.id} className='block text-text-link'>
            {flag.reason}
          </EventLink>
        ) : (
          <div>
            <Typography>{flag.reason}</Typography>
          </div>
        )}
      </div>
    </div>
  )
}
