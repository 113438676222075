import { AppSharedContextProvider } from '@strise/app-shared'
import * as React from 'react'
import { EntityIdLink } from '~/components/EntityLink/EntityIdLink'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { EventLink } from '~/components/EventLink'
import { useDisplayLanguage } from '~/contexts/DisplayLanguageContext/displayLanguageContextUtils'

export const InitAppSharedContext = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const language = useDisplayLanguage()

  const value = React.useMemo(
    () => ({
      EntityLink,
      EntityIdLink,
      language,
      EventLink
    }),
    [language]
  )

  return <AppSharedContextProvider value={value}>{children}</AppSharedContextProvider>
}
