import * as React from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { SidepanelOwnershipsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelOwnershipsCard'
import { SidepanelPersonHeaderCard } from '~/components/Sidepanel/SidepanelCards/SidepanelPersonHeaderCard'
import { SidepanelRolesCard } from '~/components/Sidepanel/SidepanelCards/SidepanelRolesCard'
import { SidepanelSanctionsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelSanctionsCard'
import { SidepanelSimilarEntities } from '~/components/Sidepanel/SidepanelCards/SidepanelSimilarEntities'
import { SidepanelTableCard } from '~/components/Sidepanel/SidepanelTableCard'
import { type TabRefs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { type SidepanelGlobalPersonFragment } from '~/graphqlTypes'
import { hasEntityAccess } from '~/utils/entity'
import { SidepanelTab } from '~/utils/urls'

export const SidepanelGlobalPersonLayout: React.FC<{
  globalPerson: SidepanelGlobalPersonFragment
  tabRefs: TabRefs
}> = ({ globalPerson, tabRefs }) => {
  const [showOriginal, setShowOriginal] = useState(false)

  return (
    <>
      <Helmet>
        <title>{globalPerson.name}</title>
      </Helmet>
      <SidepanelPersonHeaderCard ref={tabRefs[SidepanelTab.Person]} person={globalPerson} />
      {hasEntityAccess(globalPerson) && (
        <>
          <SidepanelSimilarEntities />
          <SidepanelTableCard
            entity={globalPerson}
            trackId='Global person information'
            table={globalPerson.personInformation}
          />
          <SidepanelSanctionsCard entity={globalPerson} />
          <SidepanelRolesCard
            entity={globalPerson}
            ref={tabRefs[SidepanelTab.Roles]}
            sidepanelTab={SidepanelTab.Roles}
          />
          <SidepanelOwnershipsCard
            entity={globalPerson}
            ref={tabRefs[SidepanelTab.Ownership]}
            sidepanelTab={SidepanelTab.Ownership}
            showOriginal={showOriginal}
            setShowOriginal={setShowOriginal}
          />
        </>
      )}
    </>
  )
}
