import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import React from 'react'
import { ReviewEvents } from '~/components/Review/items/ReviewEvents'
import { ReviewPepsWithDisposition } from '~/components/Review/items/ReviewPepsWithDisposition'
import { ReviewSanctions } from '~/components/Review/items/ReviewSanctions'
import { type ReviewPrivatePersonDataFragment } from '~/graphqlTypes'
import { type ReviewState } from '~/utils/reviewUtils'

export enum PrvatePersonReviewRowKind {
  Peps = 'PEPS',
  Sanctions = 'SANCTIONS',
  AdverseMediaScreening = 'ADVERSE_MEDIA_SCREENING'
}

export type PrivatePersonReviewRowType = {
  children: React.ReactNode
  description?: string | null
  key: string
  kind: PrvatePersonReviewRowKind
  label: string
}

interface PrivatePersonReviewSection {
  rows: PrivatePersonReviewRowType[]
  title: string
}

export const extractReviewSections = ({
  reviewPrivatePersonData,
  reviewState,
  setReviewState
}: {
  reviewPrivatePersonData: ReviewPrivatePersonDataFragment | null | undefined
  reviewState: ReviewState
  setReviewState: SetStateFn<ReviewState>
}): PrivatePersonReviewSection[] => {
  return [
    {
      title: t`PEPs and Sanctions`,
      rows: [
        {
          key: PrvatePersonReviewRowKind.Peps,
          kind: PrvatePersonReviewRowKind.Peps,
          label: t`PEPs`,
          children: reviewPrivatePersonData?.peps && <ReviewPepsWithDisposition items={reviewPrivatePersonData.peps} />
        },
        {
          key: PrvatePersonReviewRowKind.Sanctions,
          kind: PrvatePersonReviewRowKind.Sanctions,
          label: t`Sanctions`,
          children: reviewPrivatePersonData?.sanctions && (
            <ReviewSanctions items={[reviewPrivatePersonData.sanctions]} />
          )
        }
      ]
    },
    {
      title: t`Adverse Media Screening`,
      rows: [
        {
          key: PrvatePersonReviewRowKind.AdverseMediaScreening,
          kind: PrvatePersonReviewRowKind.AdverseMediaScreening,
          label: t`AMS`,
          children: reviewPrivatePersonData?.ams && (
            <ReviewEvents
              entityId={reviewPrivatePersonData.id}
              items={reviewPrivatePersonData.ams}
              reviewState={reviewState}
              setReviewState={setReviewState}
            />
          )
        }
      ]
    }
  ]
}
