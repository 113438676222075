import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconWikipedia = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m23 6.034-.006-.02-4.394-.012v.093l-.004.343c.373.008 1.329.17 1.628.89l-4.209 8.898c-.076-.164-.394-.816-.775-1.597-.645-1.323-1.471-3.018-1.612-3.34l2.036-3.867c.42-.588 1.224-.968 2-.995l-.002-.405-4.227-.003a2.312 2.312 0 0 0-.005.184 2.1 2.1 0 0 1-.01.24c1.192.03 1.619.275 1.333.927a84.49 84.49 0 0 1-1.655 3.18 55.452 55.452 0 0 1-.77-1.548 56.41 56.41 0 0 0-.877-1.753c-.285-.58.343-.806 1.006-.823V6.02L7.56 6.006c.002.115 0 .2-.002.28-.001.052-.003.102-.003.158.683.009 1.568.076 1.921.62.26.462.852 1.682 1.41 2.83.452.93.88 1.811 1.092 2.208-.254.447-.662 1.152-1.088 1.888-.583 1.007-1.2 2.073-1.502 2.616a339.698 339.698 0 0 1-1.815-3.834c-.987-2.103-2.059-4.386-2.5-5.259-.293-.661.837-1.043 1.49-1.071L6.57 6H1l.003.234.004.215c.903-.007 2.223 1.351 2.6 2.196.755 1.607 1.523 3.21 2.29 4.812a684.084 684.084 0 0 1 2.63 5.536c0 .006.506.012.511.001.652-1.221 1.372-2.481 2.09-3.738.447-.784.893-1.566 1.322-2.337l2.818 6.078s.467.004.47.002c.924-1.936 2.087-4.407 3.116-6.592.958-2.036 1.8-3.824 2.222-4.698.356-.774.953-1.246 1.917-1.273L23 6.034Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconWikipedia.displayName = 'IconWikipedia'
