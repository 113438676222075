import { Typography } from '@strise/ui-components'
import React from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { PepPersonAddressInfo, PepPersonContent } from '~/components/PepAndSanctions/PepPersonContent'
import { type BaseEntityLikeFragment, type PepInfoFragment } from '~/graphqlTypes'

export const PepsWithEntity = ({
  entity,
  entityName,
  pepInfo
}: {
  entity: BaseEntityLikeFragment | null | undefined
  entityName: string
  pepInfo: PepInfoFragment
}): React.ReactNode => {
  return (
    <div className='flex w-full gap-2'>
      <div className='min-w-[220px]'>
        {entity ? <EntityLink entity={entity} /> : <Typography>{entityName}</Typography>}
        <PepPersonAddressInfo address={pepInfo.address} />
      </div>
      <PepPersonContent
        className='mt-1'
        pepInfos={[pepInfo]}
        person={null}
        showSystemSuggestedFalse={false}
        associatedCompanies={null}
        currentCompanyId={null}
        enableEdit={false}
      />
    </div>
  )
}
