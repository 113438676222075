import { useUserId } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { reduceFlatten } from '@strise/ts-utils'
import { DisplayLanguage } from '@strise/types'
import React from 'react'
import {
  CurrentUserSettingsContext,
  useCurrentUserEnabledContentLanguages
} from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useDisplayLanguage } from '~/contexts/DisplayLanguageContext/displayLanguageContextUtils'
import { useFinancialFieldsQuery, useUpdateUserSettingsMutation } from '~/graphqlOperations'
import { type FinancialFieldsQuery } from '~/graphqlTypes'
import { financialFieldsMap } from '~/state'

export const useSaveUserTimeZone = (): void => {
  const userId = useUserId()
  const { settings } = useContext(CurrentUserSettingsContext)
  const [update] = useUpdateUserSettingsMutation()

  React.useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const defaultTimeZone = 'UTC'

    // Validate timezone
    const isValidTimeZone = (tz: string): boolean => {
      try {
        Intl.DateTimeFormat(undefined, { timeZone: tz })
        return true
      } catch (e) {
        return false
      }
    }
    // eslint-disable-next-line functional/no-let
    let finalTimeZone = defaultTimeZone

    // First try to use user's timezone
    if (userTimeZone && isValidTimeZone(userTimeZone)) {
      finalTimeZone = userTimeZone
      // If user's timezone is invalid, try to use current settings
    } else if (settings.timeZone && isValidTimeZone(settings.timeZone)) {
      finalTimeZone = settings.timeZone
    }

    // Skip if no change is needed
    if (finalTimeZone === settings.timeZone && isValidTimeZone(settings.timeZone)) return

    update({
      variables: {
        user: userId,
        input: { application: { timeZone: finalTimeZone } }
      }
    })
  }, [settings.timeZone, userId])
}

export const useFetchUserFinancialFields = (): boolean => {
  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()
  const [loading, setLoading] = React.useState(true)

  const handleCompleted = (data: FinancialFieldsQuery): void => {
    const { financialFields } = data

    if (!financialFields.length) return

    const map = financialFields
      .map((financialField) => ({
        [financialField.id]: financialField
      }))
      .reduce(reduceFlatten, {})

    financialFieldsMap(map)
    setLoading(false)
  }

  useFinancialFieldsQuery({
    variables: { countries: enabledContentLanguages },
    onCompleted: handleCompleted
  })

  return loading
}

export const useHelpCenterLink = (): 'https://help.strise.ai/nb/' | 'https://help.strise.ai/en/' => {
  const displayLanguage = useDisplayLanguage()

  const helpCenterLink =
    displayLanguage === DisplayLanguage.Norwegian ? 'https://help.strise.ai/nb/' : 'https://help.strise.ai/en/'

  return helpCenterLink
}
