import { t } from '@lingui/macro'
import { type Duration, type PortfolioHealthReviewMetrics } from '@strise/types'
import React from 'react'
import { PortfolioHealthReviewMetricCard } from '~/views/Home/PortfolioHealth/PortfolioHealthReviewMetricCard'
import { getDurationTitle } from '~/views/Home/PortfolioHealth/utils/durationUtils'

interface PortfolioHealthReviewMetricsSectionProps {
  duration: Duration
  reviewMetrics: PortfolioHealthReviewMetrics
}

export const PortfolioHealthReviewMetricsSection = ({
  duration,
  reviewMetrics
}: PortfolioHealthReviewMetricsSectionProps): React.ReactNode => {
  const title = getDurationTitle(duration)

  return (
    <div className='py-6'>
      <div className='grid grid-cols-1 lg:grid-cols-4 gap-6'>
        <PortfolioHealthReviewMetricCard
          metric={reviewMetrics.completedMetric}
          title={title}
          teamText={t`in entire team`}
          subtitle={t`Done by you`}
          tooltipText={t`This is the number of reviews that you have completed in the selected time period`}
          tooltipTextFooter={t`This is the number of reviews that your team has completed in the selected time period`}
        />
        <PortfolioHealthReviewMetricCard
          metric={reviewMetrics.pendingMetric}
          title={t`Pending reviews`}
          teamText={t`pending in entire team`}
          tooltipText={t`This is the number of reviews that are pending in the selected time period`}
          tooltipTextFooter={t`This is the number of reviews that your team has pending in the selected time period`}
        />
        <PortfolioHealthReviewMetricCard
          metric={reviewMetrics.highPriorityMetric}
          title={t`High priority reviews`}
          teamText={t`high priority in entire team`}
          tooltipText={t`This is the number of reviews that are marked as high priority in the selected time period`}
          tooltipTextFooter={t`This is the number of reviews that your team has marked as high priority in the selected time period`}
        />
        <PortfolioHealthReviewMetricCard
          metric={reviewMetrics.upcomingMetric}
          title={t`Upcoming reviews`}
          teamText={t`upcoming in entire team`}
          subtitle={t`Next month`}
          tooltipText={t`This is the number of reviews that are upcoming in the selected time period`}
          tooltipTextFooter={t`This is the number of reviews that your team has upcoming in the selected time period`}
        />
      </div>
    </div>
  )
}
