import { createContext } from '@strise/react-utils'
import { type OwnersState } from '~/components/Ownerships/ownershipChartUtils'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'

interface OwnershipsContextProps {
  above25: boolean
  danish: boolean
  editMode: boolean
  // Should add padding, make nodes wider, and hide "New" chips
  isExport: boolean
  noFlags: boolean
  noLink: boolean
  noStatus: boolean
  noSubsidiaryCount: boolean
  owners: OwnersState
  rootEntity: BaseEntityLikeFragment
  showOriginal: boolean
}

export const OwnershipsContext = createContext<OwnershipsContextProps>()
