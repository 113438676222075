import React from 'react'
import { Timespan } from '~/components/Timespan/Timespan'
import { type RoleMetaFragment } from '~/graphqlTypes'
import { CustomMeta } from '~/utils/apiTable/CustomMeta'
import { type handleEditRowFn } from '~/utils/apiTable/apiTableUtils'

export const Role = ({
  editMode,
  handleEdit,
  roleMeta
}: {
  editMode?: boolean
  handleEdit?: handleEditRowFn
  roleMeta: RoleMetaFragment
}): React.ReactNode => {
  return (
    <div className='grid w-full grid-cols-[1fr_1fr_80px] items-center justify-center'>
      <div className='w-1/2'>{roleMeta.roleTitle}</div>
      <div className='flex min-h-flags-chip w-1/2 items-center'>
        <Timespan isActive={roleMeta.isActive} timespan={roleMeta.prettyTimespan} duration={roleMeta.prettyDuration} />
      </div>
      <CustomMeta
        customMeta={roleMeta.customMeta}
        editMode={editMode}
        handleEdit={() => {
          handleEdit?.(null, {
            existingRole: true,
            type: 'EditRole',
            roleTitleId: roleMeta.roleTitleId,
            comment: roleMeta.customMeta?.comment
          })
        }}
      />
    </div>
  )
}
