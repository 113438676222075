import { IdNameChip } from '@strise/app-shared'
import { type SetStateFn } from '@strise/react-utils'
import * as React from 'react'
import { type RegionFragment } from '~/graphqlTypes'
import { GrowLocationFilter } from '~/views/Grow/components/Location/GrowLocationFilter'

export const GrowLocationSearch = ({
  locations,
  setLocations
}: {
  locations: RegionFragment[]
  setLocations: SetStateFn<RegionFragment[]>
}): React.ReactNode => {
  const handleDelete =
    (location: RegionFragment): (() => void) =>
    () => {
      setLocations((oldLocations) => oldLocations.filter((otherLocation) => otherLocation.id !== location.id))
    }

  return (
    <div>
      {!!locations.length && (
        <div className='flex flex-wrap gap-1'>
          {locations.map((location) => (
            <IdNameChip
              variant='outlined'
              palette='secondary'
              key={location.id}
              value={location}
              onDelete={handleDelete(location)}
            />
          ))}
        </div>
      )}

      <GrowLocationFilter locations={locations} setLocations={setLocations} />
    </div>
  )
}
