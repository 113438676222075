import { Divider, Skeleton, cn } from '@strise/ui-components'
import * as React from 'react'
import { REVIEW_CHECKBOX_COLUMN_WIDTH_CLASSES } from '~/utils/reviewUtils'

const CheckboxColumnSkeleton = (): React.ReactNode => {
  return <Skeleton className={cn('h-flags-chip', REVIEW_CHECKBOX_COLUMN_WIDTH_CLASSES)} />
}

export const RowValueSkeleton = (): React.ReactNode => {
  return (
    <>
      <div className='flex gap-4'>
        <CheckboxColumnSkeleton />
        <div className='flex w-full flex-col gap-4'>
          <Skeleton className='h-flags-chip w-full' />
          <Skeleton className='h-flags-chip w-full' />
        </div>
      </div>
      <Divider />
    </>
  )
}
