import { t } from '@lingui/macro'
import { type SetStateFn, setChildState } from '@strise/react-utils'
import { Alert, Button, Divider, Typography } from '@strise/ui-components'
import * as React from 'react'
import { ReviewRow } from '~/components/Review/ReviewRow'
import { ReviewSelectAllCheckboxSection } from '~/components/Review/ReviewSelectAllCheckboxSection'
import { extractReviewSections } from '~/components/Review/reviewPrivatePersonCardUtils'
import { useReviewPrivatePersonDataQuery } from '~/graphqlOperations'
import { type TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { RowValueSkeleton } from '~/loaders/ReviewCompanyCardLoader'
import { type ReviewState } from '~/utils/reviewUtils'
import { TestIDs } from '~/utils/testIDs'

interface PrivatePersonReviewCardContentProps {
  privatePersonId: string
  resetReviewState: () => void
  reviewState: ReviewState
  setReviewState: SetStateFn<ReviewState>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}
export const PrivatePersonReviewCardContent = ({
  privatePersonId,
  resetReviewState,
  reviewState,
  setReviewState,
  teamReviewSettings
}: PrivatePersonReviewCardContentProps) => {
  const { data, error, loading } = useReviewPrivatePersonDataQuery({
    variables: {
      id: privatePersonId
    }
  })

  const checkedMap = reviewState.checkedMap
  const inlineCommentsMap = reviewState.inlineCommentsMap
  const setCheckedMap = setChildState(setReviewState, 'checkedMap')
  const setInlineCommentsMap = setChildState(setReviewState, 'inlineCommentsMap')

  const reviewPrivatePersonData = data?.reviewPrivatePersonData

  // TODO: Separate sections as ams takes a long time to load
  const reviewSections = extractReviewSections({
    reviewPrivatePersonData: reviewPrivatePersonData,
    reviewState,
    setReviewState
  })

  const rowKeys = reviewSections.flatMap((section) => {
    return section.rows.filter((row) => !!row.children).map((row) => row.key)
  })

  const checkedCount = rowKeys.filter((key) => checkedMap[key]).length
  return (
    <div>
      <ReviewSelectAllCheckboxSection
        className='pb-0 pl-4'
        idSuffix='top'
        checkedCount={checkedCount}
        setCheckedMap={setCheckedMap}
        rowKeys={rowKeys}
        id={privatePersonId}
        errors={false}
        disabled={false}
        loading={loading}
      />
      {reviewSections.map((section) => {
        return (
          <div key={section.title}>
            <Typography className='mb-2 mt-4 px-4' variant='subtitle1' data-id={`Review / ${section.title}`}>
              {section.title}
            </Typography>
            <Divider />
            {error && (
              <div className='p-4'>
                <Alert variant='danger'>{t`Something went wrong. Please try again`}</Alert>
              </div>
            )}
            {loading && (
              <div className='flex flex-col gap-4 p-4'>
                {Array.from({ length: 2 }).map((_, rowIndex) => (
                  <RowValueSkeleton key={rowIndex} />
                ))}
              </div>
            )}
            {section.rows.map((row) => (
              <ReviewRow
                key={row.key}
                checkedMap={checkedMap}
                setCheckedMap={setCheckedMap}
                inlineCommentsMap={inlineCommentsMap}
                setInlineCommentsMap={setInlineCommentsMap}
                row={row}
                entityId={privatePersonId}
                errors={false}
                loading={loading}
                teamReviewSettings={teamReviewSettings}
                alerts={[]}
              />
            ))}
          </div>
        )
      })}
      <ReviewSelectAllCheckboxSection
        className='pb-0 pl-4'
        idSuffix='bottom'
        checkedCount={checkedCount}
        setCheckedMap={setCheckedMap}
        rowKeys={rowKeys}
        id={privatePersonId}
        errors={false}
        disabled={false}
        loading={loading}
      />
      <div className='flex justify-end px-4 items-center mt-4'>
        <Button variant='ghost' palette='secondary' onClick={resetReviewState} data-track='false' className='mr-1'>
          {t`Reset`}
        </Button>
        <Button
          className='px-10'
          variant='contained'
          palette='primary'
          data-track='false'
          data-id={TestIDs.Review.Card.submitReviewButton}
        >
          {t`Save Review`}
        </Button>
      </div>
    </div>
  )
}
