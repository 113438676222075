import { type RiskColor } from '@strise/types'
import { IconDotSmall, type IconProps, cn } from '@strise/ui-components'
import * as React from 'react'
import { riskColorClasses } from '~/utils/risk'

export const RiskLevelDot: React.FC<
  {
    color: RiskColor
  } & IconProps &
    React.RefAttributes<SVGSVGElement>
> = ({ className, color, ...props }) => {
  return <IconDotSmall className={cn('shrink-0', riskColorClasses[color].colorClass, className)} {...props} />
}
