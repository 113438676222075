import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconLetter = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M20 6H4v12h16V6ZM2 4v16h20V4H2Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m2.336 5.747 1.328-1.494L12 11.662l8.336-7.41 1.328 1.495L12 14.338l-9.664-8.59Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconLetter.displayName = 'IconLetter'
