import { SlideToast } from '../components/Toast/SlideToast'
import { toast } from '../components/Toast/toast'
import { type I18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { ButtonCountdown } from '@strise/ui-components-legacy'
import * as React from 'react'

interface UndoToastOpts {
  body?: React.ReactNode
  duration?: number
  i18n?: I18n
  label?: string
  onUndo?: (event: React.MouseEvent) => void
}

export const undoToast = ({ body, duration = 5000, i18n, label, onUndo }: UndoToastOpts = {}): void => {
  toast.custom(
    (item) => {
      const handleUndo = (event: React.MouseEvent): void => {
        if (onUndo) onUndo(event)
        toast.dismiss(item.id)
      }

      return (
        <SlideToast
          visible={item.visible}
          label={label}
          variant='success'
          onClose={() => toast.dismiss(item.id)}
          actions={
            <ButtonCountdown className='mr-2' countdown={duration} size='sm' onClick={handleUndo}>
              {i18n ? i18n._(defineMessage({ message: 'Undo' })) : 'Undo'}
            </ButtonCountdown>
          }
        >
          {body}
        </SlideToast>
      )
    },
    {
      duration
    }
  )
}
