import { t } from '@lingui/macro'
import { CompanyStatusDot, NationFlags } from '@strise/app-shared'
import { companyStatusTitles } from '@strise/app-shared/src/i18n'
import { useContext } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { type FieldValuePair } from '@strise/types'
import { Chip, Divider, Tooltip, Typography, type TypographyProps, cn } from '@strise/ui-components'
import { Box, type BoxProps } from '@strise/ui-components-legacy'
import * as React from 'react'
import { companyInPortfolio } from '~/components/CompanyStatus/companyStatusUtils'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import ConnectOwnershipButton from '~/components/Ownerships/ConnectOwnershipButton'
import { OwnershipSubsidiariesChip } from '~/components/Ownerships/OwnershipSubsidiariesChip'
import { OwnershipsContext } from '~/components/Ownerships/OwnershipsContext/OwnershipsContext'
import { OwnerActionDropdown } from '~/components/Ownerships/edit-owner/EditOwner'
import {
  OWNERSHIP_NODE_HEIGHT,
  OWNERSHIP_NODE_WIDTH,
  OWNERSHIP_NODE_Z_INDEX
} from '~/components/Ownerships/ownershipUtils'
import { type CustomMetaFragment, type OwnerChartNodeEntityFragment, type ShareholderFragment } from '~/graphqlTypes'
import { CustomMeta } from '~/utils/apiTable/CustomMeta'
import { useIsMergeableEntity } from '~/utils/entity'
import { getTitle } from '~/utils/enum'

const textProps: TypographyProps = {
  variant: 'body2'
}

// TODO - keeping as Box and BoxProps for now due to usage of `styled-components` in Owners component
interface OwnershipEntityProps extends BoxProps {
  children?: React.ReactNode
  customMeta?: CustomMetaFragment | null
  entity: ShareholderFragment | OwnerChartNodeEntityFragment | null | undefined
  fallbackName?: string
  isCustom: boolean
  meta?: FieldValuePair | null
  ownership: string
  subsidiariesCount?: boolean
  subsidiary?: boolean
  uncertain: boolean
}

const extractTooltipContent = (uncertain: boolean): string | undefined => {
  if (uncertain) return t`Uncertain ownership percentage`
}

const useNameMaxLength = (): 50 | 27 | 17 => {
  const { isExport, noFlags } = useContext(OwnershipsContext)

  if (isExport) return 50
  if (noFlags) return 27
  return 17
}

export const OwnershipEntity = React.forwardRef<HTMLDivElement, OwnershipEntityProps>(
  (
    {
      children,
      className,
      customMeta,
      entity,
      fallbackName,
      isCustom,
      meta,
      ownership,
      subsidiariesCount = false,
      subsidiary,
      uncertain,
      ...props
    },
    ref
  ) => {
    const { editMode, isExport, noFlags, noLink, noStatus, noSubsidiaryCount } = useContext(OwnershipsContext)

    const isMergeable = useIsMergeableEntity(entity)

    const name = entity?.name ?? fallbackName ?? 'Unknown'

    const maxLength = useNameMaxLength()
    const trimmedName = ellipsis(name, maxLength)

    const nameContent = <div className='flex-1 flex-nowrap'>{trimmedName}</div>

    return (
      <Box
        className={cn(
          'relative',
          uncertain
            ? 'border-dashed border-semantic-notice-main bg-semantic-notice-shade-10/20'
            : 'border border-divider bg-background-paper',
          isCustom ? 'border-dashed' : 'border',
          className
        )}
        ref={ref}
        style={{
          width: isExport ? OWNERSHIP_NODE_WIDTH.wide : OWNERSHIP_NODE_WIDTH.default,
          height: OWNERSHIP_NODE_HEIGHT,
          zIndex: OWNERSHIP_NODE_Z_INDEX
        }}
        {...props}
      >
        {isMergeable && <ConnectOwnershipButton entity={entity} />}
        <Tooltip content={extractTooltipContent(uncertain)}>
          <div className='p-2'>
            <div className='flex justify-between'>
              {entity ? (
                <EntityLink
                  entity={entity}
                  noFlags={noFlags}
                  noLink={noLink || isMergeable}
                  withIcon
                  iconWrapperProps={{ size: 'sm' }}
                  disableOnNoAccess
                  maxLength={maxLength}
                  className='relative'
                  {...textProps}
                >
                  {isMergeable && (
                    <NationFlags
                      className='absolute size-3.5 -bottom-0 -left-4 bg-background-paper rounded'
                      countryIsoCodes={[entity.primaryCountry?.kind ?? '']}
                    />
                  )}

                  {nameContent}
                </EntityLink>
              ) : (
                <Typography className='flex min-h-flags-chip items-center' {...textProps}>
                  {nameContent}
                </Typography>
              )}
            </div>
            <Divider />
            <div className='flex h-flags-chip items-center justify-between'>
              <Typography variant='body3'>{ownership}</Typography>

              <div className='flex items-center'>
                {entity?.__typename === 'Company' &&
                  !noStatus &&
                  entity.statusV2?.status &&
                  companyInPortfolio(entity.statusV2.status) && (
                    <Tooltip content={getTitle(companyStatusTitles[entity.statusV2.status])}>
                      <div className='h-[16px]'>
                        <CompanyStatusDot status={entity.statusV2.status} size='md' className='ml-2' />
                      </div>
                    </Tooltip>
                  )}

                {meta && (
                  <Typography variant='body3'>
                    {meta.name}: {meta.value}
                  </Typography>
                )}

                {isMergeable && (
                  <Chip
                    className='flex rounded-sm bg-semantic-notice-shade-5 border-none px-0'
                    size='sm'
                    label={t`International Owner`}
                    textProps={{ variant: 'body3' }}
                  />
                )}

                {entity && subsidiariesCount && !noSubsidiaryCount && (
                  <OwnershipSubsidiariesChip className='ml-1' entity={entity} />
                )}
              </div>
            </div>
            {children}
            {entity && !subsidiary && editMode && <OwnerActionDropdown entity={entity} />}
          </div>
        </Tooltip>
        {isCustom && customMeta && (
          <div className='absolute -bottom-3 flex w-full justify-center'>
            <CustomMeta customMeta={customMeta} />
          </div>
        )}
      </Box>
    )
  }
)
