import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconCompany = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M5 5v2h2V5H5ZM5 13v2h2v-2H5ZM9 11V9h2v2H9ZM5 9v2h2V9H5ZM9 13h2v2H9v-2ZM11 5H9v2h2V5ZM13 13h2v2h-2v-2ZM15 5h-2v2h2V5ZM13 9h2v2h-2V9Z'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M19 2v3h4v18H9v-4H7v4H1V2c0-.552.445-1 .997-1h16.01c.552 0 .993.448.993 1ZM5 17v4H3V3h14v18h-6v-4H5Zm14 4V7h2v14h-2Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconCompany.displayName = 'IconCompany'
