import * as colors from './colors'
import { typography } from './typography'

declare module '@mui/system' {
  // eslint-disable-next-line
  export interface Shape {
    [key: string]: string
  }
}

export const theme = {
  palette: {
    common: {
      white: colors.white
    },
    primary: {
      main: colors.blue[50],
      shade: colors.blue,
      contrastText: colors.white
    },
    secondary: {
      main: colors.gray[100],
      shade: colors.gray,
      contrastText: colors.white
    },
    accent: {
      blue: {
        main: colors.blue[50],
        shade: colors.blue
      },
      green: {
        main: colors.green[50],
        shade: colors.green
      },
      yellow: {
        main: colors.yellow[50],
        shade: colors.yellow
      },
      pink: {
        main: colors.pink[50],
        shade: colors.pink,
        contrastText: colors.white
      },
      purple: {
        main: colors.purple[50],
        shade: colors.purple
      },
      orange: {
        main: colors.orange[50],
        shade: colors.orange
      }
    },
    text: {
      primary: colors.gray[100],
      primaryContrast: colors.white,
      secondary: colors.gray[40],
      link: colors.blue[60],
      secondaryLink: colors.blue[10],
      disabled: colors.gray[40]
    },
    background: {
      default: colors.gray[5],
      paper: colors.white,
      disabled: colors.gray[5]
    },
    divider: colors.gray[10],
    semantic: {
      warning: {
        main: colors.semanticOrange[50],
        shade: colors.semanticOrange
      },
      danger: {
        main: colors.semanticRed[50],
        shade: colors.semanticRed
      },
      success: {
        main: colors.semanticGreen[50],
        shade: colors.semanticGreen
      },
      info: {
        main: colors.semanticBlue[50],
        shade: colors.semanticBlue
      },
      notice: {
        main: colors.semanticYellow[50],
        shade: colors.semanticYellow
      },
      disabled: {
        main: colors.semanticGray[50],
        shade: colors.semanticGray
      }
    }
  },
  spacing: 4,
  typography,
  shadows: [
    'none',
    '0px 8px 26px rgba(185, 182, 175, 0.2)',
    '0px 4px 13px rgba(185, 182, 175, 0.4)',
    '0px 4px 13px rgba(185, 182, 175, 0.8)',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  shape: {
    button: '4px',
    card: '12px'
  }
}

type Palette = (typeof theme)['palette']
type Keys = keyof Palette

// 0 | 5 | 10 | ... | 100
type Accents = keyof Palette['primary']['shade']

// The union type of keys in Palette[Key]. 'shade' is replaced by Accents
// Result is a union of Accents and all keys in Palette[Key] not matching 'shade'
type SubKeys<Key> = Key extends keyof Palette
  ? { [SubKey in keyof Palette[Key]]: SubKey extends 'shade' ? Accents : SubKey }[keyof Palette[Key]]
  : never

// SubKey<Key> extends string | number so SubKeys<Key> can be used in `${}`
// If Key extends accent / semantic we add also accents, so semantic.<SubKey>.<Accents> | semantic.<SubKey>
// else text.<SubKey>
type NestedColors = {
  [Key in Keys]: SubKeys<Key> extends string | number
    ? Key extends 'accent' | 'semantic'
      ? `${Key}.${SubKeys<Key>}.${Accents}` | `${Key}.${SubKeys<Key>}`
      : `${Key}.${SubKeys<Key>}`
    : never
}[Keys]

// https://github.com/microsoft/TypeScript/issues/29729#issuecomment-471566609
type LiteralUnion<T extends U, U = string> = T | (U & { zz_IGNORE_ME?: never })

export type ThemeColor = LiteralUnion<Keys | NestedColors | 'inherit' | 'transparent'>

export const darkTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    secondary: {
      main: colors.gray[5],
      shade: {
        5: colors.gray[100],
        10: colors.gray[90],
        20: colors.gray[80],
        30: colors.gray[70],
        40: colors.gray[60],
        50: colors.gray[50],
        60: colors.gray[40],
        70: colors.gray[30],
        80: colors.gray[20],
        90: colors.gray[10],
        100: colors.gray[5]
      }
    },
    accent: {
      ...theme.palette.accent,
      green: {
        main: colors.green[50],
        shade: {
          5: colors.green[90],
          10: colors.green[80],
          20: colors.green[70],
          30: colors.green[60],
          40: colors.green[50],
          50: colors.green[40],
          60: colors.green[30],
          70: colors.green[20],
          80: colors.green[10],
          90: colors.green[5],
          100: colors.green[100]
        }
      }
    },
    text: {
      primary: colors.gray[5],
      secondary: colors.gray[30],
      link: colors.blue[10],
      secondaryLink: colors.blue[60]
    },
    background: {
      default: colors.gray[90],
      paper: colors.gray[80]
    },
    divider: colors.gray[70]
  },
  shadows: [
    'none',
    '0px 8px 26px rgba(22, 23, 23, 0.2)',
    '0px 4px 13px rgba(22, 23, 23, 0.4)',
    '0px 4px 13px rgba(22, 23, 23, 0.8)',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ]
}
