import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconReview = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M21 3H3v18h18V3ZM1 1v22h22V1H1Z' clipRule='evenodd' />
      <path fill='currentColor' fillRule='evenodd' d='M18 9H6v12h12V9ZM4 7v16h16V7H4Z' clipRule='evenodd' />
      <path fill='currentColor' d='M3 4h18v2H3V4Z' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m16.774 12.633-5.2 6.355-3.281-3.28 1.414-1.415 1.719 1.719 3.8-4.645 1.548 1.266Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconReview.displayName = 'IconReview'
