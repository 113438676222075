import { t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { type Event, type EventRelevance as EventRelevanceEnum, LikeStatus, TrackedActivityKind } from '@strise/types'
import { type ButtonProps, IconButton, cn } from '@strise/ui-components'
import { IconDislike, IconLike } from '@strise/ui-components'
import React, { useCallback } from 'react'
import { DataSourceTooltip } from '~/components/DataSourceTooltip'
import { useUpdateEventLikeStatusMutation } from '~/graphqlOperations'
import { track } from '~/utils/tracking'

const LikeDislikeButton: React.FC<
  {
    isSmallScreen?: boolean
    selected: boolean
  } & ButtonProps
> = ({ className, isSmallScreen, selected, ...props }) => {
  return (
    <IconButton
      className={cn(
        'rounded-full p-2 text-white',
        isSmallScreen
          ? `mx-1 bg-white ${selected ? 'text-primary-main' : 'text-text-primary'}`
          : `hover:bg-white ${
              selected ? 'bg-white text-primary-main' : 'bg-transparent text-inherit hover:text-text-primary'
            }`,
        className
      )}
      data-track='false'
      {...props}
    />
  )
}

export const EventLikeDislike: React.FC<
  {
    buttonProps?: ButtonProps
    entityId: string
    event: Pick<Event, 'id' | 'liked'>
    isSmallScreen?: boolean
    relevance: EventRelevanceEnum
  } & DivProps
> = ({ buttonProps, className, entityId, event, isSmallScreen, relevance, ...props }) => {
  const [updateEventLikeStatus] = useUpdateEventLikeStatusMutation()

  const update = async (e: React.MouseEvent, status: LikeStatus): Promise<void> => {
    e.preventDefault()

    const nextStatus = event.liked === status ? null : status
    await updateEventLikeStatus({
      variables: {
        company: entityId,
        event: event.id,
        status: nextStatus
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateEventLikeStatus: {
          id: event.id,
          liked: nextStatus,
          __typename: 'Event'
        }
      }
    })
    track(TrackedActivityKind.StriseEventRelevance, {
      entityId,
      eventId: event.id,
      status: nextStatus,
      relevance
    })
  }

  const toggleLike = useCallback(async (e: React.MouseEvent) => await update(e, LikeStatus.Liked), [update])
  const toggleDislike = useCallback(async (e: React.MouseEvent) => await update(e, LikeStatus.Disliked), [update])

  const liked = event.liked === LikeStatus.Liked
  const disliked = event.liked === LikeStatus.Disliked

  return (
    <div className={cn('flex flex-col', className)} {...props}>
      <DataSourceTooltip content={t`Relevant for me`} side='left'>
        <div>
          <LikeDislikeButton isSmallScreen={isSmallScreen} selected={liked} onClick={toggleLike} {...buttonProps}>
            <IconLike />
          </LikeDislikeButton>
        </div>
      </DataSourceTooltip>
      <div className={isSmallScreen ? 'h-16' : 'h-8'} />
      <DataSourceTooltip content={t`Not relevant for me`} side='left'>
        <div>
          <LikeDislikeButton isSmallScreen={isSmallScreen} selected={disliked} onClick={toggleDislike} {...buttonProps}>
            <IconDislike />
          </LikeDislikeButton>
        </div>
      </DataSourceTooltip>
    </div>
  )
}
