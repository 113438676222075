import { t } from '@lingui/macro'
import { extractIsCompany } from '@strise/app-shared'
import { type BaseEntityLikeFragment } from '@strise/app-shared/src/graphqlTypes'
import { useContext } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { cn } from '@strise/ui-components'
import React from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { EntityMetaItems } from '~/components/EntityMeta/EntityMetaItems'
import { LastVisited } from '~/components/LastVisited'
import { SidepanelCard, type SidepanelCardProps } from '~/components/Sidepanel/SidepanelCard'
import { TruncatedList } from '~/components/TruncatedList'
import { RecentlyVisitedEntitiesContext } from '~/contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContext'

const extractDescription = (
  hasMatchingEntities: boolean,
  entity: BaseEntityLikeFragment | null | undefined
): string | null => {
  if (!entity) return null

  const isCompany = extractIsCompany(entity)
  if (!hasMatchingEntities && isCompany) return t`We could not find any matching entities for this company`
  if (!hasMatchingEntities && !isCompany) return t`We could not find any matching entities for this person`
  return t`Here are some entities we think are the same`
}

const NAME_MAX_LENGTH = 50

export const SidepanelSimilarEntitiesCard = ({
  className,
  entity,
  entityEdges,
  ...props
}: {
  entity: BaseEntityLikeFragment | null | undefined
  entityEdges: Array<{ node: BaseEntityLikeFragment }> | null | undefined
} & SidepanelCardProps): React.ReactNode => {
  const description = extractDescription(!!entityEdges?.length, entity)

  return (
    <SidepanelCard className={cn('pb-4', className)} title={t`Matching entities`} description={description} {...props}>
      {!!entityEdges?.length && (
        <div className='mx-4 rounded-[2px] border border-solid border-tertiary-main px-4'>
          <TruncatedList
            items={entityEdges}
            truncateAfter={3}
            context='Similar Entities'
            paginationProps={{ className: 'pl-1' }}
          >
            {({ node: similarEntity }, i, isLast) => {
              return <SimilarEntity key={`${similarEntity.id} ${i}`} similarEntity={similarEntity} isLast={isLast} />
            }}
          </TruncatedList>
        </div>
      )}
    </SidepanelCard>
  )
}

export const SimilarEntity = ({
  isLast,
  similarEntity
}: {
  isLast: boolean
  similarEntity: BaseEntityLikeFragment
}): React.ReactNode => {
  const { recentlyVisitedEntitiesMap } = useContext(RecentlyVisitedEntitiesContext)
  const visitedAt = recentlyVisitedEntitiesMap[similarEntity.id]?.visitedAt ?? 0

  const trimmedName = ellipsis(similarEntity.name ?? '', NAME_MAX_LENGTH)
  return (
    <div className={cn('flex items-center justify-between border-b border-tertiary-main p-1', isLast && 'border-none')}>
      <div>
        <EntityLink entity={similarEntity} maxLength={NAME_MAX_LENGTH} noTooltip>
          <div>{trimmedName}</div>
        </EntityLink>
        <EntityMetaItems maxItems={4} entity={similarEntity} noTooltip />
      </div>
      <LastVisited lastVisited={visitedAt} />
    </div>
  )
}
