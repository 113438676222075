import { type Cache } from '@apollo/client/index.js'
import { companyStatuses } from '@strise/app-shared'
import {
  type CompanyStatus,
  type CompanyUserConnection,
  type SimpleUser,
  type TeamCompanyStatus,
  type TrackedActivityKind
} from '@strise/types'
import { type SelectProps } from '@strise/ui-components'
import { type TeamCompanyStatusFragment } from '~/graphqlTypes'

export interface SelectCompany {
  id: string
  statusV2?: TeamCompanyStatusFragment | null
}

export interface SelectCompaniesStatusProps extends Omit<SelectProps, 'options' | 'value'> {
  companyIds: string[]
  companyStatus?: CompanyStatus | null
  disabled?: boolean
  disabledStatuses?: CompanyStatus[]
  loading?: boolean
  onChange?: () => void
  opportunity?: boolean
  previousCompanyStatus?: CompanyStatus | null
  removable?: boolean
  trackEventName?: TrackedActivityKind
  updateCompanyStatus: (status: CompanyStatus | null, previousStatus: CompanyStatus | null) => void
}

export const companyInPortfolio = (status: CompanyStatus | null | undefined): boolean => {
  return Boolean(status && companyStatuses.includes(status))
}
export const updateCompanyStatusCache = (data: {
  __typename: 'Company' | 'SimpleCompany'
  id: string
  statusV2?: {
    modifiedAt?: Date | null
    modifiedBy?: Pick<SimpleUser, 'id' | 'name'> | null
    previousStatus?: CompanyStatus | null
    status?: CompanyStatus | null
  } | null
}): Cache.ModifyOptions => {
  return {
    id: `${data.__typename}:${data.id}`,
    fields: {
      statusV2: (statusV2: TeamCompanyStatus | undefined) => {
        return {
          ...statusV2,
          status: data.statusV2?.status,
          previousStatus: data.statusV2?.previousStatus ?? statusV2?.previousStatus ?? null,
          modifiedAt: data.statusV2?.modifiedAt ?? statusV2?.modifiedAt ?? null,
          modifiedBy: data.statusV2?.modifiedBy ?? statusV2?.modifiedBy ?? null
        }
      },
      assignee: (assignees: CompanyUserConnection | undefined) => {
        if (!companyInPortfolio(data.statusV2?.status)) {
          return { ...assignees, edges: [] }
        }
        return assignees
      }
    }
  }
}
