import { Chip, cn } from '@strise/ui-components'
import * as React from 'react'

interface RiskChipProps {
  className?: string
  colorClass: string
  label: string
  onClick?: () => void
}

export const BaseRiskChip = ({ className, colorClass, label, onClick }: RiskChipProps): React.ReactNode => {
  return <Chip className={cn(colorClass, className)} onClick={onClick} label={label} />
}
