import { Trans, t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { Button, type ButtonProps, Chip, LoaderRound } from '@strise/ui-components'
import * as React from 'react'
import { ContactStriseToEnable } from '~/components/ContactStrise'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type ReviewFragment } from '~/graphqlTypes'
import { useDownloadReview, useHasReviewFile } from '~/utils/reviewUtils'
import { TestIDs } from '~/utils/testIDs'

const DefaultDownloadButton = (props: ButtonProps): React.ReactNode => {
  return (
    <Button
      variant='ghost'
      palette='primary'
      data-track='Download Risk assessment report'
      data-id={TestIDs.Review.Card.downloadPdfButton}
      {...props}
    >
      <Trans>View report</Trans>
    </Button>
  )
}

export const DownloadReviewPdf = ({
  DownloadButton = DefaultDownloadButton,
  hideContactSales,
  review,
  ...props
}: {
  DownloadButton?: React.FC<ButtonProps>
  hideContactSales?: boolean
  review: ReviewFragment
} & ButtonProps &
  DivProps): React.ReactNode => {
  const features = useCurrentUserFeatures()
  const { hasFile, isOver1HourOld } = useHasReviewFile(review)

  const { downloadReview, loading } = useDownloadReview()

  const handleClick = async (event: React.MouseEvent): Promise<void> => {
    if (!hasFile) return

    event.preventDefault()
    await downloadReview(review.id)
  }

  if (!features.REVIEW_PDF && !hideContactSales) {
    return <ContactStriseToEnable intention={t`enable the Review PDF feature`} {...props} />
  }

  if (!features.REVIEW_PDF) {
    return null
  }

  if (!hasFile && isOver1HourOld) {
    return <Trans>No report</Trans>
  }

  if (!hasFile) {
    return (
      <Chip
        label={
          <div className='flex items-center gap-x-2'>
            <Trans>Loading</Trans>
            <LoaderRound size='xs' />
          </div>
        }
      />
    )
  }

  return <DownloadButton onClick={handleClick} loading={loading} {...props} />
}
