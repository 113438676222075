import { type DivProps } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import { isString } from 'lodash-es'
import React from 'react'
import EllipseIcon from '~/assets/icons/mapMarker.svg'
import { useGoogleMaps, useMapsPalette } from '~/utils/googleMapsUtils'

export const GoogleMap = ({
  className,
  height = 275,
  location,
  mapOptions,
  marker = true,
  width = 275,
  ...props
}: {
  height?: number | string
  location: number[] | string
  mapOptions?: google.maps.MapOptions
  marker?: boolean
  width?: number | string
} & Omit<DivProps, 'width' | 'height'>): React.ReactNode => {
  const mapsPalette = useMapsPalette()
  const center = isString(location)
    ? { lat: 0, lng: 0 }
    : {
        lat: location[0] ?? 0,
        lng: location[1] ?? 0
      }

  const { map, mapRef } = useGoogleMaps({
    zoom: 12,
    center,
    fullscreenControl: false,
    zoomControl: true,
    styles: mapsPalette,
    backgroundColor: 'none',
    ...mapOptions
  })

  React.useEffect(() => {
    if (!map) return

    if (marker && !isString(location)) {
      new google.maps.Marker({
        position: center,
        map,
        clickable: false,
        optimized: false,
        icon: {
          url: EllipseIcon,
          scaledSize: new google.maps.Size(40, 40),
          anchor: new google.maps.Point(16, 16)
        }
      })
    }

    if (isString(location)) {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: location }, (results, status) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (status === 'OK' && results?.[0]) {
          map.setCenter(results[0].geometry.location)
        }
      })
    }
  }, [map])

  return (
    <div className={cn('relative overflow-hidden', className)} style={{ width, height }} {...props}>
      <div ref={mapRef} style={{ width, height }} />
    </div>
  )
}
