import { type DivPropsWithChildren } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import * as React from 'react'

interface BorderedRowProps extends DivPropsWithChildren {}

export const BorderedRow = ({ children, className, ...props }: BorderedRowProps): React.ReactNode => {
  return (
    <div
      className={cn('ml-2 flex items-center justify-between border-b border-divider py-4 last:border-b-0', className)}
      {...props}
    >
      {children}
    </div>
  )
}
