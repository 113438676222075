import { TeamSettingsContext } from '~/contexts/TeamSettingsContext/TeamSettingsContext'
import React from 'react'
import { useTeamSettingsQuery } from '~/graphqlOperations'

export const TeamSettingsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data, loading, refetch } = useTeamSettingsQuery({
    variables: {},
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const value = React.useMemo(() => ({ data, loading, refetch }), [data, loading, refetch])

  return <TeamSettingsContext.Provider value={value}>{children}</TeamSettingsContext.Provider>
}
