import { i18n } from '@lingui/core'
import { useResizeRef } from '@strise/react-utils'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { ContentViewContext } from '~/components/Layout/ContentViewContext'
import { Header } from '~/components/Layout/Header'
import { Menu } from '~/components/Menu/Menu'
import { Sidepanel } from '~/components/Sidepanel/Sidepanel'
import { SidepanelNavHistoryContextProvider } from '~/components/Sidepanel/SidepanelNavHistoryContext'
import { breakpoints } from '~/theme'
import { contentViewToTitle } from '~/utils/contentViewUtils'
import { useActiveContentView } from '~/utils/viewsHooks'

export const Layout = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const [isSmallScreen, setIsSmallScreen] = React.useState<boolean>(false)
  const [isMediumScreen, setIsMediumScreen] = React.useState<boolean>(false)
  const [isLargeScreen, setIsLargeScreen] = React.useState<boolean>(false)
  const contentRef = React.useRef<HTMLDivElement | null>(null)

  const resizeRef = useResizeRef((entries) => {
    if (!entries[0]) return
    setIsSmallScreen(entries[0].contentRect.width < breakpoints.md)
    setIsMediumScreen(entries[0].contentRect.width < breakpoints.lg)
    setIsLargeScreen(entries[0].contentRect.width < breakpoints.xl)
  })

  const handleRef = (node: HTMLDivElement): void => {
    contentRef.current = node
    resizeRef(node)
  }

  const values = React.useMemo(
    () => ({
      contentRef,
      isSmallScreen,
      isMediumScreen,
      isLargeScreen
    }),
    [contentRef, isSmallScreen, isMediumScreen, isLargeScreen]
  )

  const activeContentView = useActiveContentView()
  const title = activeContentView ? contentViewToTitle[activeContentView] : undefined
  const i18nTitle = title ? i18n._(title) : ''

  return (
    <SidepanelNavHistoryContextProvider>
      <ContentViewContext.Provider value={values}>
        <Helmet>
          <title>{i18nTitle}</title>
        </Helmet>
        <div className='flex'>
          <Menu />
          <div className='flex min-w-0 grow flex-col'>
            <Header>{i18nTitle}</Header>
            <div className='flex'>
              <main
                ref={handleRef}
                className='relative flex h-[calc(100vh-theme(height.header))] min-w-0 flex-1 overflow-auto'
              >
                {children}
              </main>
              <Sidepanel />
            </div>
          </div>
        </div>
      </ContentViewContext.Provider>
    </SidepanelNavHistoryContextProvider>
  )
}
