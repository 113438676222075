import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconChat = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' d='M19 9H5V7h14v2ZM15 13H5v-2h10v2Z' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M23 16a4 4 0 0 1-4 4h-1v3l-8-3H5a4 4 0 0 1-4-4V1h18a4 4 0 0 1 4 4v11Zm-7 4.114L10.363 18H5a2 2 0 0 1-2-2V3h16a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-3v2.114Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconChat.displayName = 'IconChat'
