import { cn } from '../../utils/className'
import { LoaderRound, type LoaderRoundProps } from '../LoaderRound'
import { buttonVariants } from '../variants/buttonVariants'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { type DataProps } from '@strise/react-utils'
import { type VariantProps } from 'class-variance-authority'
import * as React from 'react'

interface ButtonBaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, DataProps {
  asChild?: boolean
  loaderProps?: LoaderRoundProps
  loading?: boolean
}

export interface ButtonProps extends ButtonBaseProps, Omit<VariantProps<typeof buttonVariants>, 'disabled'> {
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      disabled,
      endIcon,
      loaderProps,
      loading,
      palette,
      size,
      startIcon,
      variant,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    const { className: loaderClassName, ...loaderPropsRest } = loaderProps || {}
    return (
      <Comp
        className={cn(buttonVariants({ disabled: disabled || loading, palette, size, variant }), className)}
        disabled={disabled || loading}
        ref={ref}
        {...props}
      >
        {!startIcon && !endIcon && loading && (
          <div
            className={cn(
              'absolute z-[2] flex size-full items-center justify-center',
              variant === 'contained' ? 'bg-inherit' : 'bg-white'
            )}
          >
            <LoaderRound size='md' className={loaderClassName} {...loaderPropsRest} />
          </div>
        )}
        {startIcon && loading ? (
          <LoaderRound size='md' className={cn('mr-4', loaderClassName)} {...loaderPropsRest} />
        ) : (
          startIcon
        )}
        <Slottable>{children}</Slottable>
        {!startIcon && endIcon && loading ? (
          <LoaderRound size='md' className={cn('ml-4', loaderClassName)} {...loaderPropsRest} />
        ) : (
          endIcon
        )}
      </Comp>
    )
  }
)

Button.displayName = 'Button'

export interface IconButtonProps
  extends ButtonBaseProps,
    Omit<VariantProps<typeof buttonVariants>, 'disabled' | 'size'> {}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ asChild = false, children, className, disabled, loaderProps, loading, palette, variant, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          buttonVariants({ disabled: disabled || loading, palette, variant, size: null }),
          'shrink-0 p-1',
          className
        )}
        disabled={disabled || loading}
        ref={ref}
        {...props}
      >
        {loading ? <LoaderRound size='md' {...loaderProps} /> : <Slottable>{children}</Slottable>}
      </Comp>
    )
  }
)

IconButton.displayName = 'IconButton'
