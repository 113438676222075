import { Img } from './Img'
import { t } from '@lingui/macro'
import { type StyleProps } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import * as React from 'react'

export interface UserAvatarProps extends StyleProps, React.ImgHTMLAttributes<HTMLImageElement> {
  user: { avatar: string | null; id: string }
}

export const UserAvatar = React.forwardRef<HTMLImageElement, UserAvatarProps>(({ className, user, ...props }, ref) => {
  return (
    <Img
      ref={ref}
      className={cn('rounded-full text-text-secondary', className)}
      src={user.avatar}
      alt={t`user avatar`}
      {...props}
    />
  )
})
