import { t } from '@lingui/macro'
import { Duration } from '@strise/types'
import { Card, CardContent, Select, Typography } from '@strise/ui-components'
import * as React from 'react'
import { GenerateTeamUsageReport } from '~/components/GenerateTeamUsageReport'
import { getDurationLabel } from '~/views/Home/PortfolioHealth/utils/durationUtils'

interface PortfolioHealthHeaderCardProps {
  duration: Duration
  onDurationChange: (duration: Duration) => void
  teamId: string
  teamName: string
  userName: string
}

export const PortfolioHealthHeaderCard = ({
  duration,
  onDurationChange,
  teamId,
  teamName,
  userName
}: PortfolioHealthHeaderCardProps): React.ReactNode => {
  const durationOptions = Object.values(Duration).map((value) => ({
    value,
    children: getDurationLabel(value)
  }))
  const getGreeting = (): string => {
    const currentHour = new Date().getHours()

    if (currentHour < 12) {
      return t`Good morning`
    }
    if (currentHour < 18 && currentHour >= 12) {
      return t`Good afternoon`
    }
    return t`Good evening`
  }

  return (
    <Card className='border-none bg-transparent px-0 md:pt-8' size='sm'>
      <CardContent className='py-0'>
        <div>
          <Typography variant='h3'>
            {getGreeting()}, {userName}
          </Typography>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 content-end gap-2'>
          <div>
            <Typography>Team</Typography>
            <Typography variant='subtitle1'>{teamName}</Typography>
          </div>
          <div className='md:justify-self-end md:self-end'>
            <Select
              placeholder={t`Range of time`}
              options={durationOptions}
              value={duration}
              onValueChange={onDurationChange}
              palette='tertiary'
              variant='contained'
              className='rounded-sm px-4 mr-4'
            />

            <GenerateTeamUsageReport teamId={teamId} teamName={teamName} />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
