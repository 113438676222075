import { Trans } from '@lingui/macro'
import { ExternalLink, IconFacebook, IconLinkedIn, Typography } from '@strise/ui-components'
import * as React from 'react'

export const SidepanelFooter = (): React.ReactNode => {
  const date = new Date()

  return (
    <div className='ml-5 mt-[3.75rem] h-[31.25rem] text-secondary-main' style={{ lineHeight: '3em' }}>
      <Typography component='div' variant='subtitle3'>
        &copy; {date.getFullYear()} Strise
      </Typography>
      <ExternalLink variant='subtitle3' href='https://files.strise.ai/privacy.pdf' title='Strise privacy policy'>
        <Trans>Privacy</Trans>
      </ExternalLink>
      <div className='flex flex-row'>
        <ExternalLink href='https://www.facebook.com/striseai/' Icon={IconFacebook} />
        <ExternalLink className='ml-2' href='https://www.linkedin.com/company/strise' Icon={IconLinkedIn} />
      </div>
    </div>
  )
}
