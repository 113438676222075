import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconTextInputField = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M5.586 23 23 5.586v2.828L8.414 23H5.586ZM16.414 23 23 16.414v-2.828L13.586 23h2.828Z'
      />
    </svg>
  )
)
IconTextInputField.displayName = 'IconTextInputField'
