import { Divider, Tabs, TabsContent, TabsList, TabsTrigger } from '@strise/ui-components'
import * as React from 'react'
import { CreatePrivatePersonDialog } from '~/components/PrivatePersons/CreatePrivatePersonForm/CreatePrivatePersonDialog'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { PortfolioCompanyView } from '~/views/Portfolio/components/PortfolioCompany/PortfolioCompanyView'
import { PortfolioPrivatePersonView } from '~/views/Portfolio/components/PortfolioPrivatePerson/PortfolioPrivatePersonView'

export const PortfolioView = React.memo(() => {
  const features = useCurrentUserFeatures()
  const isPrivatePersonEnabled = features.EXPERIMENTAL_PRIVATE_PERSON_FEATURES

  return (
    <Tabs defaultValue='company' className='bg-white'>
      <TabsList className={isPrivatePersonEnabled ? 'flex min-w-full justify-center bg-white py-0.5' : 'hidden'}>
        <TabsTrigger value='company' className='w-1/2'>
          Company
        </TabsTrigger>
        {isPrivatePersonEnabled && (
          <TabsTrigger value='person' className='w-1/2'>
            Private Person
          </TabsTrigger>
        )}
      </TabsList>
      <TabsContent value='company'>
        <PortfolioCompanyView />
      </TabsContent>
      {isPrivatePersonEnabled && (
        <TabsContent value='person'>
          <Divider />
          <CreatePrivatePersonDialog />
          <PortfolioPrivatePersonView />
        </TabsContent>
      )}
    </Tabs>
  )
})
