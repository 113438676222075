import { Trans } from '@lingui/macro'
import { Button, IconButton, IconCross, Typography } from '@strise/ui-components'
import { Modal } from '@strise/ui-components-legacy'
import React from 'react'
import { CreatePrivatePersonForm } from '~/components/PrivatePersons/CreatePrivatePersonForm/CreatePrivatePersonForm'

export const CreatePrivatePersonDialog = (): React.ReactNode => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className='flex justify-end p-2'>
      <Button variant='contained' onClick={() => setIsOpen(true)} data-track='Create Private Person / Open'>
        <Trans>Create private person</Trans>
      </Button>
      <Modal
        isOpen={isOpen}
        shouldCloseOnEsc
        onRequestClose={() => setIsOpen(false)}
        contentProps={{ className: 'bg-background-paper' }}
        contentMaxWidth={600}
      >
        <div className='flex items-center justify-between px-4 pt-4'>
          <Typography component='h1' variant='subtitle1'>
            <Trans>Create private person</Trans>
          </Typography>
          <IconButton onClick={() => setIsOpen(false)} data-track='Create Private Person / Close'>
            <IconCross />
          </IconButton>
        </div>
        <CreatePrivatePersonForm handleClose={() => setIsOpen(false)} />
      </Modal>
    </div>
  )
}
