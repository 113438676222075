import { type RenderNodeContent, type RootEntity } from './types'
import { Typography } from '@strise/ui-components'
import { Handle, type Node, type NodeProps, Position, useHandleConnections } from '@xyflow/react'
import * as React from 'react'

export type RootNodeType = Node<
  RootEntity & {
    renderNodeContent?: RenderNodeContent<RootEntity>
  },
  'root'
>

interface RootNodePRops extends NodeProps<RootNodeType> {}

const handleClassName = 'border !bg-transparent rounded-full size-[5px] border-secondary-shade-40 -z-10'

export const RootNode = ({ data, height = 0, width = 0 }: RootNodePRops): React.ReactNode => {
  const connections = useHandleConnections({ type: 'source', id: 'selfOwnership' })

  const hasSelfOwnership = connections.length > 0

  const defaultNodeClassName =
    'size-full border-[3px] border-double border-secondary-shade-30 bg-white p-2 cursor-grab active:cursor-grabbing'

  const defaultNodeContent = (
    <div className='size-full border-[3px] border-double border-secondary-shade-30 bg-white p-2' style={{ height }}>
      <Typography variant='h6' component='span'>
        {data.name}
      </Typography>
    </div>
  )
  return (
    <>
      {data.renderNodeContent?.({ nodeId: data.id, data, className: defaultNodeClassName, width, height }) ??
        defaultNodeContent}
      <Handle className={handleClassName} isConnectable={false} position={Position.Top} type='target' />
      {hasSelfOwnership && (
        <Handle
          className={handleClassName}
          isConnectable={false}
          position={Position.Left}
          type='source'
          id='selfOwnership'
        />
      )}
      <Handle
        className='!left-[42px] !size-0 !min-h-0 !min-w-0'
        position={Position.Bottom}
        type='source'
        id='rootNodeBottomHandle'
      />
    </>
  )
}
