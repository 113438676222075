import * as React from 'react'
import { BaseRiskChip } from '~/components/Risk/BaseRiskChip'
import { type ReviewFragment } from '~/graphqlTypes'
import { riskChipClasses } from '~/utils/risk'

interface CustomRiskLevelChipProps {
  className?: string
  customRiskLevel: NonNullable<ReviewFragment['customRiskLevel']>
  onClick?: () => void
}

export const CustomRiskLevelChip = ({
  className,
  customRiskLevel,
  onClick
}: CustomRiskLevelChipProps): React.ReactNode => {
  return (
    <BaseRiskChip
      className={className}
      colorClass={riskChipClasses[customRiskLevel.color]}
      label={customRiskLevel.label}
      onClick={onClick}
    />
  )
}
