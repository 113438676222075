import { flagChipProps } from './flagChipUtils'
import { type FlagSeverity } from '@strise/types'
import { Chip, type ChipProps, cn } from '@strise/ui-components'
import * as React from 'react'

export const FlagChip = ({
  className,
  value,
  ...props
}: { value: FlagSeverity } & Omit<ChipProps, 'value'>): React.ReactNode => {
  const { className: flagClassName, ...flagProps } = flagChipProps(value)
  return <Chip key={value} className={cn(flagClassName, className)} {...flagProps} {...props} />
}
