import { t } from '@lingui/macro'
import { Button, DataTable, Skeleton, cn, createColumnHelper } from '@strise/ui-components'
import React, { useState } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { EntityMetaItems } from '~/components/EntityMeta/EntityMetaItems'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { useSidepanelEntityKeyDetailsQuery } from '~/graphqlOperations'
import { type BaseEntityLikeFragment, type SidepanelEntityKeyDetailsQuery } from '~/graphqlTypes'
import { hasDataSources } from '~/utils/entity'

const LoadingSkeleton = () => (
  <div className='flex flex-col gap-2'>
    <Skeleton className='h-5 w-96' />
    <Skeleton className='h-5 w-96' />
    <Skeleton className='h-5 w-96' />
  </div>
)

const CellWithFallback = ({ value }: { value: string | null }) => (
  <span className={cn({ 'text-text-secondary': !value })}>{value || 'N/A'}</span>
)

const columnHelper = createColumnHelper<{
  address: string | null
  primaryIndustry: string | null
  shareholders: string | null
}>()

const extractColumns = () => {
  return [
    columnHelper.accessor((info) => info.address, {
      header: t`Address`,
      cell: ({ getValue }) => <CellWithFallback value={getValue()} />
    }),
    columnHelper.accessor((info) => info.primaryIndustry, {
      header: t`Industries`,
      cell: ({ getValue }) => <CellWithFallback value={getValue()} />
    }),
    columnHelper.accessor((info) => info.shareholders, {
      header: t`Shareholders`,
      cell: ({ getValue }) => <CellWithFallback value={getValue()} />
    })
  ]
}

const CompanyDetails = ({ entity }: { entity: SidepanelEntityKeyDetailsQuery['entity'] | undefined }) => {
  const columns = React.useMemo(() => extractColumns(), [entity])

  if (!entity || entity.__typename !== 'Company') return null

  const data = [
    {
      address: entity.companyKeyDetails.address,
      primaryIndustry: entity.companyKeyDetails.primaryIndustry,
      shareholders:
        entity.companyKeyDetails.shareholders.reduce((acc, curr, index, array) => {
          const item = `${curr.name} (${curr.share}%)`
          const separator = index < array.length - 1 ? ', ' : ''
          return acc + item + separator
        }, '') || null
    }
  ]

  return (
    <DataTable
      columns={columns}
      data={data}
      orientation='vertical'
      bodyCellClassName='p-0'
      headerCellClassName='p-0 w-1/4'
      variant='backplate'
      className='w-full'
      getRowClassName={() => 'hover:bg-white border-none p-0'}
    />
  )
}

const MergeEntityDetails = ({ entity }: { entity: BaseEntityLikeFragment }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { data, loading } = useSidepanelEntityKeyDetailsQuery({
    variables: {
      entity: entity.id,
      shareholderLimit: 3
    }
  })

  return (
    <div className='flex flex-col gap-2'>
      <EntityLink entity={entity} withIcon noTooltip openInNewTab />
      <EntityMetaItems entity={entity} />
      {isOpen && (loading ? <LoadingSkeleton /> : <CompanyDetails entity={data?.entity} />)}
      <div className='flex justify-between gap-2'>
        <Button
          variant='ghost'
          palette='primary'
          className='text-sm py-0 px-2 h-auto'
          onClick={() => setIsOpen(!isOpen)}
          data-track='Merge Entity / Details Toggle'
        >
          {isOpen ? t`Hide details` : t`View more details`}
        </Button>
        {hasDataSources(entity) && <DataSources dataSources={entity.dataSources.baseInfo} className='p-0' />}
      </div>
    </div>
  )
}

export default MergeEntityDetails
