import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconArrowLeft = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M3.758 10.973H22v2.054H3.758L2.776 12l.982-1.027Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M0 12 10.51 1l1.387 1.453-8.14 8.52L2.778 12l.98 1.027 8.14 8.52L10.51 23 0 12Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconArrowLeft.displayName = 'IconArrowLeft'
