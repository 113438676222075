import DOMPurify from 'dompurify'
import TurndownService from 'turndown'

// Function to create a DOMPurify instance
const createFrontendDOMPurify = (): DOMPurify.DOMPurifyI => {
  // Some weird tsc error in the command line when running from root, but when from the lib directly or in the IDE
  // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore
  // eslint-disable-next-line no-restricted-globals
  const win = typeof window === 'undefined' ? undefined : globalThis.window
  return DOMPurify(win)
}

// Function to create a TurndownService instance
const createTurndownService = (): TurndownService => {
  return new TurndownService()
}

// Function to convert HTML to Markdown
const _htmlToMarkdown = (domPurify: DOMPurify.DOMPurifyI, turndownService: TurndownService, html: string): string => {
  // Sanitize the HTML
  const sanitizedHtml = domPurify.sanitize(html)

  // Remove links from anchor tags
  turndownService.addRule('removeLinks', {
    filter: 'a',
    replacement: (content) => content // Keep only the text, ignore the href
  })

  // Convert the sanitized HTML to Markdown
  return turndownService.turndown(sanitizedHtml)
}

// Create shared instances
const domPurify = createFrontendDOMPurify()
const turndownService = createTurndownService()

// Export the function that uses the shared instances
export const htmlToMarkdown = (html: string): string => {
  return _htmlToMarkdown(domPurify, turndownService, html)
}

// Need to create a separate function for testing due to JSDOM not working in the browser
export const htmlToMarkdownForTest = (html: string, purify: DOMPurify.DOMPurifyI): string => {
  return _htmlToMarkdown(purify, turndownService, html)
}
