import { cn } from '../../utils/className'
import { isNumber, isObject, isString } from 'lodash-es'
import * as React from 'react'
import * as RechartsPrimitive from 'recharts'
import { type NameType, type Payload, type ValueType } from 'recharts/types/component/DefaultTooltipContent'

// Defines the available chart themes - either 'light' or 'dark' mode
export type ChartTheme = 'light' | 'dark'
// Maps theme names to their corresponding CSS class strings
export type ChartThemes = Record<ChartTheme, string>

// Defines the configuration options available for each chart element
export interface ChartConfigItem {
  color?: string // The color to use for this chart element
  icon?: React.ComponentType // Optional icon component to display in legend
  label?: React.ReactNode // Label text/content to show in legend/tooltip
  theme?: Record<ChartTheme, string> // Theme-specific overrides
}

// Configuration object mapping keys to their chart element settings
export type ChartConfig = Record<string, ChartConfigItem>

// Context value interface containing the chart configuration
interface ChartContextValue {
  config: ChartConfig // The complete chart configuration object
}

// CSS class suffixes used for theme variants
// Light theme has no suffix, dark theme uses '.dark'
const THEMES: ChartThemes = { light: '', dark: '.dark' } as const

// React context to provide chart configuration to child components
const ChartContext = React.createContext<ChartContextValue | null>(null)

// Custom hook to access chart configuration from context
// Throws error if used outside of ChartContainer component
function useChart(): ChartContextValue {
  const context = React.useContext(ChartContext)
  if (!context) throw new Error('useChart must be used within a <ChartContainer />')
  return context
}

// Interface for the main chart container component props
interface ChartContainerProps extends React.ComponentProps<'div'> {
  children: React.ComponentProps<typeof RechartsPrimitive.ResponsiveContainer>['children']
  config: ChartConfig // Configuration object for chart styling and behavior
}

// Main container component that provides context and styling for chart elements
const ChartContainer = React.forwardRef<HTMLDivElement, ChartContainerProps>((props, ref) => {
  const { children, className, config, ...rest } = props
  const chartId = React.useId()

  const contextValue = React.useMemo(() => ({ config }), [config])

  return (
    <ChartContext.Provider value={contextValue}>
      <div
        data-chart={chartId}
        ref={ref}
        className={cn('flex aspect-square justify-start items-start size-full text-xs', className)}
        {...rest}
      >
        <ChartStyle id={chartId} config={config} />
        <RechartsPrimitive.ResponsiveContainer>{children}</RechartsPrimitive.ResponsiveContainer>
      </div>
    </ChartContext.Provider>
  )
})
ChartContainer.displayName = 'ChartContainer'

// Type definition for chart payload items that can be either Recharts payload or custom payload
type PayloadItem =
  | Payload<ValueType, NameType>
  | {
      color?: string
      dataKey?: string | number
      name?: string | number
      payload?: Record<string, unknown>
      value?: string | number
    }

// Helper function to get configuration for a payload item from the chart config
const getPayloadConfigFromPayload = (
  config: ChartConfig,
  payload: PayloadItem,
  key: string
): ChartConfigItem | undefined => {
  if (!isObject(payload)) return undefined

  const payloadPayload =
    'payload' in payload && isObject(payload.payload) ? (payload.payload as Record<string, unknown>) : undefined
  const configLabelKey = getConfigLabelKey(payload, payloadPayload, key)

  return configLabelKey in config ? config[configLabelKey] : config[key]
}

// Helper function to extract the configuration label key from payload data
const getConfigLabelKey = (
  payload: PayloadItem,
  payloadPayload: Record<string, unknown> | undefined,
  key: string
): string => {
  if (key in payload && isString(payload[key as keyof typeof payload])) {
    return payload[key as keyof typeof payload] as string
  }

  if (payloadPayload && key in payloadPayload && isString(payloadPayload[key])) {
    return payloadPayload[key]
  }

  return key
}

// Export Recharts components with custom names
const ChartTooltip = RechartsPrimitive.Tooltip
const ChartLegend = RechartsPrimitive.Legend

// Interface for the ChartStyle component that handles dynamic styling
interface ChartStyleProps extends React.ComponentProps<'style'> {
  config: ChartConfig
  id: string
}

// Component that generates CSS variables for chart colors based on theme and configuration
// https://ui.shadcn.com/docs/components/chart
const ChartStyle: React.FC<ChartStyleProps> = ({ config, id, ...props }) => {
  const colorConfigs = Object.entries(config).filter(([_, itemConfig]) => itemConfig.theme || itemConfig.color)
  if (!colorConfigs.length) return null

  const styleContent = Object.entries(THEMES)
    .map(
      ([theme, prefix]) => `
${prefix} [data-chart=${id}] {
${colorConfigs
  .map(([key, itemConfig]) => {
    const color = itemConfig.theme?.[theme as ChartTheme] || itemConfig.color
    return color ? `  --color-${key}: ${color};` : null
  })
  .filter(Boolean)
  .join('\n')}
}
`
    )
    .join('\n')

  return <style dangerouslySetInnerHTML={{ __html: styleContent }} {...props} />
}

// Interface defining props for the ChartLegendContent component
interface ChartLegendContentProps extends React.ComponentProps<'div'> {
  hideIcon?: boolean // Whether to hide the icon in legend items
  nameKey?: string // Key to use for item names
  payload?: Array<PayloadItem> // Data payload for legend items
  verticalAlign?: 'top' | 'bottom' // Vertical alignment of the legend
}

// ChartLegendContent component renders a customizable legend for charts
// It displays icons/colors and labels for each data series
const ChartLegendContent = React.forwardRef<HTMLDivElement, ChartLegendContentProps>(
  ({ className, hideIcon = false, nameKey, payload, verticalAlign = 'bottom', ...props }, ref) => {
    // Get chart configuration from context
    const { config } = useChart()

    // Don't render if no payload items
    if (!payload?.length) return null

    return (
      <div
        ref={ref}
        className={cn(
          'flex flex-wrap items-center justify-center gap-x-4',
          verticalAlign === 'top' ? 'pb-3' : 'pt-3',
          className
        )}
        {...props}
      >
        {/* Map through payload items to render legend entries */}
        {payload.map((item) => {
          // Get key for this legend item
          const key = String(nameKey || item.dataKey || 'value')
          // Get config for this item from payload
          const itemConfig = getPayloadConfigFromPayload(config, item, key)

          return (
            <div
              key={String(item.value)}
              className={cn('flex items-center gap-1.5 [&>svg]:size-3 [&>svg]:text-gray-50')}
            >
              {/* Show either custom icon or default color box */}
              {itemConfig?.icon && !hideIcon ? (
                <itemConfig.icon />
              ) : (
                <div className='size-2 shrink-0 rounded-[2px]' style={{ backgroundColor: item.color }} />
              )}
              {itemConfig?.label}
            </div>
          )
        })}
      </div>
    )
  }
)
ChartLegendContent.displayName = 'ChartLegendContent'

interface ChartTooltipContentProps extends React.ComponentProps<'div'> {
  active?: boolean
  color?: string
  formatter?: (value: unknown, name: string | number, item: unknown, index: number) => React.ReactNode
  hideIndicator?: boolean
  hideLabel?: boolean
  indicator?: 'line' | 'dot' | 'dashed'
  label?: React.ReactNode
  labelClassName?: string
  labelFormatter?: (label: unknown, payload: PayloadItem[]) => React.ReactNode
  labelKey?: string
  nameKey?: string
  payload?: PayloadItem[]
}

// ChartTooltipContent is a customizable tooltip component for charts
// It displays values and labels for data points when hovering over chart elements
const ChartTooltipContent = React.forwardRef<HTMLDivElement, ChartTooltipContentProps>(
  (
    {
      active, // Whether the tooltip is currently active/visible
      className, // Additional CSS classes
      color, // Override color for tooltip indicators
      formatter, // Custom formatter function for tooltip values
      hideIndicator = false, // Hide the color indicator dot/line
      hideLabel = false, // Hide the tooltip label
      indicator = 'dot', // Indicator style: 'dot', 'line', or 'dashed'
      label, // Custom label content
      labelClassName, // Additional CSS classes for the label
      labelFormatter, // Custom formatter function for the label
      labelKey, // Key to use for label lookup
      nameKey, // Key to use for name lookup
      payload, // Data payload containing values to display
      ...props
    },
    ref
  ) => {
    const { config } = useChart()

    // Calculate the tooltip label based on configuration and props
    const tooltipLabel = React.useMemo(() => {
      if (hideLabel || !payload?.length) return null

      const [item] = payload
      const key = `${labelKey || item?.dataKey || item?.name || 'value'}`
      const itemConfig = item ? getPayloadConfigFromPayload(config, item, key) : undefined
      const value = !labelKey && isString(label) ? config[label]?.label || label : itemConfig?.label

      if (labelFormatter) {
        return <div className={cn('font-medium', labelClassName)}>{labelFormatter(value, payload)}</div>
      }

      if (!value) return null

      return <div className={cn('font-medium', labelClassName)}>{value}</div>
    }, [config, hideLabel, label, labelClassName, labelFormatter, labelKey, payload])

    // Don't render if tooltip is inactive or has no data
    if (!active || !payload?.length) return null

    // Special layout case for single items with non-dot indicators
    const nestLabel = payload.length === 1 && indicator !== 'dot'

    return (
      <div
        ref={ref}
        className={cn(
          'grid min-w-32 items-start gap-2 rounded-lg border border-white bg-white px-2.5 py-1.5 text-xs shadow-xl',
          className
        )}
        {...props}
      >
        {/* Show label unless nested */}
        {nestLabel ? null : tooltipLabel}
        <div className='grid gap-1.5'>
          {/* Map through payload items and render each value */}
          {payload.map((item, index) => {
            const key = `${nameKey || item.name || item.dataKey || 'value'}`
            const itemConfig = getPayloadConfigFromPayload(config, item, key)
            const indicatorColor = color || (item.payload as Record<string, string>).fill || item.color

            return (
              <div
                key={String(item.dataKey)}
                className={cn(
                  'flex w-full flex-wrap items-stretch gap-2 [&>svg]:size-2.5 [&>svg]:text-gray-50',
                  indicator === 'dot' && 'items-center'
                )}
              >
                {/* Use custom formatter if provided, otherwise render default layout */}
                {formatter && item.value !== undefined && item.name ? (
                  formatter(item.value, item.name, item, index)
                ) : (
                  <>
                    {/* Render icon or color indicator */}
                    {itemConfig?.icon ? (
                      <itemConfig.icon />
                    ) : (
                      !hideIndicator && (
                        <div
                          className={cn('shrink-0 rounded-[2px] border-[--color-border] bg-[--color-bg]', {
                            'h-2.5 w-2.5': indicator === 'dot',
                            'w-1': indicator === 'line',
                            'w-0 border-[1.5px] border-dashed bg-transparent': indicator === 'dashed',
                            'my-0.5': nestLabel && indicator === 'dashed'
                          })}
                          style={
                            /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
                            {
                              '--color-bg': indicatorColor,
                              '--color-border': indicatorColor
                            } as React.CSSProperties
                          }
                        />
                      )
                    )}
                    {/* Render value and label */}
                    <div
                      className={cn(
                        'flex flex-1 justify-between leading-none',
                        nestLabel ? 'items-end' : 'items-center'
                      )}
                    >
                      <div className='grid gap-1.5'>
                        {nestLabel ? tooltipLabel : null}
                        <span className='text-gray-50'>{itemConfig?.label || item.name}</span>
                      </div>
                      {item.value && (
                        <span className='font-medium tabular-nums text-gray-50 px-1'>
                          {isNumber(item.value) ? item.value.toLocaleString() : item.value}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
)
ChartTooltipContent.displayName = 'ChartTooltipContent'

export { ChartContainer, ChartTooltip, ChartTooltipContent, ChartLegend, ChartLegendContent, ChartStyle }
