import { DarkModeContext } from './DarkModeContext'
import { getBrowserGlobals } from '@strise/react-utils'
import * as React from 'react'

const storageKey = 'system-dark-mode'

export const DarkModeContextProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const darkModeStorage = getBrowserGlobals()?.window.localStorage.getItem(storageKey)
  const value = React.useMemo(
    () => ({
      state: darkModeStorage === 'rgb(0, 0, 0)'
    }),
    [darkModeStorage]
  )

  return <DarkModeContext.Provider value={value}>{children}</DarkModeContext.Provider>
}
