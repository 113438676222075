import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconCertified = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M3 1h18v8.223c0 4.145-1.8 8.079-4.911 10.671L12 23.303l-4.089-3.407C4.801 17.302 3 13.367 3 9.223V1Zm2 2v6.223c0 3.573 1.553 6.936 4.192 9.135L12 20.698l2.808-2.34C17.448 16.16 19 12.796 19 9.223V3H5Zm11.855 4.52-4.625 7.605-4.344-3.88 1.332-1.49 2.552 2.278 3.376-5.553 1.709 1.04Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconCertified.displayName = 'IconCertified'
