import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconArrowDownSmall = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m12 16.5-4.707-4.707 1.414-1.414L11 12.672V7.085h2v5.585l2.293-2.292 1.414 1.414L12 16.5Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconArrowDownSmall.displayName = 'IconArrowDownSmall'
