import { t } from '@lingui/macro'
import { IconSend, Input } from '@strise/ui-components'
import * as React from 'react'

export const PortfolioHealthChat = () => {
  return (
    <div className='w-full lg:max-w-5xl absolute left-1/2 -translate-x-1/2 p-4 lg:py-20'>
      <Input
        className='bg-white rounded-lg border-gray-10 p-6 shadow-lg shadow-gray-20'
        placeholder={t`Ask Strise AML anything`}
        endIcon={<IconSend className='text-gray-20' size='sm' />}
      />
      {/* TODO: add chat dialog here */}
    </div>
  )
}
