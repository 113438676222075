import { useContext } from '@strise/react-utils'
import * as React from 'react'
import { ActiveFilters } from '~/components/Filter/ActiveFilters'
import { ContentViewContext } from '~/components/Layout/ContentViewContext'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { portfolioFilters } from '~/views/Portfolio/utils/portfolioFilters'

export const PortfolioFilterActive: React.FC<{
  toggleFilters: () => void
}> = ({ toggleFilters }) => {
  const { isSmallScreen } = useContext(ContentViewContext)
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const filteredFilters = Object.values(portfolioFilters).filter((value) => !!value.totalSelected(settings))

  return (
    <div className='flex min-h-[theme(height.header)] grow flex-wrap items-center px-4'>
      {!isSmallScreen && (
        <ActiveFilters
          filters={filteredFilters}
          value={settings}
          setValue={saveSettings}
          toggleEdit={toggleFilters}
          chipProps={{ variant: 'contained', palette: 'tertiary', className: 'mr-2 my-2' }}
        />
      )}
    </div>
  )
}
