import { FocusRootButton, ScrollToLastNodeButton, ZoomButton } from './Controls'
import { ExpandSubsidiariesNode } from './ExpandSubsidiariesNode'
import { OwnerNode } from './OwnerNode'
import { OwnershipEdge } from './OwnershipEdge'
import { RootNode } from './RootNode'
import { SubsidiaryEdge } from './SubsidiaryEdge'
import { SubsidiaryNode } from './SubsidiaryNode'
import { centerOnRootNode, useOwnershipGraph } from './ownerUtils'
import { NODE_HEIGHT, NODE_WIDTH } from './sizes'
import { useSubsidiaries } from './subsidiaryUtils'
import {
  type OwnershipChart as ChartType,
  type ControlButtons,
  type RenderNodeContent,
  type RootEntity,
  type Subsidiary
} from './types'
import { type SetStateFn } from '@strise/react-utils'
import { Button, cn } from '@strise/ui-components'
import {
  Background,
  BackgroundVariant,
  MiniMap,
  Panel,
  ReactFlow,
  type ReactFlowInstance,
  type ReactFlowProps,
  getNodesBounds
} from '@xyflow/react'
import * as React from 'react'

export interface OwnershipChartProps<T extends ChartType, K extends Subsidiary<K>> {
  /** If true, the chart container will automatically resize to fit all the nodes */
  autoSize?: boolean
  /** Titles passed to the control buttons */
  buttonTitles?: Partial<Record<ControlButtons, string>>
  /** The chart you want to render. This will contain all the nodes and edges in the chart */
  chart: T
  /** Classname for the chart wrapper. Use this to control the size of the chart, the default size is 800px width and height
   *  Avoid adding padding or margin to the chart wrapper, as this will break the layout, instead wrap the chart in a div and add padding to that div
   *  The chart requires a fixed size to work properly
   */
  className?: string
  /** Disable the controls, enabled by default */
  disableControls?: boolean
  /** Disable the minimap, enabled by default */
  disableMinimap?: boolean
  /** The id of the node to focus on. This overrides `focusRootNode` */
  focusNodeId?: string
  /** If true, the root node will be focused on init. This is overridden by `focusNodeId` */
  focusRootNode?: boolean
  /** Use this function for formatting the share percentage of subsidiary nodes. Default formatting just appends a % after the subsidiary's `sharePercentage` */
  formatOwnership?: (percentage: number) => React.ReactNode
  /** Called when react flow's onInit is called */
  onChartLoaded?: () => void
  /** Called when the user clicks the enter full screen button above the minimap */
  onEnterFullScreen?: () => void
  /** Use this function to load the subsidiaries for a node.
   *  This is used when the user clicks on the expand button on a node and for the first level of subsidiaries.
   */
  onExpandSubsidiaries?: (id: string) => Promise<K[]>
  /** Props to pass to the react-flow component. see https://reactflow.dev/api-reference/react-flow */
  reactFlowProps?: ReactFlowProps
  /**
   * Use this function to customize how you want the nodes in your chart to look.
   * It's important to note that the nodes need to have the correct width and height for the layout to work properly.
   * This function is passed an object with the following properties:
   * - `data` - The original data object for the node
   * - `ownershipEdge` - Use this to find the matching edge for the node. This is useful if you want to display data from the edge on the node.
   * - `className` - The default class name for the node. It's recommended to use this and extend it.
   * - `width` - The width of the node. It's important that this is passed to whatever element you use to render the node.
   * - `height` - The height of the node. It's important that this is passed to whatever element you use to render the node.
   */
  renderNodeContent?: RenderNodeContent<T['nodes'][number]>
  /**
   * Use this function to customize how you want root node of your chart to look.
   * It's important to note that the nodes need to have the correct width and height for the layout to work properly.
   * This function is passed an object with the following properties:
   * - `data` - The original data object for the node
   * - `className` - The default class name for the node. It's recommended to use this and extend it.
   * - `width` - The width of the node. It's important that this is passed to whatever element you use to render the node.
   * - `height` - The height of the node. It's important that this is passed to whatever element you use to render the node.
   */
  renderRootNodeContent?: RenderNodeContent<RootEntity>
  /**
   * Use this function to customize how you want root node of your chart to look.
   * It's important to note that the nodes need to have the correct width and height for the layout to work properly.
   * This function is passed an object with the following properties:
   * - `data` - The original data object for the node
   * - `className` - The default class name for the node. It's recommended to use this and extend it.
   * - `width` - The width of the node. It's important that this is passed to whatever element you use to render the node.
   * - `height` - The height of the node. It's important that this is passed to whatever element you use to render the node.
   */
  renderSubsidiaryNodeContent?: RenderNodeContent<K>
  /** The root entity of the chart. */
  rootEntity: RootEntity
  /** Set the focus node id */
  setFocusNodeId?: SetStateFn<string | null>
}

const nodeTypes = {
  owner: OwnerNode,
  root: RootNode,
  subsidiary: SubsidiaryNode,
  expandSubsidiaries: ExpandSubsidiariesNode
}

const edgeTypes = {
  ownership: OwnershipEdge,
  subsidiary: SubsidiaryEdge
}

/** For the chart to work, it needs some base styles from @xyflow/react
 * Include the styles like this: import '@strise/app-shared/src/react-flow.css'
 */
export const OwnershipChart = <T extends ChartType, K extends Subsidiary<K>>({
  autoSize,
  buttonTitles,
  chart,
  className,
  disableControls,
  disableMinimap,
  focusNodeId,
  focusRootNode,
  formatOwnership,
  onChartLoaded,
  onEnterFullScreen,
  onExpandSubsidiaries,
  reactFlowProps,
  renderNodeContent,
  renderRootNodeContent,
  renderSubsidiaryNodeContent,
  rootEntity,
  setFocusNodeId
}: OwnershipChartProps<T, K>): React.ReactNode => {
  const flowInstanceRef = React.useRef<ReactFlowInstance | null>(null)

  const { ownerEdges, ownerNodes, rootNode } = useOwnershipGraph(
    chart,
    rootEntity,
    renderNodeContent,
    renderRootNodeContent
  )

  const { subsidiaryEdges, subsidiaryNodes } = useSubsidiaries(
    rootNode,
    onExpandSubsidiaries,
    renderSubsidiaryNodeContent,
    formatOwnership
  )

  const nodes = [...ownerNodes, ...subsidiaryNodes]
  const edges = [...ownerEdges, ...subsidiaryEdges]

  const { height, width } = getNodesBounds(nodes)

  const scrollToFocusNode = React.useCallback(
    (flowInstance: ReactFlowInstance) => {
      if (focusNodeId) {
        const focusNode = nodes.find((node) => node.id === focusNodeId)
        if (focusNode) {
          flowInstance.setCenter(focusNode.position.x + NODE_WIDTH / 2, focusNode.position.y + NODE_HEIGHT / 2, {
            zoom: 0.8,
            duration: 1500
          })
          setFocusNodeId?.(null)
        }
      }
    },
    // Ignoring rule as we use JSON.stringify: https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focusNodeId, JSON.stringify(nodes), setFocusNodeId]
  )

  React.useEffect(() => {
    if (focusNodeId && flowInstanceRef.current) {
      scrollToFocusNode(flowInstanceRef.current)
    }
  }, [focusNodeId, scrollToFocusNode])

  return (
    <div className={cn('size-[800px]', className)} style={autoSize ? { height, width } : undefined}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        proOptions={{ hideAttribution: true }}
        onInit={(flowInstance) => {
          flowInstanceRef.current = flowInstance
          if (focusNodeId) {
            scrollToFocusNode(flowInstance)
          } else if (focusRootNode) {
            centerOnRootNode(rootNode, flowInstance.setCenter, false)
          }
          onChartLoaded?.()
        }}
        fitView={autoSize}
        panOnScrollSpeed={1}
        {...reactFlowProps}
      >
        <Background variant={BackgroundVariant.Dots} />
        {/* Position above the minimap */}
        {onEnterFullScreen && (
          <Panel position='bottom-right' className='!bottom-[150px] !z-10 flex w-[200px] justify-center !p-0'>
            <Button
              data-track='Sidepanel / Ownerships / Toggle Fullscreen (minimap)'
              size='sm'
              className='w-full bg-white'
              variant='outlined'
              onClick={onEnterFullScreen}
            >
              {buttonTitles?.fullscreen}
            </Button>
          </Panel>
        )}
        {!disableMinimap && <MiniMap pannable ariaLabel='Minimap' />}
        {!disableControls && (
          <Panel
            position='bottom-left'
            className='!m-0 flex flex-col rounded-sm border border-secondary-shade-20 bg-white'
          >
            <ZoomButton zoomVariant='in' title={buttonTitles?.['zoom-in']} />
            <ZoomButton zoomVariant='out' title={buttonTitles?.['zoom-out']} />
            <FocusRootButton node={rootNode} title={buttonTitles?.['focus-root']} />
            {subsidiaryNodes.length > 0 && (
              <ScrollToLastNodeButton
                view={{ nodes: [{ id: nodes.at(-1)?.id ?? '' }], maxZoom: 1, duration: 500 }}
                title={buttonTitles?.['scroll-to-last-node']}
              />
            )}
          </Panel>
        )}
      </ReactFlow>
    </div>
  )
}
