import { Typography } from '@strise/ui-components'
import React from 'react'
import { ReviewRole } from '~/components/Review/items/ReviewRole'
import { type ReviewRoleSectionFragment } from '~/graphqlTypes'

export const ReviewRoleSection = ({ section }: { section: ReviewRoleSectionFragment }): React.ReactNode => {
  return (
    <div className='pt-2'>
      <Typography variant='aLabelBold'>{section.roleTitle}</Typography>
      {section.roles.map((item, index) => {
        if (!item.value) return null
        return <ReviewRole key={index} item={item} />
      })}
    </div>
  )
}
