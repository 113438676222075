import { baseButtonAndInputClasses } from './common'
import { cva } from 'class-variance-authority'

export const inputVariants = cva('flex h-10 items-center bg-transparent text-md text-inherit', {
  variants: {
    variant: {
      outlined: 'border',
      contained: undefined,
      ghost: undefined
    },
    palette: baseButtonAndInputClasses.borders,
    disabled: {
      false: undefined,
      true: baseButtonAndInputClasses.disabled.base
    }
  },
  compoundVariants: [
    // Contained
    {
      variant: 'contained',
      palette: 'primary',
      className: baseButtonAndInputClasses.contained.primary
    },
    {
      variant: 'contained',
      palette: 'secondary',
      className: baseButtonAndInputClasses.contained.secondary
    },
    {
      variant: 'contained',
      palette: 'tertiary',
      className: baseButtonAndInputClasses.contained.tertiary
    },
    // Ghost
    {
      variant: 'ghost',
      palette: 'primary',
      className: baseButtonAndInputClasses.ghost.primary
    },
    {
      variant: 'ghost',
      palette: 'secondary',
      className: baseButtonAndInputClasses.ghost.secondary
    },
    {
      variant: 'ghost',
      palette: 'tertiary',
      className: baseButtonAndInputClasses.ghost.tertiary
    },
    {
      variant: 'ghost',
      palette: 'danger',
      className: baseButtonAndInputClasses.ghost.danger
    },
    // Disabled
    {
      variant: 'contained',
      disabled: true,
      className: baseButtonAndInputClasses.disabled.contained
    },
    {
      variant: 'outlined',
      disabled: true,
      className: baseButtonAndInputClasses.disabled.contained
    }
  ],
  defaultVariants: {
    variant: 'outlined',
    palette: 'tertiary'
  }
})
