import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconNightTime = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke='currentColor'
        strokeWidth={2}
        d='M12 22c5.523 0 10-4.477 10-10 0-5.05-3.742-9.224-8.604-9.903l.36.4A8.783 8.783 0 0 1 16 8.362v.81A6.829 6.829 0 0 1 9.172 16h-.81c-2.165 0-4.253-.8-5.865-2.245l-.4-.359C2.776 18.258 6.95 22 12 22Z'
      />
    </svg>
  )
)
IconNightTime.displayName = 'IconNightTime'
