import { Trans } from '@lingui/macro'
import { type TeamReviewSettingsV2Fragment } from '@strise/app-shared/src/graphqlTypes'
import { AuditRisk, ReviewSettingKind } from '@strise/types'
import { Divider, Skeleton, Tooltip, Typography } from '@strise/ui-components'
import React from 'react'
import { AuditRiskLabel } from '~/components/Risk/AuditRiskLabel'
import { CustomRiskLabel } from '~/components/Risk/CustomRiskLabel'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useTeamId } from '~/contexts/TeamContext/TeamContext'
import { useCalculateRiskLevelQuery } from '~/graphqlOperations'

interface ReviewAutomatedRiskRatingProps {
  entityId: string,
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}

export const ReviewAutomatedRiskRatingSection = ({
  entityId,
  teamReviewSettings
}: ReviewAutomatedRiskRatingProps): React.ReactNode => {
  const features = useCurrentUserFeatures()

  const team = useTeamId()
  const { data, loading } = useCalculateRiskLevelQuery({
    variables: { team, entity: entityId },
    skip: !features.CUSTOM_RISK_LEVELS && !features.AUTOMATED_RISK_ENGINE
  })

  if (
    !teamReviewSettings.settings.find(
      (setting) => setting.kind === ReviewSettingKind.EnableRiskAssessmentValue && features.AUTOMATED_RISK_ENGINE
    )?.enabled
  )
    return null

  const demoRiskLevel = data?.calculateRiskLevel
  // Static demo value - legacy AuditRisk will be removed eventually
  const demoAuditRisk = AuditRisk.Low

  return (
    <div className='mb-12'>
      <div className='flex items-center mt-4 mb-2'>
        <Typography variant='subtitle1' className='mr-20'>
          <Trans>Financial Risk Rating</Trans>
        </Typography>
        <Tooltip
          content={
            <Trans>
              Calculated based on the company's geographical location, industry, and legal form configured in your
              team's settings
            </Trans>
          }
        >
          <div>
            {features.CUSTOM_RISK_LEVELS && loading && <Skeleton className='h-10 w-96' />}
            {features.CUSTOM_RISK_LEVELS && data && demoRiskLevel ? (
              <CustomRiskLabel riskLevel={demoRiskLevel} />
            ) : (
              <AuditRiskLabel risk={demoAuditRisk} />
            )}
          </div>
        </Tooltip>

        <Divider />
      </div>
      <Typography>
        <Trans>Automatically calculated based on company standards</Trans>
      </Typography>
    </div>
  )
}
