import { Trans, t } from '@lingui/macro'
import { type DivProps } from '@strise/react-utils'
import { Button, IconConnect, Tooltip, Typography, cn } from '@strise/ui-components'
import * as React from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { PersonReviewInfo } from '~/components/PersonReviewInfo'
import { ShareClasses } from '~/components/Sidepanel/SidepanelCards/ShareClasses'
import { useScrollToEntityOwnershipChartNode } from '~/components/Sidepanel/SidepanelContext/useScrollToOwnershipChartNode'
import { useIsReview } from '~/contexts/RenderContext/RenderContext'
import { type BaseEntityLikeFragment, type BeneficialOwnerMetaFragment, type CustomMetaFragment } from '~/graphqlTypes'
import { CustomMeta } from '~/utils/apiTable/CustomMeta'
import { useIsMergeableEntity } from '~/utils/entity'

export const OwnerDetail = ({
  className,
  customMeta,
  entity,
  entityName,
  ownership,
  rootEntityId,
  shareClasses,
  uboMeta,
  ...props
}: {
  customMeta: CustomMetaFragment | null | undefined
  entity: BaseEntityLikeFragment | null | undefined
  entityName: string
  ownership: string | null | undefined
  rootEntityId: string
  shareClasses: string[] | null
  uboMeta: BeneficialOwnerMetaFragment | null | undefined
} & DivProps): React.ReactNode => {
  const isReview = useIsReview()
  const scrollToOwnershipChartNode = useScrollToEntityOwnershipChartNode(rootEntityId)
  const isMergeable = useIsMergeableEntity(entity)
  const customOwnershipMeta = customMeta ?? null

  const assumedFamilyElement =
    uboMeta?.familyRelationships.map((familyRelationship, index) => {
      const relationshipsTitle = familyRelationship.translatedRelationships.join(', ')

      return (
        <div className='flex items-center pl-6' key={index}>
          <EntityLink
            disableOnNoAccess
            entity={familyRelationship.person}
            className='text-text-primary underline'
            noFlags
            noLifeStatus
            wrapperProps={{ className: 'list-item' }}
          />
          <Typography className='ml-1'>({relationshipsTitle})</Typography>
        </div>
      )
    }) ?? null

  return (
    <div className='flex w-full items-center justify-evenly'>
      <div
        className={cn(
          'my-1 mr-1 w-full rounded',
          { 'px-2': customOwnershipMeta },
          customOwnershipMeta ? 'border-dashed border-accent-blue-shade-5' : 'border-none',
          className
        )}
        {...props}
      >
        <div>
          <div className='grid'>
            <div>
              {entity ? (
                <div className='flex flex-col'>
                  <EntityLink disableOnNoAccess entity={entity} noLink={isMergeable} />
                  {isReview && (entity.__typename === 'Person' || entity.__typename === 'GlobalPerson') && (
                    <PersonReviewInfo person={entity} />
                  )}
                </div>
              ) : (
                <Typography>{entityName}</Typography>
              )}
            </div>
          </div>
          <div className='mt-2 flex'>
            {ownership && (
              <div className='max-w-[190px] grow'>
                <Typography>
                  <Trans>Ownership</Trans>: {ownership}
                </Typography>
              </div>
            )}

            {uboMeta?.meta && (
              <div className='ml-2 max-w-[190px] grow'>
                <Typography>
                  {uboMeta.meta.name}: {uboMeta.meta.value}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {uboMeta?.reason && (
          <div className='mt-2'>
            <Typography>{uboMeta.reason}</Typography>
            {assumedFamilyElement}
          </div>
        )}

        <ShareClasses shareClasses={shareClasses ?? []} className='mt-2' />
      </div>
      {customMeta && (
        <div>
          <CustomMeta customMeta={customMeta} />
        </div>
      )}
      {isMergeable && (
        <Tooltip content={t`This ownership has unresolved missing entity connections.`}>
          <Button
            endIcon={<IconConnect className='ml-1' size='sm' />}
            onClick={(event) => {
              scrollToOwnershipChartNode(entity.id, event.ctrlKey || event.metaKey)
            }}
            palette='primary'
            variant='ghost'
            className='hover:bg-transparent active:bg-transparent focus:bg-transparent text-sm whitespace-nowrap hover:underline'
            data-track='Review / Owner Detail / Mergeable Entity / Open'
          >
            {t`Connect owner`}
          </Button>
        </Tooltip>
      )}
    </div>
  )
}
