import { t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { type IdentityVerificationStatus } from '@strise/types'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IconSend,
  Typography
} from '@strise/ui-components'
import * as React from 'react'
import { IDVUrl } from '~/components/IDVCheck/IDVUrl'
import { IdvCheckResetButton } from '~/components/IDVCheck/IdvCheckResetButton'
import { type IdvRoleFragment } from '~/graphqlTypes'

interface IdvCheckModalPendingDocumentProps {
  children: React.ReactNode
  loadingStatus: IdentityVerificationStatus | null
  onUpdateStatus: (status: IdentityVerificationStatus) => Promise<void>
  onUrlCopy: () => void
  role: IdvRoleFragment
  url: string | null
}

export const IdvCheckModalPendingDocument = ({
  children,
  loadingStatus,
  onUpdateStatus,
  onUrlCopy,
  role,
  url
}: IdvCheckModalPendingDocumentProps): React.ReactNode => {
  const [open, setOpen] = React.useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='flex items-center gap-2'>
            <IconSend />
            {t`Waiting for documents`}
          </DialogTitle>
          <DialogDescription className='flex flex-col gap-4'>
            <Typography>
              {t`ID is requested and`} <span className='font-bold'>{role.person.name ?? t`Unknown`}</span>{' '}
              {t`has been notified by email`}.
            </Typography>
            <IDVUrl url={url} onUrlCopy={onUrlCopy} />
            <div className='flex flex-col items-start justify-start gap-x-1'>
              <Typography>
                {t`Data notified`}:{' '}
                <span className='font-bold'>
                  {role.idvRequest?.documentNotifiedAt
                    ? formatDate(role.idvRequest.documentNotifiedAt, { relative: false, time: true })
                    : t`Now`}
                </span>
              </Typography>
              <Typography>
                {t`Link expires`}:{' '}
                <span className='font-bold'>
                  {role.idvRequest?.linkExpiresAt
                    ? formatDate(role.idvRequest.linkExpiresAt, { relative: false, time: true })
                    : t`N/A`}
                </span>
              </Typography>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='sm:justify-start'>
          <IdvCheckResetButton onUpdateStatus={onUpdateStatus} loadingStatus={loadingStatus} setOpen={setOpen} />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
