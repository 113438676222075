import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconExpand = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M5 11h18v2H5v-2Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m3.586 12 8.707-8.707 1.414 1.414L6.414 12l7.293 7.293-1.414 1.414L3.586 12ZM1 23V1h2v22H1Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconExpand.displayName = 'IconExpand'
