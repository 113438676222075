import { EntityDispositionStatusKind } from '@strise/types'
import type { PepInfoFragment } from '~/graphqlTypes'

export const systemSuggestedTruePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  !!pepInfo.matchInfoAnalysis?.suggestedTruePositive && !pepInfo.disposition

export const systemSuggestedFalsePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  !pepInfo.matchInfoAnalysis?.suggestedTruePositive && !pepInfo.disposition

export const userConfirmedFalsePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  pepInfo.disposition?.status === EntityDispositionStatusKind.ConfirmedFalse

export const userConfirmedTruePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  pepInfo.disposition?.status === EntityDispositionStatusKind.ConfirmedTrue
