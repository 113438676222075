import { Trans } from '@lingui/macro'
import { Typography } from '@strise/ui-components'
import React from 'react'
import { useToggle } from 'usehooks-ts'
import { CreditScoreIndicator } from '~/components/CreditScore/CreditScoreIndicator'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { CreditReportMonitorButton } from '~/components/Sidepanel/SidepanelCards/CreditReport/CreditReportMonitorButton'
import { SidepanelCreditScoreHelpModal } from '~/components/Sidepanel/SidepanelCards/CreditReport/SidepanelCreditScoreHelpModal'
import { type CompanyMetaFragment, type CreditReportFragment } from '~/graphqlTypes'
import { SidepanelTab } from '~/utils/urls'

export type BaseCreditScore = Omit<
  CreditReportFragment,
  'paymentRemarks' | 'incomes' | 'collateral' | 'totalDebtBalance'
>

export const CreditScore = ({
  company,
  creditReport
}: {
  company: CompanyMetaFragment | null
  creditReport: BaseCreditScore
}): React.ReactNode => {
  const [helpModalOpen, toggleHelpModalOpen] = useToggle(false)
  const hasPaymentRemarks = !!creditReport.hasPaymentRemarks

  return (
    <div className='grid w-full grid-cols-[1.5fr_150px_1fr] items-center justify-between gap-6 py-2'>
      <div>
        <CreditScoreRow label={<Trans>Credit limit</Trans>} value={creditReport.creditLimit} />
        {!!creditReport.contractLimit && (
          <CreditScoreRow label={<Trans>Contract limit</Trans>} value={creditReport.contractLimit} />
        )}
        {!!creditReport.dbt && <CreditScoreRow label={<Trans>DBT</Trans>} value={creditReport.dbt} />}
        {!!creditReport.industryDbt && (
          <CreditScoreRow label={<Trans>Industry DBT</Trans>} value={creditReport.industryDbt} />
        )}
        {creditReport.averageInvoiceValue != null && (
          <CreditScoreRow label={<Trans>Average invoice value</Trans>} value={creditReport.averageInvoiceValue} />
        )}
        {creditReport.paymentsOnFile != null && (
          <CreditScoreRow label={<Trans>Invoices available</Trans>} value={creditReport.paymentsOnFile} />
        )}
        {creditReport.paymentsPaid != null && (
          <CreditScoreRow label={<Trans>Invoices paid</Trans>} value={creditReport.paymentsPaid} />
        )}
        {creditReport.paymentsStillOwing != null && (
          <CreditScoreRow label={<Trans>Invoices outstanding</Trans>} value={creditReport.paymentsStillOwing} />
        )}
        <CreditScoreRow
          label={<Trans>Payment remarks</Trans>}
          value={hasPaymentRemarks ? <Trans>Yes</Trans> : <Trans>No</Trans>}
        />
      </div>
      <CreditScoreIndicator
        className='mb-0'
        size='small'
        score={creditReport.ratingValue}
        scoreCharacter={creditReport.internationalRating}
        toggleHelpModalOpen={toggleHelpModalOpen}
        disableAnimation
        isFetched
      />

      {company && (
        <div className='grid place-items-center gap-2 self-center'>
          <CreditReportMonitorButton
            company={company}
            hasCreditReport={!!creditReport}
            isMonitored={creditReport.isMonitored}
            context='Review'
          />
          <EntityLink
            entity={company}
            sidepanelTab={SidepanelTab.Credit}
            noFlags
            noLifeStatus
            noTooltip
            className='text-center'
          >
            <Typography variant='aLabel'>
              <Trans>See details</Trans>
            </Typography>
          </EntityLink>
        </div>
      )}
      {helpModalOpen && <SidepanelCreditScoreHelpModal handleClose={toggleHelpModalOpen} />}
    </div>
  )
}

const CreditScoreRow = ({ label, value }: { label: React.ReactNode; value: React.ReactNode }): React.ReactNode => {
  return (
    <div className='mb-2 flex items-center justify-between gap-1'>
      <Typography variant='aLabelSmall'>{label}</Typography>
      <Typography className='text-right' variant='aLabelSmallBold'>
        {value}
      </Typography>
    </div>
  )
}
