import { Trans, t } from '@lingui/macro'
import { Empty, toast } from '@strise/app-shared'
import { triggerBase64Download } from '@strise/react-utils'
import { filterNullishValues, formatNumber } from '@strise/ts-utils'
import { Button, IconDownload, LoaderRound, Typography } from '@strise/ui-components'
import * as React from 'react'
import { useIdvDocumentInfosQuery, useIdvDocumentsLazyQuery } from '~/graphqlOperations'
import { type IdvDocumentInfoFragment, type IdvDocumentsQuery, type IdvRoleFragment } from '~/graphqlTypes'

const IdvCheckDocument = ({ documentInfo, role }: { documentInfo: IdvDocumentInfoFragment; role: IdvRoleFragment }) => {
  const handleDownload = (documentsData: IdvDocumentsQuery) => {
    const document = documentsData.idvDocuments[0]

    if (!document) {
      toast.error(t`No documents to download`)
      return
    }

    triggerBase64Download(
      document,
      documentInfo.name || crypto.randomUUID(),
      documentInfo.contentType ?? 'application/octet-stream'
    )
  }

  const [fetchDocument, { loading }] = useIdvDocumentsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: handleDownload
  })

  const handleFetchDocument = () => {
    if (!role.idvRequest?.id) {
      toast.error(t`No IDV request found`)
      return
    }
    fetchDocument({ variables: { id: role.idvRequest.id, documentReferences: [documentInfo.reference] } })
  }

  const prettyContentType = documentInfo.contentType?.split('/')[1]?.toUpperCase() ?? documentInfo.contentType
  const prettySize = documentInfo.size ? `${formatNumber(documentInfo.size / 1024)} KB` : null

  const metadata = filterNullishValues([prettyContentType, prettySize]).join(' - ')

  return (
    <div className='flex items-center justify-between rounded-sm bg-background-default p-3 w-full'>
      <div className='flex flex-col'>
        <Typography component='span' className='text-sm font-medium'>
          {documentInfo.name || t`No file name`}
        </Typography>
        <Typography component='span' className='text-xs text-gray-70'>
          {metadata || t`No metadata`}
        </Typography>
      </div>
      <Button
        data-track={`IDV Check / Download ${documentInfo.name}`}
        variant='outlined'
        palette='secondary'
        size='xs'
        loading={loading}
        loaderProps={{ size: 'sm', className: 'mr-1' }}
        startIcon={<IconDownload size='sm' className='mr-1' />}
        onClick={handleFetchDocument}
      >
        {t`Download`}
      </Button>
    </div>
  )
}

export const IdvCheckDocuments = ({ role }: { role: IdvRoleFragment }) => {
  const { data: documentInfosData, loading: documentInfosLoading } = useIdvDocumentInfosQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    variables: { id: role.idvRequest?.id! },
    skip: !role.idvRequest?.id,
    fetchPolicy: 'network-only'
  })

  const documentInfos = documentInfosData?.idvDocumentsInformation ?? []

  return (
    <div className='mt-4 flex flex-col gap-3 items-center'>
      {documentInfosLoading && (
        <>
          <Typography>
            <Trans>Loading documents</Trans>...
          </Typography>
          <LoaderRound size='sm' />
        </>
      )}
      {!documentInfosLoading && !documentInfos.length && <Empty title={t`No documents found`} />}
      {!documentInfosLoading &&
        documentInfos.map((documentInfo) => {
          return <IdvCheckDocument key={documentInfo.reference} role={role} documentInfo={documentInfo} />
        })}
    </div>
  )
}
