import { t } from '@lingui/macro'
import { Link, Typography } from '@strise/ui-components'
import * as React from 'react'
import { OpenChatLink } from '~/components/ContactStrise'
import { trackError } from '~/utils/errorTracking'

export const ErrorView = (): React.ReactNode => {
  React.useEffect(() => {
    trackError.unhandled('Error page shown to user', '')
  }, [])

  return (
    <div className='mx-auto flex h-screen max-w-[600px] flex-col items-center justify-center text-center'>
      <Typography className='mb-4' variant='h2'>{t`Oh, something went wrong`}</Typography>
      <Typography>{t`Try reload Strise! If the issue persists please contact us!`}</Typography>

      <div className='mt-5 flex gap-2'>
        <Link variant='subtitle1' className='text-text-link underline' href='/'>{t`Reload`}</Link>
        <Typography className='text-text-primary' component='span'>{t`or`}</Typography>
        <OpenChatLink variant='subtitle1' className='underline'>{t`Contact us`}</OpenChatLink>
      </div>
    </div>
  )
}
