import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconAscendingDescending = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M9 2v20H7V2h2ZM15 22V2h2v20h-2Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m8 .586 4.707 4.707-1.414 1.414L8 3.414 4.707 6.707 3.293 5.293 8 .586ZM16 23.414l-4.707-4.707 1.414-1.414L16 20.586l3.293-3.293 1.414 1.414L16 23.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconAscendingDescending.displayName = 'IconAscendingDescending'
