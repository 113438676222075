import { t } from '@lingui/macro'
import { Duration } from '@strise/types'

export const getDurationTitles = () => ({
  [Duration.Day]: t`Review last 24 hours`,
  [Duration.Week]: t`Review last 7 days`,
  [Duration.Month]: t`Review last 30 days`,
  [Duration.HalfYear]: t`Review last 6 months`,
  [Duration.Year]: t`Review last year`,
  [Duration.TwoYear]: t`Review last 2 years`,
  [Duration.ThreeYear]: t`Review last 3 years`,
  [Duration.FourYear]: t`Review last 4 years`,
  [Duration.FiveYear]: t`Review last 5 years`
})

export const getDurationTitle = (duration: Duration): string => {
  return getDurationTitles()[duration] || t`Review`
}

export const getDurationLabel = (duration: Duration): string =>
  ({
    [Duration.Day]: t`Last 24 hours`,
    [Duration.Week]: t`Last 7 days`,
    [Duration.Month]: t`Last 30 days`,
    [Duration.HalfYear]: t`Last 6 months`,
    [Duration.Year]: t`Last year`,
    [Duration.TwoYear]: t`Last 2 years`,
    [Duration.ThreeYear]: t`Last 3 years`,
    [Duration.FourYear]: t`Last 4 years`,
    [Duration.FiveYear]: t`Last 5 years`
  })[duration] || t`Unknown duration`
