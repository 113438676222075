import { type DivProps } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import * as React from 'react'
import { type HTMLAttributes } from 'react'

export const Dim = ({
  className,
  ...props
}: DivProps & Pick<HTMLAttributes<HTMLDivElement>, 'onClick'>): React.ReactNode => (
  <div className={cn('fixed inset-0 z-[11] bg-secondary-main/80', className)} {...props} />
)
