import { type AnimatedIconProps, SvgMorphingWrapper } from '../SvgMorphingWrapper'
import { IconCollapseAnimatedA } from './IconCollapseAnimatedA'
import { IconCollapseAnimatedB } from './IconCollapseAnimatedB'
import * as React from 'react'

export const IconCollapseAnimated = React.forwardRef<SVGSVGElement, AnimatedIconProps>((props, ref) => (
  <SvgMorphingWrapper
    {...props}
    SvgComponentA={IconCollapseAnimatedA}
    SvgComponentB={IconCollapseAnimatedB}
    ref={ref}
  />
))
IconCollapseAnimated.displayName = 'IconCollapseAnimated'
