import { Trans, t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { Chip, Divider, IconButton, IconHelp, Tooltip, Typography, cn } from '@strise/ui-components'
import { Modal, ModalContent, ModalHeader } from '@strise/ui-components-legacy'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { type ReviewCcjValueFragment } from '~/graphqlTypes'

export const ReviewCCJ = ({ item }: { item: ReviewCcjValueFragment }): React.ReactNode => {
  const ccj = item.value
  const [helpModalOpen, toggleHelpModalOpen] = useToggle(false)

  if (!ccj) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const getStatusContent = (status: string | null, paidDate: string | null): React.ReactNode => {
    if (status === 'Satisfied') {
      return (
        <Tooltip
          arrow
          className='flex flex-col'
          content={
            <>
              <Trans>Settled date</Trans>: <span>{paidDate ? formatDate(paidDate) : t`Unknown`}</span>
            </>
          }
        >
          <Trans>Settled</Trans>
        </Tooltip>
      )
    }
    return status === 'Judgment' ? t`Pay due` : status
  }

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <div className='flex flex-col space-y-2'>
        {/* Match Information */}
        <div className='flex items-center'>
          <Typography variant='body1' className='font-medium'>
            <Trans>Match</Trans>:
          </Typography>
          <Tooltip content={ccj.incomingRecordDetails}>
            <Chip
              className={cn(ccj.isExactMatch && 'ml-2 bg-semantic-success-shade-10')}
              label={ccj.isExactMatch ? t`Exact` : t`Likely`}
            />
          </Tooltip>
          <IconButton
            className='ml-2 rounded-full text-text-secondary'
            onClick={toggleHelpModalOpen}
            data-track="CCJ's / Help modal / Open"
          >
            <IconHelp size='lg' />
          </IconButton>
        </div>

        {/* Court Information */}
        <div className='flex'>
          <Typography variant='body1' className='font-medium'>
            <Trans>Court</Trans>:
          </Typography>
          <Typography variant='body1' className='ml-2'>
            {ccj.court}
          </Typography>
        </div>

        {/* Case Update */}
        <div className='flex'>
          <Typography variant='body1' className='font-medium'>
            <Trans>Case update</Trans>:
          </Typography>
          <Typography variant='body1' className='ml-2'>
            {getStatusContent(ccj.status, ccj.paidDate)}
          </Typography>
        </div>

        {/* Amount */}
        <div className='flex'>
          <Typography variant='body1' className='font-medium'>
            <Trans>Amount</Trans>:
          </Typography>
          <Typography variant='body1' className='ml-2'>
            {ccj.amount != null && ccj.amount}
          </Typography>
        </div>

        {/* Date */}
        <div className='flex'>
          <Typography variant='body1' className='font-medium'>
            <Trans>Date</Trans>:
          </Typography>
          <Typography variant='body1' className='ml-2'>
            {ccj.date && formatDate(ccj.date)}
          </Typography>
        </div>
      </div>

      {/* Help Modal */}
      <MatchHelpModal isOpen={helpModalOpen} handleClose={toggleHelpModalOpen} />
    </ReviewItem>
  )
}

const gridClassName = 'grid grid-cols-[0.5fr_0.5fr_1fr] py-6'

const MatchHelpModal = ({ handleClose, isOpen }: { handleClose: () => void; isOpen: boolean }): React.ReactNode => {
  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} contentMaxWidth={1000}>
      <ModalHeader onClose={handleClose}>
        <Typography component='h1' variant='subtitle1'>
          <Trans>Match</Trans>
        </Typography>
      </ModalHeader>
      <ModalContent>
        <div className='flex flex-col p-10'>
          <Divider className='mt-10' />
          {/* Exact Match Explanation */}
          <div className={gridClassName}>
            <span>
              <Trans>Match</Trans>
            </span>
            <span>
              <Chip className='bg-semantic-success-shade-10' label={t`Exact`} />
            </span>
            <Typography>
              <Trans>
                This is a perfect match. The company name in our records exactly matches the one in CCJ records.
              </Trans>
            </Typography>
          </div>
          <Divider />
          {/* Likely Match Explanation */}
          <div className={gridClassName}>
            <span />
            <span>
              <Chip label={t`Likely`} />
            </span>
            <Typography>
              <Trans>
                Almost there! This means the company name is very close, but not an exact match. For example, if our
                record shows 'Shady Shark' but the actual name is 'Shady Shark Limited', our data provider tags it as
                'Likely'.
              </Trans>
            </Typography>
          </div>
          <Divider />
          {/* Tooltip Example */}
          <div className={gridClassName}>
            <span />
            <span>
              <Tooltip arrow content='Giffgaff, Hertz House, Vine Street, Uxbridge, ub8 1qe, ub8 1qe' open>
                <Chip label={t`Likely`} />
              </Tooltip>
            </span>
            <Typography>
              <Trans>Hover the 'Likely' tag to reveal data match details.</Trans>
            </Typography>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
