import { type RateLimitKind } from '@strise/types'
import { useCustomerId } from '~/contexts/TeamContext/TeamContext'
import { useLatestRateLimitQuery } from '~/graphqlOperations'
import { type LatestRateLimitQuery } from '~/graphqlTypes'

export const useLatestRateLimit = (
  kind: RateLimitKind,
  featureEnabled: boolean
): { data: LatestRateLimitQuery | undefined; loading: boolean } => {
  const customerId = useCustomerId()

  const { data, loading } = useLatestRateLimitQuery({
    fetchPolicy: 'network-only',
    variables: { customer: customerId, kind },
    skip: !featureEnabled
  })

  return { data, loading }
}
