import * as React from 'react'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { ContentViews } from '~/utils/urls'
import { useActiveContentView } from '~/utils/viewsHooks'
import { ActivityView } from '~/views/Activity/ActivityView'
import { GrowView } from '~/views/Grow/GrowView'
import { HomeView } from '~/views/Home/HomeView'
import { NotificationsView } from '~/views/Notifications/NotificationsView'
import { PortfolioView } from '~/views/Portfolio/PortfolioView'
import { ReviewView } from '~/views/ReviewView'

const contentViewToView: { [key in ContentViews]: React.FC } = {
  [ContentViews.Activity]: ActivityView,
  [ContentViews.Portfolio]: PortfolioView,
  [ContentViews.Review]: ReviewView,
  [ContentViews.Grow]: GrowView,
  [ContentViews.Notifications]: NotificationsView,
  [ContentViews.Home]: HomeView
}

export const ContentView = (): React.ReactNode => {
  const team = useTeam()
  const activeContentView = useActiveContentView()

  if (!activeContentView) return null

  const ActiveView = contentViewToView[activeContentView]

  // DO NOT remove `w-full` here. There is some sort of bug in PortfolioView that makes it grow in width indefinitely with out it
  return (
    <div className='w-full' key={[team.id, team.portfolioId].join('-')}>
      <ActiveView />
    </div>
  )
}
