import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconSettings = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M4.497 5.94a2 2 0 0 1 .732-2.732l2.607-1.506a2 2 0 0 1 2.732.732l.636 1.102a8.53 8.53 0 0 1 1.69.003l.663-1.123A2 2 0 0 1 16.28 1.7l2.611 1.507a2 2 0 0 1 .73 2.737l-.638 1.097c.16.224.31.455.45.692l.005.008.004.008c.14.247.266.5.38.76h1.211a2 2 0 0 1 2 2l-.002 3.01a2 2 0 0 1-1.996 2l-1.224.002a8.818 8.818 0 0 1-.878 1.5l.593 1.027a2 2 0 0 1-.733 2.732l-2.65 1.53a2 2 0 0 1-2.732-.732l-.605-1.048c-.583.06-1.17.058-1.752-.004l-.61 1.046a2 2 0 0 1-2.728.724L5.1 20.784a2 2 0 0 1-.731-2.73l.612-1.062a8.657 8.657 0 0 1-.424-.66l-.001-.002a8.538 8.538 0 0 1-.417-.823l-1.204.003a2 2 0 0 1-2.004-2h2l2.605-.005.231.678c.137.398.311.783.521 1.148.192.33.413.644.659.938l.45.536-1.296 2.248 2.615 1.51 1.31-2.246.698.138c.797.156 1.62.159 2.42.006l.699-.133 1.299 2.25 2.65-1.53-1.285-2.227.461-.538a6.826 6.826 0 0 0 1.233-2.104l.24-.655 2.59-.005v-3.01l-2.608-.002-.231-.676a6.53 6.53 0 0 0-.489-1.092 7.196 7.196 0 0 0-.69-.975l-.452-.539 1.33-2.286-2.611-1.506-1.36 2.302-.688-.129a6.532 6.532 0 0 0-2.377-.003l-.694.127-1.325-2.296L6.229 4.94 7.55 7.23l-.483.54a6.536 6.536 0 0 0-1.25 2.064l-.242.646-2.646.012.001 3.018h-2L.93 10.494a2 2 0 0 1 1.99-2l1.298-.007a8.536 8.536 0 0 1 .894-1.481L4.497 5.94Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-4 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconSettings.displayName = 'IconSettings'
