import * as React from 'react'

export const FlyIcon = (): React.ReactNode => (
  <svg xmlns='http://www.w3.org/2000/svg' width='174' height='36' fill='none'>
    <path
      fill='#B9B9B9'
      fillRule='evenodd'
      d='M167.535 2c0 .176-.03.344-.086.501l.086-.001a3 3 0 1 1-3 3l.001-.086a1.5 1.5 0 1 1 .145-2.769A1.5 1.5 0 1 1 167.535 2Zm-1.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-.646.854a.473.473 0 0 1 .016.034l.018-.018-.034-.016Zm-.854.646a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z'
      clipRule='evenodd'
    />

    <path
      stroke='#B9B9B9'
      strokeDasharray='2 2'
      d='m163.213 10-7.887 5.498a66.35 66.35 0 0 1-61.412 7.629l-16.086-6.083C71.088 14.494 72.915 4.5 80.12 4.5c7.521 0 9.007 10.66 1.772 12.716L45.425 27.58A41.303 41.303 0 0 1 .625 12'
    />
  </svg>
)
