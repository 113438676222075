import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconOverview = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M1 1h22v5h-2V3H3v18h5v2H1V1Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M13 7a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-8 6a8 8 0 1 1 14.32 4.906l3.387 3.387-1.414 1.414-3.387-3.387A8 8 0 0 1 5 13Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconOverview.displayName = 'IconOverview'
