import { t } from '@lingui/macro'
import { languageToLocale, toast } from '@strise/app-shared'
import { ContentType, triggerDownload } from '@strise/react-utils'
import {
  Button,
  DatePickerRange,
  type DateRange,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IconDownload,
  IconExport,
  LoaderRound,
  Tooltip,
  Typography
} from '@strise/ui-components'
import '@strise/ui-components/src/react-day-picker.css'
import { endOfDay, startOfDay } from 'date-fns'
import * as React from 'react'
import { useDisplayLanguage } from '~/contexts/DisplayLanguageContext/displayLanguageContextUtils'
import { useTeamUsageReportCsvLazyQuery } from '~/graphqlOperations'
import { type TeamUsageReportCsvQuery } from '~/graphqlTypes'

interface GenerateTeamUsageReportProps {
  teamId: string
  teamName: string
}

export const GenerateTeamUsageReport = ({ teamId, teamName }: GenerateTeamUsageReportProps): React.ReactNode => {
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>()
  const [open, setOpen] = React.useState(false)
  const displayLanguage = useDisplayLanguage()
  const locale = languageToLocale[displayLanguage]

  const handleDownload = (data: TeamUsageReportCsvQuery) => {
    try {
      if (!data.teamUsageReportCsv) {
        toast.error('No data to download')
        return
      }
      if (!dateRange?.from || !dateRange.to) {
        toast.error('Please select a date range')
        return
      }
      const teamUsageReportCsvTitle = `${teamName}_from:${dateRange.from.toISOString()}_to:${dateRange.to.toISOString()}`
      triggerDownload(data.teamUsageReportCsv, teamUsageReportCsvTitle, ContentType.CSV)
      setDateRange(undefined)
      toast.success(t`Report downloaded successfully`)
      setOpen(false)
    } catch (error) {
      console.error(error)
      toast.error(t`Error downloading report`)
    }
  }

  const [fetchCsv, { loading: csvLoading }] = useTeamUsageReportCsvLazyQuery({
    onCompleted: handleDownload,
    fetchPolicy: 'no-cache'
  })

  const handleConfirm = async (): Promise<void> => {
    if (!dateRange?.from || !dateRange.to) {
      toast.error(t`Please select a date range`)
      return
    }
    // Use start of day to include the full day
    const from = startOfDay(dateRange.from).toISOString()
    // Use end of day to include the full day
    const to = endOfDay(dateRange.to).toISOString()

    await fetchCsv({
      variables: {
        teamUsageReportCsvId: teamId,
        from,
        to
      }
    })
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value)
        if (!value) {
          setDateRange(undefined)
        }
      }}
    >
      <DialogTrigger>
        <Tooltip content={t`Export the team usage report`}>
          <Button variant='outlined' palette='tertiary' className='rounded-sm' data-track='Home / Export'>
            {t`Export`} <IconExport className='size-4 ml-2' />
          </Button>
        </Tooltip>
      </DialogTrigger>

      <DialogContent onPointerDownOutside={(e) => e.preventDefault()} className='overflow-visible top-1/4'>
        <DialogHeader>
          <DialogTitle>{t`Generate team usage report`}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <DatePickerRange
            placeholder={t`Select the date range for the report`}
            disablePortal
            value={dateRange}
            onChange={setDateRange}
            locale={locale}
            disabled={csvLoading}
          />
          {dateRange && (
            <Typography variant='aLabelSmall'>
              {t`Confirm the date range is correct before generating the report`}
            </Typography>
          )}
        </DialogDescription>
        <DialogFooter>
          <Button
            variant='contained'
            palette='primary'
            disabled={!dateRange || csvLoading}
            onClick={handleConfirm}
            data-track='Portfolio Health / Generate team usage report'
          >
            {csvLoading && <LoaderRound palette='primary' size='sm' className='absolute left-1/2' />}
            {t`Generate and download`} <IconDownload className='size-4 ml-2' />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
