import { t } from '@lingui/macro'
import { Assignees, toast, useSortedAssignees } from '@strise/app-shared'
import { type PageInput, SortOrdering, UserField, type UserSortInput } from '@strise/types'
import {
  Combobox,
  type ComboboxItem,
  type ComboboxPaginationProps,
  type ComboboxProps,
  IconAvatarAdd
} from '@strise/ui-components'
import * as React from 'react'
import { useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { User } from '~/components/Assignee/User'
import { useAssigneeMutations, useYou } from '~/components/Assignee/assigneeHooks'
import { type SimpleUserFragment } from '~/graphqlTypes'
import { type AssigneeEdge } from '~/utils/assigneeUtils'
import { defaultTeamUsersPageLimit, useTeamUsers } from '~/utils/teamUsers'

interface SelectCompanyAssigneeProps extends Omit<ComboboxProps<SimpleUserFragment>, 'items'> {
  assignees: AssigneeEdge[]
  companyIds: string[]
  onAction?: () => void
}

export const SelectCompanyAssignee = React.forwardRef<HTMLDivElement, SelectCompanyAssigneeProps>(
  ({ assignees, className, companyIds, onAction, ...props }, ref): React.ReactNode => {
    const handleCompleted = (): void => {
      toast.success(t`Company assignees changed`)
    }

    const { assign, assignLoading, unassign, unassignLoading } = useAssigneeMutations(handleCompleted)

    const mutationLoading = assignLoading || unassignLoading

    const addHandler = async (users: SimpleUserFragment[]): Promise<void> => {
      await assign({ usersToAssign: users, companies: companyIds, existingAssignees: assignees })
      if (onAction) onAction()
    }

    const handleAdd = async (user: ComboboxItem<SimpleUserFragment>): Promise<void> => {
      await addHandler([user.value])
    }

    const handleRemove = async (user: ComboboxItem<SimpleUserFragment>): Promise<void> => {
      await unassign({ users: [user.id], companies: companyIds, assignees })
      if (onAction) onAction()
    }

    return (
      <AssigneeSearchSelect
        className={className}
        assignees={assignees}
        ref={ref}
        closeOnSelect
        onAdd={handleAdd}
        onRemove={handleRemove}
        loading={mutationLoading}
        data-track='Assignee Changed'
        {...props}
      />
    )
  }
)

interface AssigneeSearchSelectProps extends Omit<ComboboxProps<SimpleUserFragment>, 'items'> {
  assignees: AssigneeEdge[]
  hideSelectedValues?: boolean
  onAction?: () => void
}

// TODO: refactor and merge shared logic in `GrowAssigneeSelect` and `AssigneeSearchSelect`
export const AssigneeSearchSelect = React.forwardRef<HTMLDivElement, AssigneeSearchSelectProps>(
  (
    {
      assignees,
      children,
      className,
      hideSelectedValues,
      loading: propsLoading,
      onAction,
      onAdd,
      onChange,
      onRemove,
      ...props
    },
    ref
  ) => {
    const value = assignees.map(({ node }) => node)

    const [search, setSearch] = React.useState('')
    const [maxTotalCount, setMaxTotalCount] = React.useState<number | null>(null)
    const [assignedUsersOnTop, setAssignedUsersOnTop] = React.useState(value.map((user) => user.id))
    const [debouncedSearch] = useDebounceValue(search, 500)

    const [page, setPage] = useState<PageInput<UserSortInput>>({
      limit: defaultTeamUsersPageLimit,
      offset: 0,
      sort: [{ field: UserField.Name, ordering: SortOrdering.Ascending }]
    })

    const {
      hasNextPage,
      loading: teamUsersLoading,
      teamUsers
    } = useTeamUsers({
      variables: {
        q: debouncedSearch,
        page
      },
      setPage,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const tCount = data.team.users.totalCount
        if (maxTotalCount === null && tCount && debouncedSearch === '') {
          // Setting maxTotalCount initially to know the maximum amount with an empty `q` search string
          setMaxTotalCount(tCount)
        }
      }
    })

    const paginationProps: ComboboxPaginationProps = {
      disabled: teamUsersLoading,
      loading: teamUsersLoading,
      showPrevious: page.offset > 0,
      showNext: hasNextPage,
      onPrevious: () => handlePageChange('previous'),
      onNext: () => handlePageChange('next')
    }

    const loading = propsLoading || teamUsersLoading

    // TODO: this can probably be handled in apollo client with merging somehow
    // As we now use search and pagination backend, we need to merge assignees and teamUsers
    // Create a map of assignee ids for more efficient lookup
    const assigneeIds = new Set(assignees.map((assignee) => assignee.node.id))
    // Filter out assignees from teamUsers
    const filteredTeamUsers = teamUsers.filter((teamUser) => !assigneeIds.has(teamUser.node.id))
    // Merge assignees and teamUsers
    const assigneeEnrichedTeamUsers: Array<{ node: SimpleUserFragment }> = [...assignees, ...filteredTeamUsers].flat()

    // If maxTotalCount is set, and we have fetched the equal amount of users, we can disable backend search - which will rarely be needed for most teams.
    const disableBackendSearch = maxTotalCount && maxTotalCount <= assigneeEnrichedTeamUsers.length

    const youUserEdges = useYou(assigneeEnrichedTeamUsers)
    const sortedAssignees = useSortedAssignees(youUserEdges, assignedUsersOnTop)
    const options = sortedAssignees.map(({ node }) => {
      return {
        label: node.name,
        id: node.id,
        value: node,
        searchString: `${node.name} ${node.email ?? ''}`,
        renderNode: <User user={node} />
      }
    })

    const handleOpen = (): void => {
      setAssignedUsersOnTop(value.map((user) => user.id))
      if (onAction) onAction()
    }

    const handlePageChange = (direction: 'next' | 'previous'): void => {
      const newOffset =
        direction === 'next'
          ? page.offset + defaultTeamUsersPageLimit
          : Math.max(0, page.offset - defaultTeamUsersPageLimit)
      setPage((prevPage) => ({
        ...prevPage,
        offset: newOffset
      }))
    }

    return (
      <Combobox
        startIcon={<IconAvatarAdd size='md' className='mr-2 shrink-0' />}
        inlineSearch
        hideSelected={hideSelectedValues}
        closeOnSelect
        customSelectedItemsRenderer={<Assignees assignees={assignees} className='mr-2 w-auto' />}
        className={className}
        onOpen={handleOpen}
        onAdd={onAdd}
        onRemove={onRemove}
        onChange={onChange}
        ref={ref}
        // TODO: Consider implementing frontend pagination as well when all items are fetched, but is highly unlikely
        paginationProps={disableBackendSearch ? undefined : paginationProps}
        items={options}
        loading={loading}
        search={disableBackendSearch ? undefined : search}
        setSearch={disableBackendSearch ? undefined : setSearch}
        value={value.map((item) => ({ label: item.name, id: item.id, value: item }))}
        data-track='Assignee Changed'
        aria-label={t`Click to assign`}
        {...props}
      >
        {children || t`Click to assign`}
      </Combobox>
    )
  }
)
