import { cn } from '../../utils/className'
import { type IconProps, iconVariants } from '../../utils/iconUtils'
import * as React from 'react'

export const IconCopy = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M8 1h10.075L23 5.83V20H8V1Zm2 2v15h11V7.25h-4.334V3H10Zm8.666 1.381.886.869h-.886v-.869Z'
        clipRule='evenodd'
      />
      <path fill='currentColor' fillRule='evenodd' d='M1 4h8v2H3v15h9.726v-2h2v4H1V4Z' clipRule='evenodd' />
    </svg>
  )
)
IconCopy.displayName = 'IconCopy'
