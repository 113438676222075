import { defineMessage } from '@lingui/macro'
import { IdNameChip } from '@strise/app-shared'
import { type SetStateFn, getByPath, setChildState } from '@strise/react-utils'
import { EnumChip } from '~/components/Filter/FilterChips'
import { type FilterSpec, filterArray } from '~/components/Filter/FilterHOC'
import { eventTypeTitles, extractEventTypesOptions, useTopicsOptions } from '~/components/Filter/filterHooks'
import type { UserSettingsFragment } from '~/graphqlTypes'

export const sidepanelEventsFilters: Record<string, FilterSpec<UserSettingsFragment>> = {
  sources: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'sidepanel.sources'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'sidepanel.sources'),
    ChipComponent: EnumChip,
    titleMap: eventTypeTitles,
    useOptions: extractEventTypesOptions,
    dataTrack: 'Sidepanel Events / Filter / Types / Changed',
    editLabel: defineMessage({ message: 'Types' }),
    enableSearch: true
  }),
  topics: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'sidepanel.topics'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'sidepanel.topics'),
    ChipComponent: IdNameChip,
    useOptions: useTopicsOptions,
    dataTrack: 'Sidepanel Events / Filter / Topics / Changed',
    editLabel: defineMessage({ message: 'Topic' }),
    enableSearch: true
  })
}
