import { AuditRisk } from '@strise/types'
import * as React from 'react'
import { BaseRiskSelect } from '~/components/Risk/BaseRiskSelect'
import { getTitle } from '~/utils/enum'
import { auditRiskTitles, auditToRiskColor, riskColorClasses } from '~/utils/risk'

const sortedAuditRisks = [AuditRisk.None, AuditRisk.Low, AuditRisk.Medium, AuditRisk.High]

export const AuditRiskSelect = ({
  disabled,
  onChange,
  selectedRisk
}: {
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedRisk: AuditRisk
}): React.ReactNode => {
  const options = sortedAuditRisks.map((auditRisk) => ({
    value: auditRisk,
    label: getTitle(auditRiskTitles[auditRisk]),
    colorClass: riskColorClasses[auditToRiskColor[auditRisk]].colorClass,
    bgColorClass: riskColorClasses[auditToRiskColor[auditRisk]].bgColorClass
  }))

  return (
    <BaseRiskSelect
      disabled={disabled}
      selectedValue={selectedRisk}
      options={options}
      name='audit-risk'
      onChange={onChange}
    />
  )
}
