import { type ApolloLink, from, useReactiveVar } from '@apollo/client/index.js'
import { setContext } from '@apollo/client/link/context/index.js'
import { API_ENV_TO_API_URL, omitTypenameLink, useAuthLink, useHttpLink } from '@strise/app-shared'
import { useMemo } from 'react'
import { useErrorLink } from '~/apolloClient/apolloLinks'
import { HEADER_REQUEST_ID } from '~/constants'
import { apiEnvState, spoofUser, team } from '~/state'

const requestIDLink = setContext((_, { headers }) => {
  const requestId = crypto.randomUUID()
  return { headers: { ...(headers as Record<string, string>), [HEADER_REQUEST_ID]: requestId } }
})

export const useApolloLink = (): ApolloLink => {
  const teamId = useReactiveVar(team)
  const apiEnv = apiEnvState().get()
  const apiUrl =
    apiEnv in API_ENV_TO_API_URL
      ? API_ENV_TO_API_URL[apiEnv as keyof typeof API_ENV_TO_API_URL]
      : // Skaffold API url
        `https://${apiEnv}.stage.serverless.graphql.strise.ai/internal/graphql`
  const httpLink = useHttpLink(apiUrl)
  const errorLink = useErrorLink()
  const authLink = useAuthLink({ spoofId: spoofUser(), teamId })

  const link = useMemo(
    () => from([omitTypenameLink, errorLink, authLink, requestIDLink, httpLink]),
    [errorLink, authLink, requestIDLink, httpLink]
  )

  return link
}
