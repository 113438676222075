import React from 'react'
import { Register } from '~/components/Register'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { reviewEventToEvent } from '~/components/Review/reviewValueTransformers'
import { type ReviewRegisterValueFragment } from '~/graphqlTypes'

export const ReviewRegister = ({
  entityId,
  item
}: {
  entityId: string
  item: ReviewRegisterValueFragment
}): React.ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const register = {
    ...item.value,
    statusDescription: item.value.status,
    registerId: item.value.externalId,
    event: item.value.event ? reviewEventToEvent(item.value.event) : null,
    __typename: 'Register' as const
  }

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <Register entityId={entityId} register={register} />
    </ReviewItem>
  )
}
